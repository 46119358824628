import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './ModifyStepRatesModal.css';

const ModifyStepRatesModal = ({
  isCloseBtnNeeded, isOpen, title, message, onCancel, onClose, fullWidth, maxWidth, buttonMessage,
}) => (
  <>
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={isOpen}
    >
      <DialogTitle id="alert-dialog-title" styleName="alert-dialog-title">
        <span styleName="title">{title}</span>
        {isCloseBtnNeeded && <IconButton aria-label="close" onClick={onCancel}><CloseIcon /></IconButton>}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions styleName="saveBtnDiv">
        <Button autoFocus color="primary" onClick={onClose} styleName="saveBtn" variant="contained">
          {buttonMessage}
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

ModifyStepRatesModal.defaultProps = {
  buttonMessage: 'OK',
  fullWidth: undefined,
  isCloseBtnNeeded: false,
  isOpen: false,
  maxWidth: undefined,
  onCancel: () => {},
  title: 'Message',
};

ModifyStepRatesModal.propTypes = {
  buttonMessage: PropTypes.string,
  fullWidth: PropTypes.string,
  isCloseBtnNeeded: PropTypes.bool,
  isOpen: PropTypes.bool,
  maxWidth: PropTypes.string,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default ModifyStepRatesModal;
