/* eslint-disable import/prefer-default-export */
import {
  takeEvery, all, put,
  call,
  select,
} from 'redux-saga/effects';
import * as R from 'ramda';
import * as Api from 'lib/Api';
import { actions as tombStoneActions } from 'ducks/tombstone/index';
import { selectors as indexerSelectors } from 'ducks/indexer/index';
import {
  SAVE_EVAL_DETAILS,
  SHOW_LOADER,
  HIDE_LOADER, SET_RESULT_OPERATION,
  SET_BORROWER_INFO,
  SET_PROCESSID_TASKID,
} from 'ducks/dashboard/types';
import {
  SET_BORROWERS_DATA,
} from 'ducks/income-calculator/types';
import {
  SET_SELECTED_BORROWER,
} from 'ducks/document-checklist/types';
import {
  ERROR,
} from 'constants/common';
import DashboardModel from '../../../models/Dashboard';
import {
  FETCH_TOMBSTONE_DATA, UPDATE_EVAL_LSAMSDETAILS, FETCH_GRID_DATA,
  SET_INDEXER_GRID_DATA, FETCH_ACTIVE_USER, SET_ACTIVE_USER_INFO,
  FETCH_SEARCH_GRID_DATA, SET_INDEXER_SEARCH_GRID_DATA, SET_INDEXING_PAGE_VISIBILITY,
  SET_BORROWERS_DATA_INDEXER,
} from './types';

// const {
//   Messages:
//   {
//     LEVEL_FAILED,
//   },
// } = DashboardModel;
const { Messages = {} } = DashboardModel || {};

const { LEVEL_FAILED } = Messages;

function* setBorrowerData(action) {
  const { loanNumber, processId } = action.payload;
  const borrowerDetails = yield call(Api.callGet,
    `/api/financial-aggregator/financecalc/getBorrowerDetails/${loanNumber}/${processId}`);
  if (borrowerDetails.status === 200) {
    const borrowerData = R.pathOr([], ['response', 'processedBorrowerData'], borrowerDetails);

    yield put({ type: SET_BORROWERS_DATA, payload: borrowerData });
    yield put({ type: SET_BORROWER_INFO, payload: borrowerData });

    const initialBorrowerDataByPstnNumber = R.find(R.propEq('borrowerPstnNumber', 1))(borrowerData);

    const firstName = R.propOr('', 'firstName', initialBorrowerDataByPstnNumber);
    const pstnNumber = R.propOr('', 'borrowerPstnNumber', initialBorrowerDataByPstnNumber);

    yield put({ type: SET_SELECTED_BORROWER, payload: { selectedBorrower: `${firstName}_${pstnNumber}` } });
  } else {
    yield put({ type: SET_BORROWERS_DATA, payload: [] });
    yield put({ type: SET_BORROWER_INFO, payload: [] });
  }
}

function* generateTombstoneData(action) {
  const indexerData = yield select(indexerSelectors.getIndexerGridData);
  const indexerSearchedData = yield select(indexerSelectors.getIndexerSearchGridData);
  const indexerGridResponse = R.propOr([], 'indexerDetailsList', indexerData);
  const combinedData = [...indexerGridResponse, ...indexerSearchedData];

  const loanNumber = action.payload;

  const constructPayload = val => ({
    loanNumber: val.loanId,
    taskId: val.taskId,
    evalId: val.evalId,
    selectedResolutionId: val.resolutionId,
    processId: val.processId,
  });

  const payload = R.head(combinedData.filter(val => val.loanId === +loanNumber)
    .map(constructPayload));

  yield put({
    type: SAVE_EVAL_DETAILS,
    payload,
  });

  yield put({
    type: SET_PROCESSID_TASKID,
    payload,
  });

  yield put(tombStoneActions.fetchTombstoneData());

  yield put({ type: SHOW_LOADER });

  yield put({
    type: SET_INDEXING_PAGE_VISIBILITY,
    payload: true,
  });
  yield call(setBorrowerData, { payload: { loanNumber, processId: payload.processId } });
  yield put({ type: HIDE_LOADER });
}
function* updateEvalLSAMSDetails(action) {
  try {
    const response = yield call(Api.callPost, '/api/disposition/updateLSAMSDetails', action.payload);
    if (!response || (response && R.has(ERROR, response))) {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          status: 'Failed to save!',
          level: LEVEL_FAILED,
          saga: 'lsamsUpdate',
        },
      });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          status: 'Successfully saved to LSAMS!',
          level: 'Success',
          saga: 'lsamsUpdate',
        },
      });
    }
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: 'Failed to save!',
        level: LEVEL_FAILED,
        saga: 'lsamsUpdate',
      },
    });
  }
}

function* gatherGridData(action) {
  const { pageNumber, pageSize } = action.payload;
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(Api.callGet, `/api/data-aggregator/indexer?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    if (response) {
      yield put({
        type: SET_INDEXER_GRID_DATA,
        payload: response,
      });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          status: 'Failed to fetch Data!',
          level: LEVEL_FAILED,
        },
      });
    }
    yield put({
      type: SET_INDEXING_PAGE_VISIBILITY,
      payload: false,
    });
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: 'Something went wrong while fetching Data!',
        level: LEVEL_FAILED,
      },
    });
  }
}

function* validateActiveUser(action) {
  const { loanId, evalId, assignedTo } = action.payload;
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(Api.callGet, `/api/bpm-audit/indexer/validate-assignee?loanId=${loanId}&evalId=${evalId}&assignedTo=${assignedTo}`);
    if (response) {
      yield put({
        type: SET_ACTIVE_USER_INFO,
        payload: response,
      });
    } else {
      yield put({
        type: SET_ACTIVE_USER_INFO,
        payload: {
          status: 'Failed to fetch Data!',
          level: LEVEL_FAILED,
        },
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_ACTIVE_USER_INFO,
      payload: {
        status: 'Something went wrong while fetching Data!',
        level: LEVEL_FAILED,
      },
    });
  }
}

function* gatherSearchGridData(action) {
  const { searchType, searchValue } = action.payload;
  const searchName = searchType === 'assignedTo' ? 'Username' : 'Id';
  try {
    yield put({ type: SHOW_LOADER });
    const response = yield call(Api.callGet, `/api/data-aggregator/indexer/searchBy${searchName}?${searchType}=${searchValue}`);
    if (response) {
      yield put({
        type: SET_INDEXER_SEARCH_GRID_DATA,
        payload: response,
      });
    } else {
      yield put({
        type: SET_RESULT_OPERATION,
        payload: {
          status: 'Failed to fetch Data!',
          level: LEVEL_FAILED,
        },
      });
    }
    yield put({ type: HIDE_LOADER });
  } catch (e) {
    yield put({
      type: SET_RESULT_OPERATION,
      payload: {
        status: 'Something went wrong while fetching Data!',
        level: LEVEL_FAILED,
      },
    });
  }
}

function* watchGenerateTombstoneData() {
  yield takeEvery(FETCH_TOMBSTONE_DATA, generateTombstoneData);
}

function* watchUpdateLSAMSDetails() {
  yield takeEvery(UPDATE_EVAL_LSAMSDETAILS, updateEvalLSAMSDetails);
}

function* watchFetchGridDetails() {
  yield takeEvery(FETCH_GRID_DATA, gatherGridData);
}

function* watchFetchSearchGridDetails() {
  yield takeEvery(FETCH_SEARCH_GRID_DATA, gatherSearchGridData);
}

function* watchActiveUserDetails() {
  yield takeEvery(FETCH_ACTIVE_USER, validateActiveUser);
}

function* watchSetBorrowersData() {
  yield takeEvery(SET_BORROWERS_DATA_INDEXER, setBorrowerData);
}

export const commonExports = {
  setBorrowerData,
};
export function* combinedSaga() {
  yield all([
    watchGenerateTombstoneData(), watchUpdateLSAMSDetails(),
    watchFetchGridDetails(), watchActiveUserDetails(), watchFetchSearchGridDetails(),
    watchSetBorrowersData(),
  ]);
}
