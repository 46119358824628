/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-lonely-if */
import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { operations as documentChecklistOperations, selectors as documentChecklistSelectors } from 'ducks/document-checklist';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { selectors as widgetsSelectors } from 'ducks/widgets';
import { DOCUMENT_CHECKLIST } from '../../../constants/widgets';
import { EDITABLE_DOC_CHECKLIST_WIDGET } from '../../../constants/appGroupName';
import {
  PROPERTY_PRIMARY_USE, VASP_DOC_REVIEW,
  ORDERED_TAB, VALIDATED_STS,
} from '../../../constants/incomeCalc/DocumentList';
import './DocumentList.css';
import Loader from '../../Loader';
import Document from './Document';
import DocumentHistoryModal from './DocumentHistoryModal';
import LinkPopover from '../DocumentViewer/components/LinkPopover';
import TagPopover from './TagPopover';
import ConfirmationPopover from '../DocumentViewer/components/ConfirmationPopover';

class DocumentList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documents: null,
      linkDocPopover: false,
      openHistoryModel: null,
      removalDocumentId: 0,
      removalDocumentName: '',
      tagPopover: false,
      taggedDocumentName: '',
      tagRequired: false,
      unlinkDocPopOver: false,
      removalDocumentAnchor: null,
    };
  }

  componentDidMount() {
    const {
      activeTab,
      docReviewStatusDropdown,
      fetchDocTxnDocuments, value,
      isVASP,
    } = this.props;
    const docReviewStatusType = isVASP ? VASP_DOC_REVIEW : PROPERTY_PRIMARY_USE;
    docReviewStatusDropdown(docReviewStatusType);
    if (R.isEmpty(value) || R.isNil(value)) { fetchDocTxnDocuments(); }
    if (activeTab === ORDERED_TAB) {
      this.checkAllDocumentsValidated();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activeTab, selectedBorrower, setRadioSelect,
    } = this.props;

    if (prevProps.selectedBorrower !== selectedBorrower) {
      setRadioSelect('');
    }
    if (activeTab === ORDERED_TAB) {
      this.checkAllDocumentsValidated();
    }
  }

  static getDerivedStateFromProps(props) {
    const {
      value, selectedBorrower, isVASP,
    } = props;
    let data = [];
    if (isVASP) {
      data = value;
    } else {
      data = R.propOr([], 'documents', R.find(R.propEq('borrowerName',
        selectedBorrower))(value));
    }
    if (data) {
      return { documents: data };
    }
    return null;
  }

  checkAllDocumentsValidated = () => {
    const { setDisableCompleteBtn } = this.props;
    const { documents } = this.state;
    if (Array.isArray(documents)) {
      const allValidated = documents.every((doc) => {
        if (doc.required) {
          return doc.documentReviewStatus === VALIDATED_STS;
        }
        return true;
      });
      setDisableCompleteBtn(!allValidated);
    }
  };

  setOpenHistoryModel = (val) => {
    this.setState({ openHistoryModel: val });
  }

  handleCloseHistoryModel = () => {
    this.setState({ openHistoryModel: null });
  }

  setLinkDocPopover = () => {
    this.setState({ linkDocPopover: true });
  }

  setRemovalDoc = (docId, docName) => {
    this.setState({ removalDocumentId: docId, removalDocumentName: docName });
  }

  handleLinkPopover = () => {
    this.setState({ linkDocPopover: false, removalDocumentId: 0, removalDocumentName: '' });
  }

  handleTagPopover = () => {
    this.setState({ tagPopover: false, taggedDocumentName: '', tagRequired: false });
  }

  setTag = (popOver, docName, req) => {
    this.setState({ tagPopover: popOver, taggedDocumentName: docName, tagRequired: req });
  }

  setRemovalDocumentAnchor = (val) => {
    this.setState({ removalDocumentAnchor: val });
  }

  handleUnLinkPopover = () => {
    this.setState({ unlinkDocPopOver: false, removalDocumentId: 0, removalDocumentName: '' });
  }

  setUnlinkDocPopOver = (val) => {
    this.setState({ unlinkDocPopOver: val });
  }

  render() {
    const {
      openWidgetList, activeTab, setDisableCompleteBtn,
      loader, groupName, isVASP, removeRadioSelect,
    } = this.props;
    const {
      documents, linkDocPopover, openHistoryModel, removalDocumentId,
      removalDocumentName, taggedDocumentName, tagRequired, tagPopover,
      removalDocumentAnchor, unlinkDocPopOver,
    } = this.state;
    const isDocWidgetNotInProc = R.contains(DOCUMENT_CHECKLIST, openWidgetList)
      && !R.contains(groupName, EDITABLE_DOC_CHECKLIST_WIDGET);

    return (
      <>

        {
          loader ? <Loader />
            : (
              <div>
                {
                  R.isEmpty(documents)
                    ? (
                      <div>
                        <Typography
                          styleName="no-data"
                          variant="h6"
                        >
                          {'No Documents Found'}
                        </Typography>
                      </div>
                    )
                    : (
                      <div>
                        {
                          documents.map(item => (
                            <Document
                              activeTab={activeTab}
                              doc={item}
                              isDocWidgetNotInProc={isDocWidgetNotInProc}
                              isVASP={isVASP}
                              setDisableCompleteBtn={setDisableCompleteBtn}
                              setLinkDocPopover={this.setLinkDocPopover}
                              setOpenHistoryModel={this.setOpenHistoryModel}
                              setRemovalDoc={this.setRemovalDoc}
                              setRemovalDocumentAnchor={this.setRemovalDocumentAnchor}
                              setTag={this.setTag}
                              setUnlinkDocPopOver={this.setUnlinkDocPopOver}
                            />
                          ))}
                        {
                          !isVASP
                            && (
                              <>

                                <DocumentHistoryModal
                                  documentName={openHistoryModel}
                                  handleClose={this.handleCloseHistoryModel}
                                  isOpen={!R.isNil(openHistoryModel)}
                                />
                                <LinkPopover
                                  linkDocPopover={linkDocPopover}
                                  removalDocumentId={removalDocumentId}
                                  removalDocumentName={removalDocumentName}
                                  setLinkDocPopover={this.handleLinkPopover}
                                  source="docChecklist"
                                  type="unlink"
                                />
                                <TagPopover
                                  setTagPopover={this.handleTagPopover}
                                  taggedDocumentName={taggedDocumentName}
                                  tagPopover={tagPopover}
                                  tagRequired={tagRequired}
                                />
                              </>
                            )
                          }
                        {
                          isVASP
                            && (
                            <>
                              <ConfirmationPopover
                                anchorEl={removalDocumentAnchor}
                                handlePopoverClose={this.handleUnLinkPopover}
                                isPopoverOpen={unlinkDocPopOver}
                                removalDocumentId={removalDocumentId}
                                removalDocumentName={removalDocumentName}
                                removeRadioSelect={removeRadioSelect}
                              />
                            </>
                            )
                        }
                      </div>
                    )
                }
              </div>
            )
        }

      </>

    );
  }
}

DocumentList.defaultProps = {
  openWidgetList: [],
  groupName: null,
  setDisableCompleteBtn: () => { },
};


DocumentList.propTypes = {
  activeTab: PropTypes.string.isRequired,
  docReviewStatusDropdown: PropTypes.func.isRequired,
  fetchDocTxnDocuments: PropTypes.func.isRequired,
  groupName: PropTypes.string,
  isVASP: PropTypes.bool.isRequired,
  loader: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string.isRequired,
  }).isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string),
  removeRadioSelect: PropTypes.func.isRequired,
  selectedBorrower: PropTypes.string.isRequired,
  setDisableCompleteBtn: PropTypes.func,
  setRadioSelect: PropTypes.func.isRequired,
  value: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  value: documentChecklistSelectors.getDocChecklistData(state),
  selectedBorrower: documentChecklistSelectors.getSelectedBorrower(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
  loader: documentChecklistSelectors.getLoader(state),
  groupName: dashboardSelectors.groupName(state),
  isVASP: documentChecklistSelectors.getIsVASP(state),
});

const mapDispatchToProps = dispatch => ({
  setRadioSelect: documentChecklistOperations.radioSelectOperation(dispatch),
  docReviewStatusDropdown:
    documentChecklistOperations.docReviewStatusDropdownOperation(dispatch),
  fetchDocTxnDocuments: documentChecklistOperations.setDocChecklistDataOperation(dispatch),
  removeRadioSelect: documentChecklistOperations.removeRadioSelectOperation(dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentList);
