import * as R from 'ramda';
import UniversalCookie from 'universal-cookie';
import { createBrowserHistory } from 'history';
import Redirect from './Redirect';

const JWT_TOKEN_COOKIE_NAME = 'app-session-jwt-token';
const JWT_REFRESH_TOKEN_COOKIE_NAME = 'app-session-jwt-refresh-token';
const APP_NAME_COOKIE = 'app-name';
const history = createBrowserHistory();

const CURRENT_URL = window.location.href;
const appNameMap = {
  dv: 'cmod-dev',
  qa: 'cmod-qa',
  ut: 'cmod-uat',
  pr: 'cmod-prod',
};

function fetchCookie(cookieName) {
  const cookie = new UniversalCookie(document.cookie);
  return cookie.get(cookieName);
}

function fetchAppNameBasedOnURL() {
  if (CURRENT_URL.includes('cmod')) {
    const index = CURRENT_URL.search('cmod');
    const env = CURRENT_URL.substring(index + 4, index + 6);
    return appNameMap[env];
  } if (CURRENT_URL.includes('localhost')) {
    return fetchCookie(APP_NAME_COOKIE);
  }
  return null;
}

function fetchValueFromLocalStorage(key) {
  return localStorage.getItem(key);
}
const postResponseCodes = [400, 404, 422, 500];

const getRefreshToken = async (payload) => {
  const cookie = new UniversalCookie(document.cookie);
  const appName = fetchAppNameBasedOnURL();
  const headers = {
    'Content-Type': 'application/json',
  };
  const body = {
    accessToken: fetchValueFromLocalStorage(JWT_TOKEN_COOKIE_NAME),
    refreshToken: fetchValueFromLocalStorage(JWT_REFRESH_TOKEN_COOKIE_NAME),
  };
  let payloadResponse = null;
  if (!body.accessToken) {
    history.replace('/');
    Redirect.toLogin('/');
    return payloadResponse;
  }

  try {
    const response = await fetch(`/api/auth/refreshtoken/jwt?appName=${appName}`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      });
    if (R.prop('ok', response)) {
      const apiResponse = await response.json();
      if (apiResponse.accessToken) {
        cookie.set(JWT_TOKEN_COOKIE_NAME, apiResponse.accessToken);
        localStorage.setItem(JWT_TOKEN_COOKIE_NAME, apiResponse.accessToken);
        const updatedHeaders = { ...payload.headers, 'Session-Token': fetchValueFromLocalStorage(JWT_TOKEN_COOKIE_NAME) };
        if (payload.method && ['GET', 'GET_TEXT'].includes(payload.method)) {
          payloadResponse = await fetch(payload.endpoint, { method: 'GET', headers: updatedHeaders });
          if (payload.method === 'GET') {
            if (R.prop('ok', payloadResponse)) {
              payloadResponse = payloadResponse.json();
              return payloadResponse;
            }
          } else if (payload.method === 'GET_TEXT') {
            if (R.prop('ok', payloadResponse)) {
              if (R.equals(R.prop('status', payloadResponse), 204)) {
                payloadResponse = {
                  statusCode: 204,
                  status: 'No Content',
                };
                return payloadResponse;
              }
              payloadResponse = payloadResponse.text();
            }
          }
        } else if (payload.method && ['POST', 'POST_GET'].includes(payload.method)) {
          payloadResponse = await fetch(payload.endpoint, { method: 'POST', headers: updatedHeaders, body: payload.body });
          if (payload.method === 'POST') {
            if (R.prop('ok', payloadResponse)
              || R.contains(R.prop('status', payloadResponse), postResponseCodes)) {
              if (R.equals(R.prop('status', payloadResponse), 202)) {
                payloadResponse = {
                  statusCode: 202,
                  status: 'Accepted',
                  ...payload.body,
                };
                return payloadResponse;
              }
              if (R.equals(R.prop('status', payloadResponse), 204)) {
                payloadResponse = {
                  statusCode: 204,
                  status: 'No Content',
                  ...payload.body,
                };
                return payloadResponse;
              }
              return payloadResponse ? payloadResponse.json() : null;
            }
          } else if (payload.method === 'POST_GET') {
            if (R.prop('ok', payloadResponse)
              || R.contains(R.prop('status', payloadResponse), postResponseCodes)) {
              if (R.equals(R.prop('status', payloadResponse), 202)) {
                payloadResponse = payloadResponse.json();
                return payloadResponse;
              }
              if (R.equals(R.prop('status', payloadResponse), 204)) {
                payloadResponse = {
                  statusCode: 204,
                  status: 'No Content',
                  ...payload.body,
                };
                return payloadResponse;
              }
              return payloadResponse ? payloadResponse.json() : null;
            }
          }
        } else if (payload.method && ['PUT', 'PATCH'].includes(payload.method)) {
          payloadResponse = await fetch(payload.endpoint,
            {
              method: payload.method,
              headers: updatedHeaders,
              body: JSON.stringify(payload.body),
            });
          if (R.prop('ok', payloadResponse)) {
            payloadResponse = payloadResponse.json();
          }
          throw new RangeError('HTTP status code not in range (2xx).');
        }
      }
    } else {
      history.replace('/');
      Redirect.toLogin('/');
    }
    return payloadResponse;
  } catch (e) {
    history.replace('/');
    Redirect.toLogin('/');
    return payloadResponse;
  }
};


const callGet = function callGet(endpoint, params = {}) {
  let headers = {};
  if (!R.isEmpty(params)) {
    headers = params;
  }
  headers = { ...headers, 'Session-Token': fetchValueFromLocalStorage(JWT_TOKEN_COOKIE_NAME) };
  return fetch(endpoint,
    {
      method: 'GET',
      headers,
    })
    .then(async (response) => {
      if (R.prop('ok', response)) {
        if (R.equals(R.prop('status', response), 204)) {
          return null;
        }
        return response.json();
      } if (R.equals(R.prop('status', response), 401) || R.equals(R.prop('status', response), 403)) {
        const payload = {
          method: 'GET',
          headers,
          endpoint,
        };
        const retryResponse = await getRefreshToken(payload);
        return retryResponse;
      }
      return null;
    });
};

const callGetText = function callGetText(endpoint, params = {}) {
  let headers = {};
  if (!R.isEmpty(params)) {
    headers = params;
  }

  headers = { ...headers, 'Session-Token': fetchValueFromLocalStorage(JWT_TOKEN_COOKIE_NAME) };
  return fetch(endpoint,
    {
      method: 'GET',
      headers,
    })
    .then(async (response) => {
      if (R.prop('ok', response)) {
        if (R.equals(R.prop('status', response), 204)) {
          return {
            statusCode: 204,
            status: 'No Content',
          };
        }
        return response.text();
      }
      if (R.equals(R.prop('status', response), 401) || R.equals(R.prop('status', response), 403)) {
        const payload = {
          method: 'GET_TEXT',
          headers,
          endpoint,
        };
        const retryResponse = await getRefreshToken(payload);
        return retryResponse;
      }
      return null;
    });
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const callPost = function callPost(endpoint, body, params = {}) {
  const headers = {
    'Content-Type': 'application/json',
    'Session-Token': fetchValueFromLocalStorage(JWT_TOKEN_COOKIE_NAME),
  };

  if (!R.isEmpty(params)) {
    Object.assign(headers, params);
  }
  return fetch(endpoint,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    })
    .then(async (response) => {
      if (R.prop('ok', response)
        || R.contains(R.prop('status', response), postResponseCodes)) {
        if (R.equals(R.prop('status', response), 202)) {
          return {
            statusCode: 202,
            status: 'Accepted',
            ...body,
          };
        }
        if (R.equals(R.prop('status', response), 204)) {
          return {
            statusCode: 204,
            status: 'No Content',
            ...body,
          };
        }
        let res;
        if (R.equals(R.prop('status', response), 400)) {
          res = response;
          if (isJsonString(res)) {
            return response.json();
          }
          return response.text();
        }
        return response ? response.json() : null;
      }
      if (R.equals(R.prop('status', response), 401) || R.equals(R.prop('status', response), 403)) {
        const payload = {
          method: 'POST',
          headers,
          body: JSON.stringify(body),
          endpoint,
        };
        const retryResponse = await getRefreshToken(payload);
        return retryResponse;
      }
      return null;
    });
};


const callPostGetResponse = function callPostGetResponse(endpoint, body, params = {}) {
  const headers = {
    'Content-Type': 'application/json',
    'Session-Token': fetchValueFromLocalStorage(JWT_TOKEN_COOKIE_NAME),
  };

  if (!R.isEmpty(params)) {
    Object.assign(headers, params);
  }
  return fetch(endpoint,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    })
    .then(async (response) => {
      if (R.prop('ok', response)
        || R.contains(R.prop('status', response), postResponseCodes)) {
        if (R.equals(R.prop('status', response), 202)) {
          return response.json();
        }
        if (R.equals(R.prop('status', response), 204)) {
          return {
            statusCode: 204,
            status: 'No Content',
            ...body,
          };
        }
        return response ? response.json() : null;
      }
      if (R.equals(R.prop('status', response), 401) || R.equals(R.prop('status', response), 403)) {
        const payload = {
          method: 'POST_GET',
          headers,
          body: JSON.stringify(body),
          endpoint,
        };
        const retryResponse = await getRefreshToken(payload);
        return retryResponse;
      }
      return null;
    });
};

function put(endpoint, body, params = {}) {
  const headers = {
    'Content-Type': 'application/json',
    'Session-Token': fetchValueFromLocalStorage(JWT_TOKEN_COOKIE_NAME),
  };

  if (!R.isEmpty(params)) {
    Object.assign(headers, params);
  }
  return fetch(endpoint,
    {
      method: 'PUT',
      headers,
      body: JSON.stringify(body),
    })
    .then(async (response) => {
      if (R.prop('ok', response)) {
        return response.json();
      }
      if (R.equals(R.prop('status', response), 401) || R.equals(R.prop('status', response), 403)) {
        const payload = {
          method: 'PUT',
          headers,
          body: JSON.stringify(body),
          endpoint,
        };
        const retryResponse = await getRefreshToken(payload);
        return retryResponse;
      }
      throw new RangeError('HTTP status code not in range (2xx).');
    });
}

function patch(endpoint, body, params = {}) {
  const headers = {
    'Content-Type': 'application/json',
    'Session-Token': fetchValueFromLocalStorage(JWT_TOKEN_COOKIE_NAME),
  };

  if (!R.isEmpty(params)) {
    Object.assign(headers, params);
  }
  return fetch(endpoint,
    {
      method: 'PATCH',
      headers,
      body: JSON.stringify(body),
    })
    .then(async (response) => {
      if (R.prop('ok', response)) {
        return response.json();
      }
      if (R.equals(R.prop('status', response), 401) || R.equals(R.prop('status', response), 403)) {
        const payload = {
          method: 'PATCH',
          headers,
          body: JSON.stringify(body),
          endpoint,
        };
        const retryResponse = await getRefreshToken(payload);
        return retryResponse;
      }
      throw new RangeError('HTTP status code not in range (2xx).');
    });
}

export {
  callGet,
  callGetText,
  callPost,
  callPostGetResponse,
  put,
  patch,
  getRefreshToken,
};
