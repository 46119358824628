import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tab from '@material-ui/core/Tab';
import * as R from 'ramda';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './Fico.css';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { selectors as incomeCalcSelectors } from 'ducks/income-calculator';
import { selectors as loginSelectors } from 'ducks/login';
import { selectors as taskChecklistSelectors, operations as taskChecklistOperations } from 'ducks/tasks-and-checklist';
import { operations as tombstoneOperations } from 'ducks/tombstone';
import { operations as indexerOperations } from 'ducks/indexer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { FEUW_GROUPS_CAN_EDIT_FICO, BEUW_GROUPS_CAN_EDIT_FICO, PROC_GROUPS_CAN_EDIT_FICO } from '../../constants/loanInfoComponents';
import processBorrowerData from '../../lib/CustomFunctions/BorrowerData/processBorrowerData';
import { DECEASED_BORROWER } from '../../constants/incomeCalc/DocumentList';
import { TABLE_SCHEMA } from '../../constants/tableSchema';
import MUITable from '../IncomeCalc/Table';
import AddContributor from '../IncomeCalc/AddContributor/AddContirbutor';
import EditContributor from '../IncomeCalc/EditContributor/EditContributor';


function Fico(props) {
  const {
    getborrowerData, ficoHistoryData, fetchFicoHistory, ficoScoreData,
    setFicoScoreData, isAssigned, setChecklistCenterPaneData, isNavigatedFromTombstone, group,
    ficoLockCalculationForms, enableNextChecklist, enableLockButton, user, ficoQuestionairre,
    isChecklistCleared, updateChecklistClearance, fetchBorrowers, processId, loanNumber,
  } = props;

  const userGroups = user && user.groupList;

  const canEditFico = () => {
    const groupsToCheck = [...FEUW_GROUPS_CAN_EDIT_FICO,
      ...BEUW_GROUPS_CAN_EDIT_FICO, ...PROC_GROUPS_CAN_EDIT_FICO];
    return groupsToCheck ? R.intersection(groupsToCheck, userGroups).length > 0 : false;
  };

  const [tabIndex, setTabIndex] = useState(0);
  const [selectedBorrowerPstn, setSelectedBorrowerPstn] = useState(1);
  const [selectedHistory, setSelectedHistory] = useState([]);
  const [selectedFicoScore, setSelectedFicoScore] = useState('');

  const disableFico = !!(isNavigatedFromTombstone && !canEditFico());
  const isDisabled = disableFico ? 'disable' : '';
  const [showAddContributorPopup, setShowAddContributorPopup] = useState(false);
  const [showEditContributorPopup, setShowEditContributorPopup] = useState(false);
  const [contributorData, setContributorData] = useState({});
  const columns = R.propOr([], 'FICO', TABLE_SCHEMA);
  const [selectedValue, setSelectedValue] = useState(ficoQuestionairre);
  const displayList = processBorrowerData(getborrowerData);
  const handleTabChange = (selectedIndex) => {
    let positionNumber = selectedIndex + 1;
    if (getborrowerData && getborrowerData[selectedIndex]
      && getborrowerData[selectedIndex].borrowerPstnNumber) {
      positionNumber = getborrowerData[selectedIndex].borrowerPstnNumber;
    }
    setTabIndex(selectedIndex);
    setSelectedBorrowerPstn(positionNumber);
    setSelectedHistory(R.filter(R.propEq('position', (positionNumber)))(ficoHistoryData));
    const selectFico = R.propOr('', 'ficoScore', R.find(R.propEq('position',
      (positionNumber)))(ficoScoreData));
    if (R.isNil(selectFico) || R.isEmpty(selectFico)) { setSelectedFicoScore(''); } else { setSelectedFicoScore(selectFico); }
  };

  const handleFicoScoreChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^\d*\.?(?:\d{1,2})?$/;
    if (regex.test(inputValue)) {
      const payload = inputValue;
      setSelectedFicoScore(payload);
      setFicoScoreData({
        value: payload,
        position: selectedBorrowerPstn,
        creditScorePulled: selectedValue,
        isNavigatedFromTombstone,
      });
    }
  };
  const handAddContributorClick = () => {
    setShowAddContributorPopup(true);
  };

  const handleCloseAddContributor = () => {
    setShowAddContributorPopup(false);
  };

  const EditContributorFn = (item) => {
    setContributorData(item);
    setShowEditContributorPopup(true);
  };
  const closeEditContributor = () => {
    setShowEditContributorPopup(false);
  };

  useEffect(() => {
    fetchFicoHistory();
    if (isNavigatedFromTombstone) {
      fetchBorrowers({ loanNumber, processId });
    }
  }, []);

  useEffect(() => {
    if (selectedValue !== ficoQuestionairre && !R.isEmpty(selectedValue)) {
      enableNextChecklist(selectedValue);
    }
  }, [selectedValue]);

  useEffect(() => {
    setSelectedHistory(R.filter(R.propEq('position', (selectedBorrowerPstn)))(ficoHistoryData));
    setSelectedFicoScore(R.propOr('', 'ficoScore', R.find(R.propEq('position',
      (selectedBorrowerPstn)))(ficoScoreData)));
  }, [ficoHistoryData]);

  useEffect(() => {
    let positionNumber = selectedBorrowerPstn;
    if (getborrowerData && getborrowerData[tabIndex]
      && getborrowerData[tabIndex].borrowerPstnNumber) {
      positionNumber = getborrowerData[tabIndex].borrowerPstnNumber;
    }
    setSelectedBorrowerPstn(positionNumber);
    setSelectedHistory(R.filter(R.propEq('position', (positionNumber)))(ficoHistoryData));
    setSelectedFicoScore(R.propOr('', 'ficoScore', R.find(R.propEq('position',
      (positionNumber)))(ficoScoreData)));
  }, [getborrowerData]);


  const isEditable = item => (!!(item.description.toLowerCase().startsWith('assump')
    || item.description.toLowerCase().startsWith('contrib')));

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    updateChecklistClearance();
  };

  const questionairre = group === 'FEUW' && !isNavigatedFromTombstone ? (
    <Grid styleName="questionairre">
      <div>
          Has a credit report been pulled for each borrower/contributor and uploaded to the file ?
      </div>
      <RadioGroup onChange={handleChange} styleName={isDisabled} value={isChecklistCleared ? '' : selectedValue}>
        <FormControlLabel control={<Radio styleName="radio-control-bubble" />} label="Yes" styleName="questionairreOptions" value="Yes" />
        <FormControlLabel control={<Radio styleName="radio-control-bubble" />} label="No" styleName="questionairreOptions" value="No" />
        <FormControlLabel control={<Radio styleName="radio-control-bubble" />} label="Not Required" styleName="questionairreOptions" value="NA" />
      </RadioGroup>
    </Grid>
  ) : null;

  const handleClose = () => {
    setChecklistCenterPaneData('Checklist');
  };

  const lockDisabled = () => {
    if (userGroups.includes('readonly')) {
      return true;
    }
    return false;
  };

  const handleLock = () => {
    ficoLockCalculationForms();
  };

  const isFromTombstone = isNavigatedFromTombstone ? 'borrowerBannerLoanInfo' : 'borrowerBanner';
  const gridHandle = isNavigatedFromTombstone ? 'gridHandleLoanInfo' : 'gridHandle';
  const lockButton = !isAssigned || disableFico || !enableLockButton ? 'lockButtonDisabled' : 'lockButton';

  const showLockbutton = isNavigatedFromTombstone ? (
    <div styleName="buttonsContainer">
      <Grid item styleName={lockButton} xs={1}>
        <Button disabled={!enableLockButton || lockDisabled()} onClick={handleLock}>Lock</Button>
      </Grid>
      <Grid item styleName="closeButton" xs={1}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </div>
  ) : null;

  return (
    <div>
      {showLockbutton}
      {isNavigatedFromTombstone ? (
        <Paper elevation={1} square styleName={isFromTombstone}>
          <Tabs
            inkBarStyle={{ background: '#596feb' }}
            onChange={(_, selectedIndex) => handleTabChange(selectedIndex)}
            scrollable="true"
            style={{ width: 'auto' }}
            value={tabIndex}
            variant="scrollable"
          >
            {displayList && displayList.map(task => (
              <Tab
                key={R.propOr('', 'name', task)}
                disabled={R.propOr('', 'name', task).includes(DECEASED_BORROWER)}
                label={(
                  <div styleName="taballign">
                    <div styleName="borrNameDiv">
                      <Typography styleName="borrName" variant="subtitle1">
                        {task && R.propOr('', 'name', task)}
                      </Typography>
                      <Typography styleName="borrDesc" variant="subtitle2">
                        {task && R.propOr('', 'description', task)}
                      </Typography>
                    </div>
                    {isEditable(task) && (
                    <div>
                      <IconButton
                        aria-label="Edit"
                        component="span"
                      >
                        <EditIcon onClick={() => EditContributorFn(task)} />
                      </IconButton>
                    </div>
                    ) }
                  </div>
                )}
                styleName="borrTab"
              />
            ))}
          </Tabs>
        </Paper>
      ) : null}

      <div>
        {isNavigatedFromTombstone ? (
          <Grid styleName={gridHandle}>
            <Grid style={{ paddingbottom: '0.5rem' }} xs={4}>
              <>
                <Paper styleName="paper">
                  <p>
                    <Typography styleName="fico-title">Fico</Typography>
                  </p>
                  <Grid>
                    <div styleName="align">
                      <div>
                        { <Typography styleName="fico-scr-title">Fico Score</Typography>}
                      </div>
                      <TextField
                        disabled={(R.propOr('', 'name', R.find(R.propEq('pstnNum',
                          (tabIndex + 1)))(displayList))).includes(DECEASED_BORROWER)
                          || lockDisabled()}
                        margin="dense"
                        onChange={event => handleFicoScoreChange(event)}
                        size="small"
                        styleName={`textalign ${isDisabled}`}
                        value={selectedFicoScore}
                        variant="outlined"
                      />
                    </div>
                    <div styleName="align">
                      <div>
                        { <Typography styleName="fico-scr-title">Date</Typography>}
                      </div>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          disabled
                          format="MM-DD-YYYY"
                          helperText=""
                          inputVariant="outlined"
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          size="small"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid>
                </Paper>
              </>
            </Grid>
            <Grid style={{ paddingbottom: '0.5rem' }} xs={7}>
              <>
                <p>
                  <Typography styleName="fico-title">Fico History</Typography>
                </p>
              </>
              <MUITable columns={columns} data={selectedHistory || []} size="small" />
            </Grid>
            <Grid style={{ paddingbottom: '0.5rem' }} xs={1}>
              <IconButton disabled={!isAssigned || lockDisabled()} onClick={handAddContributorClick} styleName="addContributor">
                <img alt="add-contributor" src="/static/img/person_add.svg" />
              </IconButton>
            </Grid>
            {showAddContributorPopup && <AddContributor checklistType="Fico" onClose={handleCloseAddContributor} />}
            {showEditContributorPopup && (
            <EditContributor
              checklistType="Fico"
              contributorData={contributorData}
              onClose={closeEditContributor}
            />
            )}
          </Grid>
        ) : null}

        {questionairre}
      </div>
    </div>
  );
}

Fico.defaultProps = {
  isNavigatedFromTombstone: false,
  enableLockButton: false,
  isChecklistCleared: false,
};


Fico.propTypes = {
  enableLockButton: PropTypes.bool,
  enableNextChecklist: PropTypes.func.isRequired,
  fetchBorrowers: PropTypes.func.isRequired,
  fetchFicoHistory: PropTypes.func.isRequired,
  ficoHistoryData: PropTypes.shape().isRequired,
  ficoLockCalculationForms: PropTypes.func.isRequired,
  ficoQuestionairre: PropTypes.string.isRequired,
  ficoScoreData: PropTypes.func.isRequired,
  getborrowerData: PropTypes.shape().isRequired,
  group: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool.isRequired,
  isChecklistCleared: PropTypes.bool,
  isNavigatedFromTombstone: PropTypes.bool,
  loanNumber: PropTypes.number.isRequired,
  processId: PropTypes.number.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setFicoScoreData: PropTypes.func.isRequired,
  updateChecklistClearance: PropTypes.func.isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  user: loginSelectors.getUser(state),
  group: dashboardSelectors.groupName(state),
  processId: dashboardSelectors.processId(state),
  loanNumber: dashboardSelectors.loanNumber(state),
  getborrowerData: incomeCalcSelectors.getBorrowers(state),
  enableLockButton: dashboardSelectors.enableLockButton(state),
  ficoHistoryData: taskChecklistSelectors.getFicoHistoryData(state),
  ficoScoreData: taskChecklistSelectors.getFicoScoreData(state),
  isAssigned: dashboardSelectors.isAssigned(state),
  ficoQuestionairre: taskChecklistSelectors.getFicoQuestionairre(state),
  isChecklistCleared:
  taskChecklistSelectors.isFicoChecklistCleared(state),
});

const mapDispatchToProps = dispatch => ({
  fetchFicoHistory: taskChecklistOperations.fetchFicoHistoryOperation(dispatch),
  setFicoScoreData: taskChecklistOperations.setFicoScoreOperation(dispatch),
  setChecklistCenterPaneData: tombstoneOperations.setChecklistCenterPaneDataOperation(dispatch),
  ficoLockCalculationForms: taskChecklistOperations.ficoLockOperation(dispatch),
  enableNextChecklist: taskChecklistOperations.enableNextChecklistOperation(dispatch),
  updateChecklistClearance: taskChecklistOperations.updateChecklistClearanceOperation(dispatch),
  fetchBorrowers: indexerOperations.fetchBorrowersIndexer(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fico);
