/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDrag } from 'react-dnd';
import * as R from 'ramda';
import {
  Checkbox,
  Grid,
  Typography,
  Link,
} from '@material-ui/core';
import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
import { operations as documentChecklistOperations, selectors as documentChecklistSelectors } from 'ducks/document-checklist';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { selectors as widgetsSelectors } from 'ducks/widgets';
import { selectors as indexerSelectors } from 'ducks/indexer';
import LightTooltip from './components/LightTooltip';
import DocumentPopover from './components/DocumentPopover';
import './DocumentViewer.css';
import { DateFormatter } from '../../../lib/DateUtils';
import { DECEASED_BORROWER } from '../../../constants/incomeCalc/DocumentList';
import { DOCUMENT_CHECKLIST } from '../../../constants/widgets';
import { EDITABLE_DOC_CHECKLIST_WIDGET } from '../../../constants/appGroupName';

const FileNetDocument = (props) => {
  const [isDragged, setIsDragged] = useState(false);
  const [hoverDoc, setHoverDoc] = useState({});
  const [isVisited, setVisited] = useState([]);
  const {
    rec, linkDocumentsToBorrowers, handleMultiSelect,
    selectedDocs, clearSelected, radioSelect, checkDocument,
    checkedDocumentId, isIndexingPageVisible, isAssigned,
    openWidgetList, groupName,
  } = props;

  const isDocWidgetNotInProc = R.contains(DOCUMENT_CHECKLIST, openWidgetList)
  && !R.contains(groupName, EDITABLE_DOC_CHECKLIST_WIDGET);

  const isDisabled = (!isIndexingPageVisible && (!isAssigned || isDocWidgetNotInProc));

  const [{ opacity }, drag] = useDrag({
    item: { type: 'fileNetDoc' },
    begin: (item) => {
      let draggedDocs = [];
      if (item) {
        if (selectedDocs.find(field => field.fileNetDocId === rec.fileNetDocId)) {
          draggedDocs = selectedDocs;
        } else {
          draggedDocs = [...selectedDocs, rec];
        }
      }
      return { docs: draggedDocs };
    },
    end(item, monitor) {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const { name, selectedBorrower } = dropResult;
        if (name && !selectedBorrower.includes(DECEASED_BORROWER) && !isDisabled) {
          setIsDragged(true);
          const payload = {
            checkedBorrowers: [selectedBorrower],
            checkedFilenetDocs: item.docs,
            name,
          };
          linkDocumentsToBorrowers(payload);
        }
        clearSelected();
        setIsDragged(false);
      }
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  const handleDocPopOpen = (event, doc) => {
    setHoverDoc(doc);
  };

  const handleVisited = (index) => {
    if (!isVisited.includes(index)) {
      setVisited([...isVisited, index]);
    }
  };

  const selectedFileNetDocIds = R.pluck('fileNetDocId', selectedDocs);
  const isChecked = checkedDocumentId.includes(rec.fileNetDocId) || selectedFileNetDocIds.includes(rec.fileNetDocId);

  return (
    <div
      ref={drag}
      onClick={e => handleMultiSelect(e.ctrlKey, rec)}
      style={{ opacity }}
      styleName={isChecked ? 'listItemGridChecked' : 'listItemGrid'}
    >
      <Grid
        key={rec.fileNetDocId}
        button
        container
        dense
        spacing={3}
      >
        <Grid item styleName="checkBoxGrid" xs={1}>
          {radioSelect && !isDragged
            ? (
              <Checkbox
                checked={checkedDocumentId.includes(rec.fileNetDocId)}
                onClick={() => checkDocument(rec)}
                styleName={checkedDocumentId.includes(rec.fileNetDocId) ? 'checkBoxChecked' : 'checkBox'}
              />
            ) : (
              <PanToolOutlinedIcon styleName="dragHandIcon" />
            )
          }
        </Grid>
        {
          rec.fileNetDocId
            ? (
              <Grid
                key={rec.docTitle}
                item
                onMouseEnter={event => handleDocPopOpen(event, rec)}
                primary={rec.docTitle}
                styleName="docNameGrid"
                xs={radioSelect ? 7 : 8}
              >
                <LightTooltip placement="bottom-start" title={<DocumentPopover hoverDoc={hoverDoc} />}>
                  <Typography
                    noWrap
                    styleName="docName"
                  >
                    <Link color="inherit" href={rec.docUrl} onClick={() => handleVisited(rec.fileNetDocId)} rel="noopener noreferrer" styleName={isVisited.includes(rec.fileNetDocId) ? 'visited' : ''} target="_blank">
                      {rec.docTitle}
                    </Link>
                  </Typography>
                </LightTooltip>
              </Grid>
            )
            : (
              <Grid
                key={rec.name}
                item
                onMouseEnter={event => handleDocPopOpen(event, rec)}
                primary={rec.name}
                styleName="docNameGrid"
                xs={radioSelect ? 7 : 8}
              >
                <LightTooltip placement="bottom-start" title={<DocumentPopover hoverDoc={hoverDoc} />}>
                  <Typography
                    noWrap
                    styleName="docName"
                  >
                    <Link color="inherit" href={rec.docUrl} rel="noopener noreferrer" target="_blank">
                      {rec.name}
                    </Link>
                  </Typography>
                </LightTooltip>
              </Grid>
            )
        }
        <Grid
          key={rec.fileNetDocId}
          item
          primary={rec.docTitle}
          styleName="docDateGrid"
          xs={4}
        >
          <Typography
            noWrap
            styleName="docDate"
          >
            {DateFormatter(rec.docCreatedDate)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

FileNetDocument.propTypes = {
  checkDocument: PropTypes.func.isRequired,
  checkedDocumentId: PropTypes.arrayOf().isRequired,
  clearSelected: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  handleMultiSelect: PropTypes.func.isRequired,
  isAssigned: PropTypes.bool.isRequired,
  isIndexingPageVisible: PropTypes.bool.isRequired,
  linkDocumentsToBorrowers: PropTypes.func.isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string).isRequired,
  radioSelect: PropTypes.string.isRequired,
  rec: PropTypes.shape().isRequired,
  selectedDocs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  groupName: dashboardSelectors.groupName(state),
  isAssigned: dashboardSelectors.isAssigned(state),
  isIndexingPageVisible: indexerSelectors.isIndexingPageVisible(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
  radioSelect: documentChecklistSelectors.getRadioSelect(state),
});

const mapDispatchToProps = dispatch => ({
  linkDocumentsToBorrowers: documentChecklistOperations.linkDocumentsOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileNetDocument);
