import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { convertToUSCurrency } from 'lib/Formatters';
import * as R from 'ramda';
import { selectors as evalSelectors, operations } from 'ducks/eval';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { selectors as tombstoneSelectors } from 'ducks/tombstone';
import { selectors as loginSelectors } from 'ducks/login';
import {
  Select, Button, MenuItem, Grid, TextField,
} from '@material-ui/core';
import ModifyStepRatesModal from '../../../../components/ModifyStepRatesModal';
import EvalTable from '../../../../components/Eval/EvalTable';
import ConfirmationDialogSingleOption from '../../../../components/ConfirmationDialogSingleOption';
import { STEP_RATES_MODIFY_CASETYPE, AD_GROUPS_CASE_SKILLS } from '../../../../constants/eval';
import './CaseDetails.css';

const columns = [
  { name: 'date', label: 'Date', cellFormat: item => (item ? moment(item).format('MM/DD/YYYY') : '-') },
  { name: 'rate', label: 'Rate', cellFormat: item => `${(Number(item) * 100).toFixed(3)}%` },
  { name: 'amount', label: 'Amount', cellFormat: item => convertToUSCurrency.format(Number(item).toFixed(2)) },
  { name: 'duration', label: 'Duration' },
];

const StepRates = ({
  stepRatesData, resolutionId, updateStepRateFlag, caseInformation, caseSkills, userGroupList,
  calculateCaseRules, updateStepRatesData, brandName, caseType, generalInformation,
  isDisabled, lockFlag,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [stepRates, setStepRates] = useState([]);

  const dropdownOptions = [12, 24, 36, 48, 60, 0];
  const caseModTermIdx = generalInformation.findIndex(i => i.columnName === 'caseModifiedTerm');
  const premodrateIdx = generalInformation.findIndex(i => i.columnName === 'ARMResetInterestRate');
  const workoutTypeIdx = caseInformation.findIndex(i => i.columnName === 'workoutType');

  const caseModTerm = generalInformation[caseModTermIdx]
    && generalInformation[caseModTermIdx].columnVal;
  const premodrate = generalInformation[premodrateIdx]
    && generalInformation[premodrateIdx].columnVal;
  const workoutType = caseInformation[workoutTypeIdx] && caseInformation[workoutTypeIdx].columnVal;

  const isModifiable = STEP_RATES_MODIFY_CASETYPE.includes(caseType) && workoutType === 'Open Waterfall';
  const caseSkillsList = R.pluck('skillName', caseSkills);
  const skills = caseSkillsList.map(val => val.toLowerCase());
  const isADGroupEditable = userGroupList.some(group => AD_GROUPS_CASE_SKILLS.includes(group));
  const isResetBtnEditable = isADGroupEditable && skills.includes('stepRatesResetBtn'.toLowerCase()) && lockFlag !== 1;
  const isModifyBtnEditable = isADGroupEditable && skills.includes('stepRatesModifyBtn'.toLowerCase()) && lockFlag !== 1;

  useEffect(() => {
    Array.from({ length: 8 }, (_, i) => i).forEach(
      (i) => { stepRates[i] = { sequence: i + 1, caseId: Number(resolutionId) }; },
    );
    stepRatesData.forEach(
      (item, index) => {
        stepRates[index] = {
          rate: item.rate,
          duration: item.duration,
          sequence: index + 1,
          caseId: Number(resolutionId),
        };
        if (!dropdownOptions.includes(item.duration)) delete stepRates[index].duration;
      },
    );
  }, [stepRatesData, resolutionId]);

  const isUSAACaseType = () => STEP_RATES_MODIFY_CASETYPE.includes(caseType) && workoutType === 'Open Waterfall' && brandName !== 'RSH';
  const validateStepRates = () => {
    // Valid entry has both: Rate and Duration
    if (stepRates.some(r => (r.rate !== undefined && r.duration === undefined)
      || (r.rate === undefined && r.duration !== undefined))) {
      setAlertMessage('Rate and Duration both are required for a valid step rate.');
      setAlertOpen(true);
      return false;
    }
    const rtSteps = stepRates.filter(r => r.rate !== undefined && r.duration === 0);
    if (rtSteps.length === 0) {
      setAlertMessage('\'RT\' must be selected for the last step rate.');
      setAlertOpen(true);
      return false;
    }
    if (rtSteps.length > 1) {
      setAlertMessage('\'RT\' can be selected only for the last step rate.');
      setAlertOpen(true);
      return false;
    }
    const lastRateStep = [...stepRates].reverse().find(r => r.rate !== undefined);
    const lastRTStep = rtSteps[0];
    if (lastRateStep !== lastRTStep) {
      setAlertMessage('\'RT\' can be selected only for the last step rate.\nPlease remove any rates after the \'RT\' step.');
      setAlertOpen(true);
      return false;
    }
    if (stepRates.reduce((sum, r) => sum + (r.duration || 0), 0) > Number(caseModTerm)) {
      setAlertMessage('Total duration exceeds the term.');
      setAlertOpen(true);
      return false;
    }

    if (stepRates[0] && isUSAACaseType()) {
      if (stepRates.some(r => r.rate !== undefined && (r.rate * 100) < 1)) {
        setAlertMessage('Invalid rate. Rate entered must be 1% or greater.');
        setAlertOpen(true);
        return false;
      }
    }
    return true;
  };

  const handleSaveChanges = useCallback(() => {
    if (!validateStepRates()) return;
    updateStepRateFlag(true);
    updateStepRatesData(stepRates.reduce((acc, { rate, duration, ...rest }) => {
      if (rate !== undefined && duration !== undefined) {
        return [...acc, {
          ...rest,
          rate: Number(rate),
          duration: Number(duration),
        }];
      }
      return acc;
    }, []));
    calculateCaseRules();
    updateStepRateFlag(false);
    setModalOpen(false);
  }, [stepRates, updateStepRateFlag, updateStepRatesData, calculateCaseRules]);

  const handleResetClick = () => {
    updateStepRateFlag(true);
    const updatedStepRates = [
      {
        rate: premodrate && Number(premodrate),
        duration: caseModTerm && Number(caseModTerm),
        sequence: 1,
        caseId: Number(resolutionId),
      },
    ];
    updateStepRatesData(updatedStepRates);
    calculateCaseRules();
    updateStepRateFlag(false);
  };

  return (
    <>
      <EvalTable columns={columns} data={stepRatesData || []} />
      {isModifiable && (
        <div styleName="action-buttons">
          <Button color="primary" disabled={!isResetBtnEditable || isDisabled} onClick={() => { handleResetClick(); }} style={{ borderWidth: '0px' }} variant="outlined">
            RESET
          </Button>
          <Button color="primary" disabled={!isModifyBtnEditable || isDisabled} onClick={() => setModalOpen(true)} style={{ borderWidth: '2px' }} variant="outlined">
            MODIFY
          </Button>
        </div>
      )}
      <ModifyStepRatesModal
        buttonMessage="SAVE"
        isCloseBtnNeeded
        isOpen={isModalOpen}
        message={
          (
            <StepRatesForm
              dropdownOptions={dropdownOptions}
              setStepRates={setStepRates}
              stepRates={stepRates}
            />
          )
        }
        onCancel={() => setModalOpen(false)}
        onClose={handleSaveChanges}
        styleName="dialog-box-cta"
        title="Modify Step Rates"
      />
      <ConfirmationDialogSingleOption
        isOpen={isAlertOpen}
        message={alertMessage}
        onClose={() => setAlertOpen(false)}
        option="OK"
        title=""
      />
    </>
  );
};

const rateValueGenerator = (value, isDivision) => {
  const toFixedValueArr = value.toString().split('.');
  let fixedValCondition;
  if (isDivision) {
    fixedValCondition = toFixedValueArr[1] ? toFixedValueArr[1].length + 2 : 2;
  } else {
    fixedValCondition = toFixedValueArr[1] ? toFixedValueArr[1].length - 2 : 2;
  }
  const toFixedValue = (value === 0) ? 0 : fixedValCondition;
  return (parseFloat(isDivision ? (value / 100) : (value * 100)).toFixed(toFixedValue));
};

const StepRatesForm = ({ dropdownOptions, stepRates, setStepRates }) => {
  const handleChange = (index, field, value) => {
    if (value === '' && field === 'rate') {
      const newStepRates = [...stepRates];
      delete newStepRates[index].rate;
      setStepRates(newStepRates);
    } else if (field === 'rate') {
      const finalValue = rateValueGenerator(value, true);
      setStepRates(
        prev => prev.map((item, i) => (i === index
          ? { ...item, [field]: finalValue } : item)),
      );
    } else {
      setStepRates(
        prev => prev.map((item, i) => (i === index ? { ...item, [field]: value } : item)),
      );
    }
  };

  const processedRates = stepRates.reduce((acc, item) => {
    if (item.rate) {
      const finalValue = rateValueGenerator(item.rate, false);
      return [...acc, { ...item, rate: finalValue }];
    }
    return [...acc, item];
  }, []);

  return (
    <>
      <Grid container style={{ paddingBottom: '10px' }}>
        <Grid item xs={6}>
          Rates
        </Grid>
        <Grid item xs={6}>
          Duration
        </Grid>
      </Grid>
      <div styleName="horizontal-line" />
      {
        processedRates.map((stepRate, index) => (
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 1000, style: { paddingTop: '9px', paddingBottom: '9px' } }}
                onChange={e => handleChange(index, 'rate', e.target.value)}
                size="small"
                type="number"
                value={`${stepRate.rate}` || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={5}>
              <Select
                defaultValue=""
                onChange={e => handleChange(index, 'duration', e.target.value)}
                size="small"
                styleName="dropdownSelectStyle"
                value={stepRate.duration}
                variant="outlined"
              >
                {dropdownOptions.map(value => (
                  <MenuItem key={value} value={value}>{value || 'RT'}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        ))
      }
    </>
  );
};

StepRates.defaultProps = {
  caseInformation: [],
  generalInformation: [],
  stepRatesData: [],
};

StepRates.propTypes = {
  brandName: PropTypes.string.isRequired,
  calculateCaseRules: PropTypes.func.isRequired,
  caseInformation: PropTypes.arrayOf(PropTypes.shape()),
  caseSkills: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  caseType: PropTypes.string.isRequired,
  generalInformation: PropTypes.arrayOf(PropTypes.shape()),
  isDisabled: PropTypes.func.isRequired,
  lockFlag: PropTypes.number.isRequired,
  resolutionId: PropTypes.number.isRequired,
  stepRatesData: PropTypes.arrayOf(PropTypes.shape()),
  updateStepRateFlag: PropTypes.func.isRequired,
  updateStepRatesData: PropTypes.func.isRequired,
  userGroupList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

StepRatesForm.propTypes = {
  dropdownOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setStepRates: PropTypes.func.isRequired,
  stepRates: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  brandName: dashboardSelectors.brand(state),
  caseInformation: evalSelectors.caseInformation(state),
  caseSkills: evalSelectors.userOverrideCaseList(state),
  caseType: evalSelectors.caseType(state),
  generalInformation: evalSelectors.generalInformation(state),
  lockFlag: evalSelectors.lockFlag(state),
  resolutionId: dashboardSelectors.resolutionId(state),
  stepRatesData: evalSelectors.stepRates(state),
  isDisabled: tombstoneSelectors.isDisable(state),
  userGroupList: loginSelectors.getGroupList(state),
});

const mapDispatchToProps = dispatch => ({
  calculateCaseRules: operations.calculateCaseRulesOpn(dispatch),
  updateStepRatesData: operations.updateStepRatesDataOperations(dispatch),
  updateStepRateFlag: operations.updateStepRateFlagOperations(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepRates);
