
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import './AssumptorContent.css';
import { connect } from 'react-redux';
import { operations, selectors } from 'ducks/tombstone';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { selectors as loginSelectors } from 'ducks/login';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { Box, Tab, Tabs } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Loader from 'components/Loader';
import { useEffect } from 'react';

const AssumptorContent = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [borrowers, setBorrowers] = useState([]);
  const {
    loading, borrowerData, setChecklistCenterPaneData,
  } = props;

  const handleClose = () => {
    setChecklistCenterPaneData('Checklist');
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    setBorrowers(borrowerData.filter(e => e.description && (e.description.toLowerCase().startsWith('assump'))));
  }, [borrowerData]);


  return (
    <>
      {loading ? <Loader message="Please Wait" /> : (
        <Grid container>
          <Grid itme xs={11}>
            <Typography styleName="heading">
              {}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleClose} styleName="close-icon">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid elevation={1} styleName="layout" xs={12}>
            <Paper styleName="background-color" variant="elevation">
              <Box xs={1}>
                <Tabs indicatorColor="primary" onChange={handleTabChange} textColor="primary" value={selectedTab}>
                  {(borrowers).map(user => (
                    <Tab
                      key={user.borrowerId}
                      label={(
                        <Box alignItems="center" display="flex" flexDirection="column">
                          <Typography
                            styleName="assumptor-name"
                            variant="body1"
                          >
                            {user.firstName && user.lastName
                              ? `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`
                                .split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                              : ''}
                          </Typography>
                          <Typography
                            styleName="sub-title"
                            variant="body1"
                          >
                            { 'Assumptor' }
                          </Typography>
                        </Box>
                    )}
                    />
                  ))}
                </Tabs>
              </Box>
            </Paper>
          </Grid>
          { borrowers.map((selectedUser, index) => index === selectedTab && (
            <Grid container elevation={1} styleName="layout">
              <Grid item xs={12}>
                <Paper styleName="background-color">
                  <Box>
                    <Grid container direction="row">
                      <Paper container elevation={1} styleName="layout-white-bg">
                        <Grid item xs={12}>
                          <Grid container direction="row">
                            <Grid item styleName="name-padding" xs={6}>
                              <Typography>First Name</Typography>
                              <TextField
                                disabled
                                fullWidth
                                id="fullWidth"
                                InputProps={{ readOnly: true }}
                                size="small"
                                styleName="background-color"
                                textTransform="none"
                                value={selectedUser && selectedUser.firstName}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item styleName="name-padding" xs={6}>
                              <Typography>Last Name</Typography>
                              <TextField
                                disabled
                                fullWidth
                                id="fullWidth"
                                InputProps={{ readOnly: true }}
                                size="small"
                                styleName="background-color"
                                textTransform="none"
                                value={selectedUser && selectedUser.lastName}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>

                          <Grid container direction="row">
                            <Grid item styleName="name-padding" xs={6}>
                              <Typography>DOB</Typography>
                              <TextField
                                disabled
                                fullWidth
                                id="fullWidth"
                                InputProps={{ readOnly: true }}
                                size="small"
                                styleName="background-color"
                                textTransform="none"
                                value={selectedUser && selectedUser.birthDate}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item styleName="name-padding" xs={6}>
                              <Typography>Phone</Typography>
                              <TextField
                                disabled
                                fullWidth
                                id="fullWidth"
                                InputProps={{ readOnly: true }}
                                size="small"
                                styleName="background-color"
                                textTransform="none"
                                value={selectedUser && selectedUser.phoneNumber}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>

                          <Grid container direction="row">
                            <Grid item styleName="name-padding" xs={12}>
                              <Typography>Email</Typography>
                              <TextField
                                disabled
                                fullWidth
                                id="fullWidth"
                                InputProps={{ readOnly: true }}
                                size="small"
                                styleName="background-color"
                                textTransform="none"
                                value={selectedUser && selectedUser.emailAddress}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} />
                      </Paper>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          )) }

          <Grid xs={8}>
            {loading ? <Loader message="Please Wait" />
              : (<></>)}
          </Grid>
        </Grid>
      )}
    </>
  );
};
AssumptorContent.defaultProps = {
  loading: false,
  borrowerData: [],
};

AssumptorContent.propTypes = {
  borrowerData: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  user: PropTypes.shape({
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  activeTile: dashboardSelectors.getStagerActiveTile(state),
  loanNumber: dashboardSelectors.loanNumber(state),
  rfdTableData: selectors.getRFDTableData(state),
  reasonDescriptionOptions: selectors.getReasonDescriptionOptions(state),
  loading: selectors.getLoader(state),
  user: loginSelectors.getUser(state),
  borrowerData: dashboardSelectors.getBorrowerInfo(state),
});

const mapDispatchToProps = dispatch => ({
  onGetReasonDescOptions: operations.getRFDReasonDescDropdownOperation(dispatch),
  setChecklistCenterPaneData: operations.setChecklistCenterPaneDataOperation(dispatch),
  onSaveRFDDetails: operations.onSubmitToRFDRequestOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssumptorContent);
