import React, { useEffect, useState } from 'react';
import {
  Grid, TextField, Button, Modal, Typography, IconButton,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import UndoIcon from '@material-ui/icons/Undo';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import RouteAccess from 'lib/RouteAccess';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { ERROR } from 'constants/common';
import utils from 'ducks/tasks-and-checklist/utils';
import Loader from 'components/Loader';
import * as constants from '../../../../constants/eval';
import { operations, selectors } from '../../../../state/ducks/eval';
import { selectors as loginSelectors } from '../../../../state/ducks/login';
import EvalTable from '../../../../components/Eval/EvalTable';
import './Eligibility.css';


const Eligibility = ({
  eligibility, fetchEligibilityData, saveEligibilityData, userEmail,
  resultOperation, inProgress, evalScreenIcon,
  userGroupList, isRepayCase,
  caseHeaderInfo,
}) => {
  const [tableData, setTableData] = useState(eligibility);
  const [updateId, setUpdateId] = useState(null);
  const [isCaseEditable, setIsCaseEditable] = useState(false);
  const [isUndoConfirm, setIsUndoConfirm] = useState(false);
  const { getCSTDateTime } = utils;
  useEffect(() => {
    fetchEligibilityData(isRepayCase);
  }, []);

  useEffect(() => {
    if (caseHeaderInfo && caseHeaderInfo.status === constants.OPEN
      && evalScreenIcon === constants.EVAL_WIDGET) {
      setIsCaseEditable(true);
    }
  }, [caseHeaderInfo]);

  useEffect(() => {
    setTableData(eligibility);
  }, [eligibility]);

  const handleOverride = (value) => {
    setTableData((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === value) {
          return { ...obj, textDisable: 'No' };
        }
        return obj;
      });
      return newState;
    });
  };
  const handleComments = (id, value) => {
    setTableData((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === id) {
          return { ...obj, overrideReason: value };
        }
        return obj;
      });
      return newState;
    });
  };
  const saveComments = (id, source) => {
    setUpdateId(id);
    const updateObj = tableData.find(item => item.id === id);
    const { textDisable, ...newObj } = updateObj;
    if (source === 'SAVE') {
      saveEligibilityData({
        isRepayCase,
        userEmail,
        body: [{
          ...newObj,
          eligible: true,
          overridden: true,
          updatedBy: userEmail,
          // resolutionID: selectedCaseId,
        }],
      });
    }
    if (source === 'UNDO') {
      saveEligibilityData({
        isRepayCase,
        userEmail,
        body: [{
          ...newObj,
          overridden: false,
          updatedBy: userEmail,
          overrideReason: null,
        }],
      });
    }
  };
  const cancelComments = (id) => {
    setTableData((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === id) {
          const reason = eligibility.find(item => item.id === id).overrideReason;
          return { ...obj, textDisable: 'Yes', overrideReason: reason };
        }
        return obj;
      });
      return newState;
    });
  };

  useEffect(() => {
    if (resultOperation && resultOperation.saga === 'Eligibility' && resultOperation.level === ERROR) {
      cancelComments(updateId);
    }
  }, [resultOperation]);

  const hasTextDisableNo = data => data.some(item => item.textDisable === 'No');

  const ELIGIBILITY_TABLE_COLUMNS = [
    {
      name: 'eligibilityName',
      label: 'Eligibility',
      align: 'left',
      iconCheck: 'Yes',
      cellFormat: (item) => {
        const eligible = R.equals(true, item.overrideEligible)
          ? (item.eligible || item.overridden)
          : item.eligible;
        return (
          <>
            {
              eligible ? (
                <>
                  <img alt="success" src="/static/img/successIcon.png" styleName="successIcon" />
                  {item.eligibilityName}
                </>
              ) : (
                <div styleName="eligibilityOverrideContainer">
                  <div>
                    <img alt="error" src="/static/img/errorIcon.png" styleName="successIcon" />
                    {item.eligibilityName}
                  </div>
                  {item.overrideEligible
                    && (!item.overridden ? (
                      <Button
                        color="primary"
                        disabled={!isCaseEditable
                          || hasTextDisableNo(tableData)
                          || !(RouteAccess.hasVpAvpAccess(userGroupList))}
                        onClick={() => handleOverride(item.id)}
                        style={{ marginLeft: '1%' }}
                      >
                          Override
                      </Button>
                    ) : (
                      <>
                        <Button
                          color="primary"
                          onClick={() => setIsUndoConfirm(true)}
                          style={{ marginLeft: '1%' }}
                        >
                            Undo
                          {' '}
                          <UndoIcon />
                        </Button>
                        <Modal
                          BackdropProps={{ style: { backgroundColor: 'rgba(255, 255, 255, 0.8)' } }}
                          disableBackdropClick
                          onClose={() => setIsUndoConfirm(false)}
                          open={isUndoConfirm}
                        >
                          <div styleName="unreject-popup-container">
                            <div styleName="close-btn-header">
                              <IconButton
                                aria-label="close"
                                onClick={() => setIsUndoConfirm(false)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                            <div styleName="content-body">
                              <ErrorOutlineIcon color="error" />
                              <Typography style={{ textAlign: 'center' }} variant="h2">
                                {'Are you sure you want to revert the overridden eligibility?'}
                              </Typography>
                              <div styleName="button-unreject-container">
                                <Button color="primary" disableElevation onClick={() => saveComments(item.id, 'UNDO')} variant="contained">
                                    OK
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </>
                    )

                    )}
                </div>
              )
            }
          </>
        );
      },
    },
    {
      name: 'response',
      label: 'Description',
      align: 'left',
      minWidthHead: 30,
    },
    {
      name: 'overrideReason',
      label: 'Comments',
      align: 'left',
      name2: 'textDisable',
      cellFormat: (id, value, textDisable) => (
        <>
          {
            R.equals('No', textDisable)
              ? (
                <>
                  <TextField
                    defaultValue={value.overrideReason}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ maxLength: 1000 }}
                    onChange={e => handleComments(value.id, e.target.value)}
                    size="small"
                    styleName="eligibility-comments"
                    variant="outlined"
                  />
                  <DoneIcon
                    fontSize="small"
                    onClick={() => saveComments(value.id, 'SAVE')}
                    styleName={`eligibility-done ${value.overrideReason ? 'done-enabled' : 'done-disabled'}`}
                  />
                  <CloseIcon
                    fontSize="small"
                    onClick={() => cancelComments(value.id)}
                    styleName="eligibility-close done-enabled"
                  />
                </>
              ) : value.overrideReason
          }
        </>
      ),
    },
    {
      name: 'overriddenDate',
      label: 'Overriden Date',
      align: 'left',
      dateFormat: true,
      cellFormat: value => (value ? getCSTDateTime(moment(value), 'MM/DD/YYYY') : null),
    },
    {
      name: 'updatedBy',
      label: 'Overriden By',
      align: 'left',
    },
  ];
  return (
    <>
      {inProgress ? (
        <div styleName="overlay-loader">
          <Loader message="Please wait, while Fetching Eligibility data" size={40} />
        </div>
      ) : (
        <>
          <Grid
            id="eligibility-container"
            style={{ maxHeight: '60vh', overflow: 'auto' }}
            styleName="eligibility-table"
            xs={12}
          >
            <EvalTable
              columns={ELIGIBILITY_TABLE_COLUMNS}
              data={tableData || []}
            />
          </Grid>
        </>
      )
      }
    </>
  );
};

Eligibility.defaultProps = {
  eligibility: [],
  fetchEligibilityData: () => { },
  saveEligibilityData: () => { },
  // editable: true,
  evalScreenIcon: '',
  userGroupList: [],
  status: '',
  isRepayCase: false,
  caseHeaderInfo: {
    caseType: '',
    status: '',
  },
};

Eligibility.propTypes = {
  // editable: PropTypes.bool,
  caseHeaderInfo: PropTypes.shape(
    {
      caseType: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    },
  ),
  eligibility: PropTypes.arrayOf(PropTypes.shape()),
  evalScreenIcon: PropTypes.string,
  fetchEligibilityData: PropTypes.func,
  inProgress: PropTypes.bool.isRequired,
  isRepayCase: PropTypes.bool,
  resultOperation: PropTypes.shape({
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    saga: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  saveEligibilityData: PropTypes.func,
  // selectedCaseId: PropTypes.number.isRequired,
  status: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
  userGroupList: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  eligibility: selectors.eligibility(state),
  userEmail: loginSelectors.getUserPrincipalName(state),
  resultOperation: dashboardSelectors.resultOperation(state),
  inProgress: selectors.inProgress(state),
  evalScreenIcon: selectors.getEvalScreenIcon(state),
  userGroupList: loginSelectors.getGroupList(state),
  caseHeaderInfo: selectors.caseHeaderInfoData(state),
});

const mapDispatchToProps = dispatch => ({
  fetchEligibilityData: operations.fetchEligibilityData(dispatch),
  saveEligibilityData: operations.saveEligibilityData(dispatch),
});

const EligibilityContainer = connect(mapStateToProps, mapDispatchToProps)(Eligibility);

const TestHooks = {
  Eligibility,
};


export default EligibilityContainer;

export { TestHooks };
