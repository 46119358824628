import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core/';
import RateInputBoxStyles from './RateInputBoxStyles';

const RateInput = ({
  id, label, onChange, override, value, type, columnType,
}) => {
  const [rateValue, setValue] = useState(null);
  const [updatedValue, setUpdatedValue] = useState(null);
  const classes = RateInputBoxStyles({ override });

  useEffect(() => {
    if (updatedValue === null) {
      setValue(value !== null ? `${Number(value).toFixed(3)}%` : value);
    }
  }, [value]);

  const isValidInput = (val) => {
    let inputValue = val;
    if (inputValue.length > 0) {
      inputValue = inputValue.trim();
      inputValue = inputValue.replaceAll(' ', '');
      inputValue = inputValue.replaceAll('%', '');
    }
    return Number((inputValue) >= 0);
  };

  const handleChange = (event) => {
    setValue(null);

    let inputValue = event.target.value.trim().replaceAll(' ', '').replaceAll('%', '');
    inputValue = inputValue !== '%' && inputValue.startsWith('%') ? inputValue.substring(1, inputValue.lastIndexOf('%') > 0
      ? inputValue.lastIndexOf('%') + 1 : inputValue.length)
      : inputValue;
    if ((inputValue.endsWith('%') && Number.isNaN(Number(inputValue.substring(0, inputValue.lastIndexOf('%')))))) {
      return;
    }
    if (isValidInput(inputValue)) {
      setUpdatedValue(inputValue);
      onChange(label, inputValue, columnType);
    }
  };

  const handleBlur = () => {
    if (updatedValue) {
      const roundedValue = parseFloat(updatedValue).toFixed(3);
      setValue(roundedValue !== null ? `${roundedValue}%` : roundedValue);
      onChange(label, roundedValue, columnType);
    }

    setUpdatedValue(null);
  };

  const handleFocus = () => {
    setValue(rateValue !== null ? rateValue.replaceAll('%', '') : rateValue);
  };

  return (
    <FormControl className={classes.root}>
      <TextField
        className={classes.TextFieldDiv}
        disabled={override}
        id={id}
        InputProps={{
          // inputProps: { min: 0, inputMode: 'numeric' },
          disableUnderline: true,
          style: { paddingLeft: '5px' },
          // startAdornment:
          // <InputAdornment className={classes.Currency} position="start">%</InputAdornment>,
        }}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        type={type}
        value={rateValue !== null ? rateValue : updatedValue}
      />
    </FormControl>
  );
};

RateInput.propTypes = {
  columnType: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  override: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
};

RateInput.defaultProps = {
  label: '',
  onChange: () => { },
  override: true,
  value: '',
  type: 'text',
  columnType: '',
};

export default RateInput;
