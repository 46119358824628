import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { connect } from 'react-redux';
import { operations, selectors } from 'ducks/tombstone';
import PropTypes from 'prop-types';
import { selectors as dashboardSelectors, operations as dashboardOperations } from 'ducks/dashboard';
import { operations as accountServiceOperations } from 'ducks/accountService';
import './ViewIcon.css';
import { ACCOUNT_SERVICE_EVENT_TYPES } from 'constants/common';
import {
  ACCOUNT_SERVICE, STANDARD_EVAL_SCREEN, CASE_DETAILS, REPAYMENT_PLAN_CASE,
} from 'constants/loanInfoComponents';
import DashboardModel from 'models/Dashboard';
import CFPBTableContent from '../CFPBTableContent';
import ForeClosureFirm from '../ForeClosureFirm';

class ViewIcon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
    };
  }

  handleCenterPaneView = () => {
    const {
      getCFPBTableData,
      loanNumber,
      loanInfoComponent,
      getForeClosureFirmData,
      setChecklistCenterPaneData,
      fetchBorrower,
    } = this.props;
    this.setState({
      isDialogOpen: true,
    });
    if (loanInfoComponent === 'Delinquency Start Date') {
      getCFPBTableData(loanNumber);
    } else if (loanInfoComponent === 'ForeClosureFirm') {
      getForeClosureFirmData(loanNumber);
    } else if (loanInfoComponent === 'Assumptor') {
      setChecklistCenterPaneData('Assumptor');
      fetchBorrower(loanNumber);
    }
  };

  handleCapModClick = () => {
    const {
      gatherEscrowDataOperation,
      getHistoryItems,
      value,
      storeCapModId,
      setChecklistCenterPaneData,
      storeLoanNumber,
      loanNumber, centerPaneVal, setPrevchecklistCenterPane, group,
    } = this.props;
    storeCapModId(value);
    storeLoanNumber(loanNumber);
    getHistoryItems();
    gatherEscrowDataOperation(ACCOUNT_SERVICE_EVENT_TYPES.ACC_SERVICE);
    if (group === DashboardModel.SEARCH_LOAN
      && [STANDARD_EVAL_SCREEN, CASE_DETAILS, REPAYMENT_PLAN_CASE].includes(centerPaneVal)) {
      setPrevchecklistCenterPane(centerPaneVal);
    }
    setChecklistCenterPaneData(ACCOUNT_SERVICE);
  }

  handleCloseDialog = (isClosed) => {
    this.setState({
      isDialogOpen: isClosed,
    });
  };

  getStyles = () => {
    const { loanInfoComponent, group } = this.props;
    if (loanInfoComponent === 'Delinquency Start Date') {
      const isMilestoneActivityPage = group === 'MA';
      return isMilestoneActivityPage ? 'icon-view-disabled' : 'icon-view';
    }
    if (loanInfoComponent === 'Assumptor' || loanInfoComponent === 'Cap Mod' || loanInfoComponent === 'ForeClosureFirm') {
      return 'icon-view';
    }
    return 'default';
  }

  render() {
    const { isDialogOpen } = this.state;
    const { loanInfoComponent } = this.props;
    return (
      <>
        <IconButton onClick={loanInfoComponent === 'Cap Mod' ? this.handleCapModClick : this.handleCenterPaneView} size="small" styleName={`${this.getStyles()}`}>
          <VisibilityIcon />
        </IconButton>
        {isDialogOpen && (loanInfoComponent === 'Delinquency Start Date') && (
          <CFPBTableContent
            handleClose={this.handleCloseDialog}
            show={isDialogOpen}
          />
        )}
        {isDialogOpen && (loanInfoComponent === 'ForeClosureFirm') && (
          <ForeClosureFirm
            handleClose={this.handleCloseDialog}
            show={isDialogOpen}
          />
        )}
      </>
    );
  }
}

ViewIcon.defaultProps = {
  getCFPBTableData: () => { },
  value: null,
  getForeClosureFirmData: () => {},
};

ViewIcon.propTypes = {
  centerPaneVal: PropTypes.string.isRequired,
  fetchBorrower: PropTypes.func.isRequired,
  gatherEscrowDataOperation: PropTypes.func.isRequired,
  getCFPBTableData: PropTypes.func,
  getForeClosureFirmData: PropTypes.func,
  getHistoryItems: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  loanInfoComponent: PropTypes.string.isRequired,
  loanNumber: PropTypes.number.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setPrevchecklistCenterPane: PropTypes.func.isRequired,
  storeCapModId: PropTypes.func.isRequired,
  storeLoanNumber: PropTypes.func.isRequired,
  value: PropTypes.number,
};

const mapDispatchToProps = dispatch => ({
  getCFPBTableData: operations.getCFPBTableDataOperation(dispatch),
  getForeClosureFirmData: operations.getForeClosureFirmDataOperation(dispatch),
  gatherEscrowDataOperation: accountServiceOperations.gatherEscrowDataOperation(dispatch),
  getHistoryItems: accountServiceOperations.storeAccountServicesHistoryOperation(dispatch),
  storeCapModId: accountServiceOperations.storeCapModId(dispatch),
  storeLoanNumber: accountServiceOperations.storeLoanNumber(dispatch),
  setChecklistCenterPaneData: operations.setChecklistCenterPaneDataOperation(dispatch),
  fetchBorrower: dashboardOperations.fetchBorrowerOperation(dispatch),
  setPrevchecklistCenterPane: operations.setPrevchecklistCenterPaneOpn(dispatch),
});

const mapStateToProps = state => ({
  loanNumber: dashboardSelectors.loanNumber(state),
  rfdTableData: selectors.getCFPBTableData(state),
  group: dashboardSelectors.groupName(state),
  tombstoneData: selectors.getTombstoneData(state),
  centerPaneVal: selectors.getChecklistCenterPaneView(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewIcon);
