import { convertToUSCurrency } from 'lib/Formatters';
import * as Groups from './Groups';

export const CASE_DETAILS = 'CASE DETAILS';
export const ELIGIBILITY = 'ELIGIBILITY';
export const AMORTIZATION_SCHEDULE = 'AMORTIZATION SCHEDULE';
export const CALC_LOG = 'CALC LOG';
export const FULFILMENT_CHKLIST = 'Fulfilment Checklist';
export const CERTAIN_MORTGAGE_LOAN_CHECKLIST = 'Certain Mortgage Loan Checklist';
export const TRACK_PAYMENT = 'TRACK PAYMENT';
export const BOOKING = 'Booking';
export const COMPLETED = 'Completed';
export const EVAL = 'Eval';
export const TRIALCASE = 'TrialCase';
export const STATE_ALT_TRIAL = 'State Alternative Trial';
export const STATE_ALT_TIER_1_TRIAL = 'State Alternative Tier I Trial';
export const VA_STREAMLINE_TRIAL = 'VA Streamline Trial';
export const STREAMLINE_MODIFICATION_TRAIL = 'Streamline Modification Trial';
export const AMRTZTN_SCHDL_UNAPPLICABLE_CASES = ['Disaster Modification', 'Disaster Partial Claim', 'Payment Deferral', 'Payment Deferral Disaster', 'Repayment Plan', 'Streamline Modification Trial'];
export const NPV_DETAILS_APPLICABLE_CASES = ['State Alternative Trial'];
export const TRCK_PYMT_APPLICABLE_CASES_FOR_TRIAL = [
  'Flex Modification Trial', 'Disaster VA Trial', 'MetLife Standard Trial', 'MetLife Pandemic Trial', 'VA Streamline Trial',
  'HAMP FHA Trial', 'Disaster FHA Trial', 'Special Servicing Trial', 'USDA Stand Alone MRA Trial', 'USDA Special Loan Servicing Trial',
  'USDA Traditional Modification 2 Trial', 'VA Servicing Purchase Trial', 'State Alternative Trial', 'Traditional Modification Trial', 'Streamline Modification Trial', 'VA Trial',
];
export const TRCK_PYMT_APPLICABLE_CASES_FOR_MOD = [
  'Disaster VA Modification', 'Flex Modification', 'MetLife Standard Modification', 'MetLife Pandemic Modification', 'VA Streamline Modification',
  'HAMP FHA', 'Special Servicing Modification', 'USDA Stand Alone MRA', 'USDA Special Loan Servicing', 'USDA Traditional Modification 2',
  'VA Servicing Purchase', 'State Alternative Modification', 'Streamline Modification',
];
export const TRCK_PYMT_APPLICABLE_CASES_FOR_FORBEARANCE = [
  'Repayment Plan', 'VA Repayment Plan', 'VA Special Forbearance', 'USDA Special Forbearance', 'USDA Special Unemployment Forbearance',
];
export const FULFILLMENT_CHECKLIST_APPLICABLE_CASES = [
  'Flex Modification', 'Disaster Partial Claim', 'Disaster FHA Modification', 'Disaster VA Modification', 'Disaster USDA Modification',
  'MetLife Standard Modification', 'MetLife Pandemic Modification', 'Disaster Cap & Extend Modification', 'VA Streamline Modification',
  'HAMP FHA', 'Special Servicing Modification', 'Traditional Modification', 'VA Affordable Modification', 'VA Special Forbearance',
  'VA Repayment Plan', 'Investor Mod', 'Disaster Extension Modification', 'USDA Stand Alone MRA',
  'USDA Special Loan Servicing', 'USDA Traditional Modification 2', 'VA 30 Year Modification', 'VA 40 Year Modification',
  'VA Servicing Purchase', 'State Alternative Modification', 'Streamline Modification', 'Payment Supplement',
];
export const BOOKING_APPLICABLE_CASES = [
  'Flex Modification', 'Disaster Partial Claim', 'Disaster FHA Modification', 'Disaster VA Modification', 'Disaster USDA Modification',
  'MetLife Standard Modification', 'MetLife Pandemic Modification', 'Disaster Cap & Extend Modification', 'VA Streamline Modification',
  'HAMP FHA', 'Special Servicing Modification', 'Traditional Modification', 'VA Affordable Modification',
  'VA Special Forbearance', 'VA Repayment Plan', 'Investor Mod', 'Disaster Extension Modification', 'USDA Stand Alone MRA',
  'USDA Special Loan Servicing', 'USDA Traditional Modification 2', 'VA 30 Year Modification', 'VA 40 Year Modification',
  'VA Servicing Purchase', 'State Alternative Modification', 'Streamline Modification', 'Payment Supplement',
];

export const HARDSHIP_APPLICABLE_CASES = ['Flex Modification', 'Flex Modification Trial'];
export const TRCK_PYMT_OTHER_APPLICABLE_CASES = ['Payment Deferral', 'Payment Deferral Disaster'];
export const MOD_OFFER_LOAN_TYPES = [1, 2, 4, 7, 26, 33];
export const MOD_OFFER_INV_GRPS = ['FHLMC', 'FNMA', 'FNMA Waterfall', 'FHLMC Waterfall'];
export const BOOKED = 'Booked';
export const OPEN = 'Open';
export const SENT_FOR_APPROVAL = 'Sent for Approval';
export const LOCKED = 'Locked';
export const APPROVED = 'Approved';
export const CLOSED = 'Closed';
export const ACTIVE = 'Active';
export const REJECTED = 'Rejected';
export const SENT_FOR_REJECT = 'Sent for Reject';
export const NPV_DETAILS = 'NPV DETAILS';
export const EVAL_WIDGET = 'evalWidget';
export const REJECT_REASON_CONFIRMATION_DIALOG_MSG = 'Are you sure you want to clear the unsaved changes?';
export const OVERRIDE_CONFIRMATION_DIALOG_MSG = 'Are you sure you want to clear the unsaved changes?';
export const OVERRIDE_SUCCESS_DIALOG_MSG = 'Your override rule has been successfully updated.';
export const REPAYMENT_CASEDETAILS_DEFAULT_TABS = ['CASE DETAILS', 'ELIGIBILITY', 'CALC LOG'];
export const WORKOUT_TYPE = 'Workout Type';
export const START_DATE = 'Start Date';
export const DURATION = 'Duration';
export const EXTENDED_DURATION = 'Extended Duration';
export const MONTHLY_REPAYMENT_AMOUNT = 'Monthly Repayment Amount';
export const DELEGATION_INDICATOR = 'Delegation Indicator';
export const REPAYMENT_AMOUNT_REGEX = /^\d*\.?(?:\d{1,2})?$/;
export const REPAYMENT_ERROR_MSG = 'Please enter valid currency value';
export const REPAYMENT_EVAL = 'Repayment Plan Case';
export const SPECIAL_SERVICING_TRIAL = 'Special Servicing Trial';
export const SPECIAL_SERVICING_MOD = 'Special Servicing Modification';
export const REPAYMENT_CASE = 'Repayment Plan';
export const INVALID_STATUS_FOR_REJECT = ['Closed', 'Sent for Approval', 'Approved', 'Booked', 'Locked', 'Open'];
export const EXTEND_PLAN_CONFIRMATION_DIALOG_MSG = 'You are about to extend this plan, Click Ok to continue';
export const STEP_RATES_MODIFY_CASETYPE = [SPECIAL_SERVICING_TRIAL, SPECIAL_SERVICING_MOD];
export const ENABLE_DELETE_TRACK_PAYMENT_INFO = [
  { status: 'Approved', caseType: 'Repayment Plan Case' },
  { status: 'SentForApproval', caseType: 'Payment Deferral Disaster' }];

export const NON_CURRENCY_ONLY_REGEX = /[a-zA-Z]|[~`(@!#$%^&*+_)=\-[\]\\';/{}|\\":<>?]/;
export const DONE_POPUP_CONFIRMATION_MSG = 'Are you sure you want to leave?';
export const REQUIRED_USER_GROUPS = ['beuw', 'beuw-mgr', 'beuw-beta', 'docgen', 'docgen-mgr'];
export const REJECTED_BUTTON_GROUPS = ['feuw-mgr', 'beuw-mgr', 'secondlook-mgr', 'vp', 'avp'];
export const STANDARDEVAL_REQUIRED_USER_GROUPS = ['feuw', 'feuw-mgr', 'feuw-beta', 'docsin-mgr', 'invset', 'invset-mgr', 'postmodstager', 'postmodstager-mgr',
  'rpsstager-mgr', 'rpsstager', 'lossmitigation', 'lossmitigation-mgr'];
export const CASEDETAILS_EDITABLE_LOSS_MIT_GROUPS = ['lossmitigation', 'lossmitigation-mgr'];
export const CASEDETAILS_EDITABLE_CMOD_GROUPS = [
  'lossmitigation', 'lossmitigation-mgr', 'proc', 'proc-mgr', 'feuw', 'feuw-mgr', 'feuw-beta', 'beuw', 'beuw-mgr', 'beuw-beta', 'docgen', 'docgen-mgr',
  'docsin', 'docsin-mgr', 'invset', 'invset-mgr', 'postmodstager', 'postmodstager-mgr', 'rpsstager-mgr', 'rpsstager',
];
export const REPAYMENT_CASE_TYPE = 'Repayment Plan';
export const STANDALONE_REPAYMENT_PLAN = 'Standalone Repayment Plan';
export const PANDEMIC = 'Pandemic';
export const DISASTER = 'Disaster';

export const FORBEARANCE_WORKOUT_TYPE = 'F';

export const INVALID_WORKOUT_TYPES_FOR_APPROVED_CASES = ['T', 'F'];

export const DISASTER_FHA_MODIFICATION = 'Disaster FHA Modification';
export const DISASTER_PARTIAL_CLAIM = 'Disaster Partial Claim';
export const DISASTER_FHA_TRIAL = 'Disaster FHA Trial';
export const TRADITIONAL_MODIFICATION = 'Traditional Modification';
export const ACTIVE_CASE_TYPES = [
  DISASTER_FHA_MODIFICATION,
  DISASTER_FHA_TRIAL,
  DISASTER_PARTIAL_CLAIM,
];
export const APPROVED_CASE_TYPES = [
  DISASTER_FHA_MODIFICATION,
  DISASTER_PARTIAL_CLAIM,
];

export const EVAL_STATUS_CASE_TYPES = ['Approved', 'Active'];
export const CAPITALIZE_CURRENT_FORBEARANCE = 'capitalizeCurrentForbearance';
export const NEW_RATE = 'caseInterestRate';
export const NEW_TERM = 'caseModifiedTerm';

export const TRACK_PAYMENT_CASE_TYPES_UPDATE = ['Rejected', 'Failed'];

export const repaymentTableHeaders = [
  'Description',
  'Date',
  'Contractual Portion',
  'Delinquency Portion',
  'Monthly Repayment Amount',
];

export const PAST_DUE_PAYMENTS_TABLE_COLUMNS = [
  {
    name: 'description',
    label: 'Description',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'date',
    label: 'Date',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'contractualAmount',
    label: 'Contractual Amount',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
    cellFormat: value => convertToUSCurrency.format(value),
  },
];

export const TOTAL_DURATION_TABLE_COLUMNS = [
  {
    name: 'extension',
    label: 'Extension',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'date',
    label: 'Date',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'paymentAmount',
    label: 'Payment Amount',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
    cellFormat: value => convertToUSCurrency.format(value),
  },
  {
    name: 'noOfmonthsExtended',
    label: 'Number of Month(s) Extended',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const PAYMENTS_CHANGES_TABLE_COLUMNS = [
  '#',
  'Date',
  'ChangeType',
  'ChangeAmount/Rate',
  'New Total Amount',
];

export const CASEDETAILS_DISABLED_WORKOUTTYPE = ['va repayment plan', 'va special forbearance', 'usda special forbearance', 'usda special unemployment forbearance'];
export const REPAYMENT_STANDARD_CASES = ['usda special forbearance', 'usda special unemployment forbearance'];
export const NONDELAPPROVALDATE_CASETYPE = [
  'Investor Mod', 'USDA Traditional Modification1', 'USDA Special Loan Servicing', 'USDA Traditional Modification', 'USDA Stand Alone MRA',
  'USDA Special Loan Servicing', 'USDA Special Loan Servicing Trial', 'USDA Traditional Modification 2 Trial', 'USDA Traditional Modification 2',
  'Traditional Modification Trial', 'State Alternative Modification', 'State Alternative Trial',
];

export const COMBOBOX_OPTIONS_PAIRS_MAPPING = [
  {
    columnName: 'LockedDelegateType', source: 'UI', value: ['Delegated', 'NonDelegated'], view: 'universal',
  },
  {
    columnName: 'workoutType', source: 'service', value: 'deferralType', view: 'universal',
  },
  {
    columnName: 'workoutType', source: 'service', value: 'workoutTypes', view: 'forbearance',
  },
  {
    columnName: 'deferralType', source: 'service', value: 'deferralType', view: 'universal',
  },
  {
    columnName: 'capitalizeCurrentForbearance', source: 'service', value: 'capitalizeCurrentForbearance', view: 'universal',
  },
  {
    columnName: 'capitalizeFutureEscrow', source: 'service', value: 'capitalizeFutureEscrow', view: 'universal',
  },
  {
    columnName: 'trialDuration', source: 'service', value: 'trialDuration', view: 'universal',
  },
  {
    columnName: 'escrowSpreadMonths', source: 'service', value: 'escrowSpreadMonths', view: 'universal',
  },
  {
    columnName: 'calcMethod', source: 'service', value: 'calcMethod', view: 'universal',
  },
  {
    columnName: 'caseStartDate', source: 'service', value: 'PlanStartDates', view: 'forbearance',
  },
  {
    columnName: 'planDuration', source: 'service', value: 'PlanStartDates', view: 'forbearance',
  },
];

export const REJ_REASONS_SELECTOR = 'rejectReasonsDropDown';

export const REJ_SUBREASONS_SELECTOR = 'rejectSubReasonsDropDown';

export const FieldsToDisableForSpecialServicingTrialWithoutOpenWaterFall = ['LockedHMPRate', 'LockedForbearenceAmount', 'capitalizeCurrentForbearance', 'capitalizeFutureEscrow', 'escrowSpreadMonths', 'targetPI', 'downPaymentAmount', 'NewForgivenessAmount', 'trialDuration'];
export const FieldsToDisableForSpecialServicingModificationWithoutOpenWaterFall = ['LockedHMPRate', 'LockedForbearenceAmount', 'capitalizeCurrentForbearance', 'capitalizeFutureEscrow', 'escrowSpreadMonths', 'trialDuration', 'targetPI', 'NewForgivenessAmount', 'downPaymentAmount'];
export const FieldsToDisableForRepaymentPlanCase = ['planDuration'];
export const FieldsToDisableForPaymentSupplement = ['borrowerExecutionDate'];
export const WATERFALL_SEGREGATION = ['proc', 'proc-mgr', 'feuw', 'feuw-mgr', 'beuw', 'beuw-mgr', 'docgen', 'docgen-mgr', 'docsin', 'docsin-mgr', 'invset', 'invset-mgr', 'postmodstager', 'postmodstager-mgr', 'secondlook', 'secondlook-mgr'];
export const STREAMLINE_MOD_ACCEPTANCE_TYPES = ['V', 'W'];
export const VALID_LOAN_CHNG_TYPES = ['E', 'P', 'R', 'S'];
export const PAYMENT_SUPPLEMENT = 'Payment Supplement';
export const TRACK_PAYMENT_DELETE_ICON_VISIBILITY_CASE_TYPES = ['Closed', APPROVED];
export const REASON_CODE_VALID_INPUT = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

export const DISASTER_EDIT_USER_GROUPS = ['beuw', 'beuw-mgr', 'beuw-beta', 'invset', 'invset-mgr', 'invset-beta'];
export const FINANCIALS_EDIT_USER_GROUPS = ['feuw', 'feuw-mgr', 'feuw-beta', 'beuw', 'beuw-mgr', 'beuw-beta', 'docgen', 'docgen-mgr'];
export const COLLATERAL_ACCEPTANCE_ENABLED_GROUPS = ['proc', 'proc-mgr', 'feuw', 'feuw-mgr', 'beuw', 'beuw-mgr', 'docgen', 'docgen-mgr', 'docsin', 'docsin-mgr', 'invset', 'invset-mgr', 'postmodstager', 'postmodstager-mgr'];

export const DELETE_ICON_ENABLE_AD_LIST_WITHOUT_WORKOUT_TYPE = ['proc', 'proc-mgr', 'feuw', 'feuw-mgr', 'beuw', 'beuw-mgr', 'docgen', 'docgen-mgr', 'docsin', 'docsin-mgr', 'invset', 'invset-mgr', 'postmodstager', 'postmodstager-mgr'];
export const DELETE_ICON_ENABLE_AD_LIST_WITH_WORKOUT_TYPE = ['lossmitigation', 'lossmitigation-mgr'];
export const STREAMLINE_MOD_OFFER_VALIDATION_MSG = 'Can\'t accept - Eval must be in approved status';

export const TRACKPAYMENT_EDITABLE_GROUPS = [
  Groups.DOC_PROCESSOR,
  Groups.DOC_PROCESSOR_MGR,
  Groups.FRONTEND_UNDERWRITER,
  Groups.FRONTEND_MANAGER,
  Groups.BACKEND_UNDERWRITER,
  Groups.BACKEND_MANAGER,
  Groups.DOC_GEN,
  Groups.DOC_GEN_MGR,
  Groups.DOCS_IN,
  Groups.DOCS_IN_MGR,
  Groups.SECONDLOOK,
  Groups.SECONDLOOK_MGR,
];
export const TRACKPAYMENT_EDITABLE_LM_GROUPS = [
  ...TRACKPAYMENT_EDITABLE_GROUPS,
  Groups.LOSS_MITIGATION_MGR];

export const REPAY_HEADER_EDIT_ONLY_GROUPS = [
  'lossmitigation', 'lossmitigation-mgr', 'avp', 'vp', 'proc', 'proc-mgr', 'feuw', 'feuw-mgr',
  'beuw', 'beuw-mgr', 'docgen', 'docgen-mgr', 'docsin', 'docsin-mgr',
];

export const REPAY_ENABLE_APPROVE_REJECT_GROUPS = ['lossmitigation-mgr', 'avp', 'vp'];

export const VA_SERVICING_PURCHASE = 'VA Servicing Purchase';
export const VA_APPROVAL_DATE = 'vaApprovalDate';
export const NON_DEL_APPROVAL_DATE = 'nondelapprovaldate';
export const DELEGATED = 'Delegated';
export const NON_DELEGATED = 'NonDelegated';
export const LOCKED_DELEGATE_TYPE = 'LockedDelegateType';
export const NON_DEL_DATE_ERR_MSG1 = 'Non delegated uploaded date field cannot be blank';
export const NON_DEL_DATE_ERR_MSG2 = 'Date selected cannot be date prior to 1/1/2020';
export const NON_DEL_DATE_ERR_MSG3 = 'Future Date cannot be selected';
export const UPB_AT_DEFAULT = 'upbAtDefault';
export const ORIGINAL_MATURITY_DATE = 'originalMaturityDate';
export const PRIOR_MRA_AMT = 'priorMRA';
export const GEN_INFO = 'genInfo';
export const CASE_INFO = 'caseInfo';
export const REPAY_NONDELAPPROVALDATE_CASETYPE = ['VA Special Forbearance', 'VA Repayment Plan', 'USDA Special Unemployment Forbearance'];
export const FHAW2 = 20;
export const FNMAW = 5;
export const FHLMCW = 6;
export const DISASTER_WTFALL = 13;
export const VA_WTFALL = 23;
export const FHA_RECOVERY_REQUESTTYPE = 'fharecovery';
export const FNMA_FHLMC_REQUESTTYPE = 'deferral';
export const FNMA_FHLMC_WORKOUTTYPE = 'Payment Deferral';
export const CASE_CREATION_AD_GROUPS = [
  'lossmitigation', 'lossmitigation-mgr', 'proc', 'proc-mgr', 'feuw', 'feuw-mgr', 'feuw-beta', 'beuw', 'beuw-mgr', 'beuw-beta', 'docgen', 'docgen-mgr',
  'docsin', 'docsin-mgr', 'invset', 'invset-mgr', 'postmodstager', 'postmodstager-mgr', 'secondlook', 'secondlook-mgr',
];
export const DEFAULT_WATERFALL_CASETYPES = ['Short Sale', 'Deed In Lieu'];
export const LAST_PAID_INSTALLMENT = 'Do Not Use';
export const NOT_TRIGGER_RULES_CASE_STATUS = ['approved', 'closed', 'locked', 'rejected', 'sent for approval', 'booked'];
export const BEUW_MGR_OVERRIDE_RULE_LIST = [
  433, 448, 460, 313, 466, 296, 299, 303, 353, 358, 373, 374, 375, 387, 462,
  400, 403, 410, 411, 413, 414, 436, 438, 458, 322, 210, 161, 209, 467, 468,
  472, 475, 487, 492, 493,
];
export const ERROR_OVERRIDE_RULES_LIST = [262];
export const ERROR_OVERRIDE_GROUPS = ['vp', 'avp', 'secondlook-mgr'];
export const SMDU_POPUP_ELIGIBLE_CASES = ['Flex Modification Trial', 'Payment Deferral Disaster', 'Payment Deferral'];
export const VP_USER_GROUP = 'vp';
export const AVP_USER_GROUP = 'avp';

export const UNREJECT_TABLE_COLUMNS = [
  {
    name: 'caseId',
    label: 'Case ID',
    align: 'left',
  },
  {
    name: 'caseType',
    label: 'Case Type',
    align: 'left',
  },
  {
    name: 'status',
    label: 'Status',
    align: 'left',
  },
  {
    name: 'statusDate',
    label: 'Status Date',
    align: 'left',
  },
];

export const REPAY_NOT_TRIGGER_RULES_CASE_STATUS = ['open', 'approved', 'closed', 'locked', 'rejected', 'sent for approval'];

export const SPECIAL_REPAY_CASE_TYPES = ['VA Special Forbearance', 'USDA Special Forbearance', 'USDA Special Unemployment Forbearance', 'USDA Special Loan Servicing Trial', 'USDA Special Loan Servicing'];

export const UNREJECT_BTN_ACCESS = [
  'vp', 'avp', 'secondlook-mgr', 'lossmitigation-mgr', 'proc-mgr', 'feuw-mgr', 'beuw-mgr', 'docgen-mgr', 'docsin-mgr', 'invset-mgr', 'postmodstager-mgr', 'rpsstager-mgr',
  'stager-mgr', 'docgenvendor-mgr', 'rshstager-mgr', 'escrow-mgr', 'booking-mgr',
];

export const AD_GROUPS_CASE_FIELDS_EDITABLE = [
  'capitalizecurrentforbearance', 'dateofdefault', 'deferralcorporateadvance',
  'deferralescrowadvance', 'deferralinterest', 'deferralprincipal',
  'escrowspreadmonths', 'overridetrialperiod', 'priorpartialclaimamount',
  'subsidyamount', 'targetpi', 'trialduration', 'unpaidlegalfee',
  'upbatdefault', 'armresetpaymentamount', 'calcmethod', 'capitalizefutureescrow',
  'caseinterestrate', 'casemodifiedterm', 'lockedcollateralvalue',
  'lockedcurrentinterestdue', 'lockeddelegatetype', 'lockedforbearenceamount',
  'lockedhmprate', 'lockedmaturitydate', 'lockedstartdate',
  'nondelapprovaldate', 'priorclaimlastpaidinstallmentdate', 'priormra',
  'workouttype', 'borrowerexecutiondate', 'newforgivenessamount', 'lastpaidinstallmentdate',
  'vaapprovaldate', 'forbearanceallowed', 'maxmaturitydate', 'forgivenessallowed',
  'downpaymentamount', 'premodpi', 'stepratesresetbtn', 'stepratesmodifybtn',
  'priorMonthsDeferred',
];

export const AD_GROUPS_CASE_SKILLS = ['vp', 'avp', 'beuw-mgr', 'beuw'];

export const AVP_VP_OVERRIDE_RULE_LIST = [
  161, 209, 210, 224, 296, 299, 303, 313, 314, 322, 353, 358, 370, 373, 374, 375,
  378, 379, 380, 387, 400, 403, 410, 411, 413, 414, 418, 432, 433, 436, 438,
  448, 458, 460, 466, 467, 468, 472, 475, 479, 482, 483, 487, 462, 492, 493,
];

export const OPEN_WATERFALL = 'Open Waterfall';
