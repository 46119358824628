/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@material-ui/core/index';
import './NPVResults.css';
import { connect } from 'react-redux';
import * as moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import DashboardModel from 'models/Dashboard';
import { operations as tombstoneOperations, selectors as tombstoneSelectors } from 'ducks/tombstone';
import { operations as netpresentvalueOperations, selectors as netpresentvalueSelectors } from 'ducks/netpresentvalue';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import NPVDataFile from 'components/NetPresentValue/NPVDataFile/NPVDataFile';
import NPVResultsHistory from './NPVResultsHistory';
import { capitalizeWords } from '../../../../lib/capitalizeWords';

function NPVResults(props) {
  const [NPVResultHistoryData, setNPVResultHistoryData] = useState({});
  const {
    setNPVResultData, setNPVHistoryData, npvHistoryData, npvData, setRequestIdData,
    taskName, npvRequestId, npvRequestStatus, isAssigned,
  } = props;
  const [isDisabled, setDisabled] = useState(!isAssigned);
  const [historyWidth, setHistoryWidth] = useState(!isAssigned);
  const [buttonWidth, setButtonWidth] = useState(!isAssigned);
  const handleClose = () => {
    const { setChecklistCenterPaneData, groupName, prevCenterPaneVal } = props;
    if (groupName === DashboardModel.SEARCH_LOAN) {
      setChecklistCenterPaneData(prevCenterPaneVal);
    } else {
      setChecklistCenterPaneData('Checklist');
    }
  };
  useEffect(() => {
    setNPVResultData();
    setNPVHistoryData();
  }, []);

  useEffect(() => {
    setNPVResultData();
  }, [npvRequestId]);

  useEffect(() => {
    setDisabled(!isAssigned);
  }, [isAssigned]);

  useEffect(() => {
    if (npvRequestStatus === 'Pending') {
      setNPVResultHistoryData({});
      setHistoryWidth(5);
      setButtonWidth(6);
    } else {
      setHistoryWidth(9);
      setButtonWidth(2);
    }
  }, [npvRequestStatus]);

  useEffect(() => {
    if (NPVResultHistoryData && NPVResultHistoryData.requestId) {
      setRequestIdData(NPVResultHistoryData.requestId);
    }
  }, [NPVResultHistoryData]);

  const renderItems = item => (
    <>
      {
        npvData[item] && npvData[item].map(({ value, title }) => (
          <Grid key={item.title} item styleName="item">
            <span styleName="title">{title}</span>
            <span styleName={`content${value && value.length > 15 ? ' truncate' : ''}`} title={value === '' ? '-' : value}>
              {
                value === '' ? '-' : value
              }
            </span>
          </Grid>
        ))
      }
    </>
  );

  const renderHistoryHeader = item => (
    <>
      {
        item && item.createDate && (
          <div styleName="npv-history-heading-container">
            <Typography styleName="npv-history-heading">
              NPV Created Date:
              <span styleName="npv-history-value">{moment(item.createDate).format('MM/DD/YYYY')}</span>
            </Typography>
            <Typography styleName="npv-history-heading">
              NPV Result:
              <span styleName="npv-history-value">{capitalizeWords(item.npvStatus)}</span>
            </Typography>
            <Typography styleName="npv-history-heading">
              NPV Processed By:
              <span styleName="npv-history-value">{item.createBy}</span>
            </Typography>
          </div>
        )

      }
    </>
  );
  useEffect(() => {
    renderHistoryHeader(npvHistoryData);
  }, [npvHistoryData]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container direction="row">
          <Grid item xs={historyWidth}>
            <div styleName="container">
              <Typography styleName="heading">
                NPV Details
              </Typography>
              <IconButton>
                <NPVResultsHistory
                  historyData={npvHistoryData}
                  setNPVResultHistoryData={setNPVResultHistoryData}
                />
              </IconButton>
              <div styleName="npv-history-heading-container">
                {renderHistoryHeader(NPVResultHistoryData)}
              </div>
            </div>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }} xs={buttonWidth}>
            <NPVDataFile isDisabled={isDisabled} NPVResultHistoryData={NPVResultHistoryData} />
          </Grid>
          {!(taskName && taskName.activeTile === 'NPV')
            ? (
              <Grid item styleName="closeButton" xs={1}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            ) : null}
        </Grid>
      </Grid>
      <Grid item spacing={1} xs={12}>
        <Grid container direction="row">
          <Grid item xs={4}>
            <Grid container direction="column">
              <Grid item styleName="containerBackground info">
                <span styleName="header">NPV Info</span>
                <Grid container direction="column" spacing={1} style={{ height: '22rem', marginTop: '1rem' }}>
                  {renderItems('npvInfo')}
                </Grid>
              </Grid>
              <Grid item styleName="containerBackground info">
                <span styleName="header">NPV Results</span>
                <Grid container direction="column" spacing={1} style={{ height: '15rem', marginTop: '1rem' }}>
                  {renderItems('npvResults')}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item styleName="containerBackground infoRequest" xs={8}>
            <span styleName="header">NPV Requested</span>
            <Grid container direction="column" spacing={2} style={{ height: '36rem', marginTop: '1rem' }}>
              {renderItems('npvRequested')}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

NPVResults.defaultProps = {
  taskName: {},
};

NPVResults.propTypes = {
  groupName: PropTypes.string.isRequired,
  isAssigned: PropTypes.bool.isRequired,
  npvData: PropTypes.func.isRequired,
  npvHistoryData: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  npvRequestId: PropTypes.number.isRequired,
  npvRequestStatus: PropTypes.string.isRequired,
  prevCenterPaneVal: PropTypes.string.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setNPVHistoryData: PropTypes.func.isRequired,
  setNPVResultData: PropTypes.func.isRequired,
  setRequestIdData: PropTypes.func.isRequired,
  taskName: PropTypes.shape({
    activeTab: PropTypes.string.isRequired,
    activeTile: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = state => ({
  isAssigned: dashboardSelectors.isAssigned(state),
  npvData: netpresentvalueSelectors.getNPVResult(state),
  npvHistoryData: netpresentvalueSelectors.getNPVHistory(state),
  npvRequestId: netpresentvalueSelectors.getNPVRequestId(state),
  npvRequestStatus: netpresentvalueSelectors.getNPVRqstStatus(state),
  taskName: dashboardSelectors.stagerTaskName(state),
  groupName: dashboardSelectors.groupName(state),
  prevCenterPaneVal: tombstoneSelectors.getPrevChecklistCenterPaneView(state),
});

const mapDispatchToProps = dispatch => ({
  setChecklistCenterPaneData: tombstoneOperations.setChecklistCenterPaneDataOperation(dispatch),
  setNPVHistoryData: netpresentvalueOperations.npvHistoryOperation(dispatch),
  setNPVResultData: netpresentvalueOperations.setNpvResultOperation(dispatch),
  setRequestIdData: netpresentvalueOperations.setRequestIdOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NPVResults);
