import React from 'react';
import './UnRejectRequest.css';
import PropTypes from 'prop-types';
import UnRejectRequestCase from './UnRejectRequestCase/UnRejectRequestCase';


const UnRejectRequest = ({ unRejectRequest, recordsInfo }) => (
  <div>
    <UnRejectRequestCase recordsInfo={recordsInfo} unRejectRequest={unRejectRequest} />
  </div>
);
UnRejectRequest.propTypes = {
  recordsInfo: PropTypes.shape().isRequired,
  unRejectRequest: PropTypes.func.isRequired,
};

export default UnRejectRequest;
