const R = require('ramda');

const textFields = {
  'inc-text': {
    value: 'weekly-text',
  },
  'ri-total-text': {
    title: 'ri-total-text',
    value: '',
  },
  'se-total-text': {
    value: 'se-total-value',
    title: 'se-total',
  },
  'pitia-amt': {
    title: 'pitia-amt-title',
    div: 'display-in-row',
    inputProps: { style: { width: '17rem' } },
  },
  'pay-periods-text': {
    div: 'pay-periods-text',
    title: 'pay-periods-title',
    textField: 'pay-period-textField',
    inputProps: { style: { paddingTop: '8px', paddingBottom: '8px' } },
  },
  'mrla-text': {
    textField: 'ri-div-label',
    title: 'mrla-title',
    div: 'display-in-row',
  },
  'ri-label': {
    div: 'ri-div-label',
    title: 'ri-title',
    value: 'ri-label',
  },
  'weekly-amt': {
    div: 'weekly-amt',
    textField: 'weekly-text',
  },
  'monthly-inc': {
    div: 'monthly-inc',
  },
  'se-nom': {
    div: 'se-nom-div',
    title: 'se-nom-title',
  },
  'se-pao': {
    div: 'se-nom-div',
    title: 'se-nom-title',
  },
  'se-amount': {
    div: 'se-amount-div',
    title: 'se-amount-title',
  },
  'cnsdt-net': {
    div: 'cnsdt-net',
    title: 'cnsdt-net-title',
    value: 'cnsdt-net-value',
  },
  'reason-for': {
    div: 'reason-for',
    value: 'reason-for-value',
    textField: 'reason-for-textbox',
  },
  commentBox: {
    div: 'commentBox',
    textField: 'reason-for-textbox',
  },
  'cnsdt-income-title': {
    div: 'cnsdt-income-div',
  },
  'pp-text': {
    value: 'pp-value',
  },
  'pp-value': {
    value: 'pp-value',
  },
  'pp-value-red': {
    value: 'pp-value-red',
  },
  'pp-value-green': {
    value: 'pp-value-green',
  },
  'cnsdt-exp-sec-amt': {
    div: 'cnsdt-exp-amt-text',
    value: 'cnsdt-exp-amt-text',
    textField: 'cnsdt-exp-amt-text',
    inputProps: 'cnsdt-exp-amt-text',
  },
  'cnsdt-net-dti': {
    div: 'cnsdt-net-dti',
    title: 'cnsdt-net-dti-title',
    value: 'cnsdt-net-dti-value',
  },
  'cnsdt-net-exp': {
    div: 'cnsdt-net-exp',
    title: 'cnsdt-net-exp-title',
    value: 'cnsdt-net-exp-value',
  },
  'cnsdt-inc-count-indc': {
    div: 'cnsdt-inc-count-indc-div',
  },
  addr: {
    inputProps: { autoComplete: 'new-password' },
  },
  'acc-esc': {
    div: 'acc-esc-div',
    title: 'se-nom-title',
  },
  'ana-brk': {
    div: 'ana-brk-div',
    title: 'ana-brk-title',
  },
  'commentBox-as': {
    div: 'commentBox-as',
    title: 'reject-reason-as',
    textField: 'reason-for-textbox-as',
  },
  'se-nom-as': {
    div: 'se-nom-div-as',
    title: 'se-nom-title-as',
    textField: 'se-nom-tfield-as',
    icon: 'se-nom-icon-as',
  },
  'se-nom-text-as': {
    div: 'se-nom-div-text-as',
    title: 'se-nom-title-as',
    textField: 'se-nom-tfield-as',
    icon: 'se-nom-icon-as',
  },
  default: {

  },
};

const taskSection = {
  'fixed-income-net-value': {
    'grid-label': 'fixed-income-net-value',
  },
  'inc-calc-borr': {
    item: 'inc-calc-borr-item',
    header: 'radio-control-label',
  },
  currPayOff: {
    'label-text': 'ana-brk-label',
    gridItem: 'ic-ri-sect',
  },
  'we-title': {
    item: 'escrow',
    header: 'we-heading',
  },
  'ana-brk-title': {
    item: 'escrow',
    header: 'we-heading',
  },
  loanInfoToOrder: {

  },
  'ri-title': {
    item: 'ri-title-item',
  },
  'ri-title-heading': {
    item: 'ri-title-item',
    header: 'we-heading',
  },
  'se-title': {
    item: 'ri-title-item',
    header: 'we-heading',
  },
  escrowInfo: {
    header: 'escrowInfo',
  },
  'inc-calc-placeholder': {
    header: 'header-placeholder',
  },
  wageEarnings: {
    item: 'inc-calc-borr-item',
  },
  manualEntry: {
    item: 'inc-calc-borr-item',
  },
  fixedIncome: {
    item: 'inc-calc-borr-item',
  },
  grossYtd: {
    item: 'sect-title-delete',
  },
  grossBase: {
    item: 'sect-title-delete',
  },
  'add-contrib': {
    grid: 'add-contrib-grid',
  },
  'rental-address-wrapper': {
    grid: 'rental-address-wrapper',
    icon: 'rental-address-icon',
  },
  'self-address-wrapper': {
    grid: 'rental-address-wrapper',
    icon: 'rental-address-icon',
  },
  'add-contrib-sect': {
    header: 'add-contrib-header',
    item: 'add-contrib-item',
    icon: 'chevron-left',
  },
  'rental-income-sect': {
    icon: 'rental-income-sect-icon',
  },
  'self-income-sect': {
    icon: 'rental-income-sect-icon',
  },
  'rental-share': {
    grid: 'rental-share-grid',
    gridItem: 'rental-share-grid-item',
  },
  'self-share': {
    grid: 'rental-share-grid',
    gridItem: 'rental-share-grid-item',
  },
  'ri-share-sect-title': {
    header: 'ri-share-sect-header',
  },
  'se-share-sect-title': {
    header: 'ri-share-sect-header',
  },
  'cnsdt-accordian': {
    summary: 'cnsdt-accordian-summary',
    label: 'cnsdt-accordian-label',
    labelValue: 'cnsdt-accordian-labelValue',
    details: 'cnsdt-accordian-details',
  },
  'cnsdt-income-data-headers': {
    header: 'cnsdt-inc-data-header-div',
    space: 'cnsdt-inc-data-header-space',
  },
  'cnsdt-income-data-row': {
    inctype: 'cnsdt-income-data-row-inctype',
    amount: 'cnsdt-income-data-row-amount',
  },
  accountServices: {
    title: 'text-label',
    header: 'radio-control-label',
    icon: '',
    grid: 'grid-as',
    gridItem: '',
    label: '',
    labelValue: '',
    summary: '',
    details: '',
    'label-text': 'label-text-as',
    'grid-label': 'default-grid-label-spacing',
  },
  default: {
    title: 'text-label',
    header: 'radio-control-label',
    icon: '',
    grid: 'default-grid',
    gridItem: '',
    label: '',
    labelValue: '',
    summary: '',
    details: '',
    'grid-label': 'default-grid-label-spacing',
  },
  accountServicesLeftGrid: {
    'label-text': 'label-text-as',
  },
};

const datePicker = {
  'se-pl-date': {
    div: 'date-flex-div',
    title: 'date-flex-title',
    picker: 'date-flex-picker',
  },
  'datepicker-we': {
    title: 'text-label',
    picker: 'datepicker-we',
  },
  analysisBreakdown: {
    div: 'date-escrow-div',
    title: 'date-escrow',
    picker: 'date-flex-title',
  },
  escrow: {
    div: 'date-flex-div',
    title: 'date-flex-title',
    picker: 'date-flex-title',
  },
  accountServices: {
    div: 'date-div-as',
    title: 'date-title-as',
    picker: 'date-flex-title-as',
    enablePicker: 'date-flex-title-as-enabled',
  },
  accountServicesRefDate: {
    div: 'date-div-ref-as',
    title: 'date-title-as',
    picker: 'date-flex-title-as',
    enablePicker: 'date-flex-title-as-enabled',
    inputProps: {
      fontSize: '0.9rem',
      height: '3rem',
      padding: '0rem',
    },
    buttonProps: {
      marginLeft: '-1rem',
    },
  },
  default: {
    title: 'text-label',
    picker: 'datepicker',
  },
};

const dropDown = {
  payFreq: {
    dropdown: 'payFreq',
    'dropdown-sect': 'displayInRow',
    title: 'pay-freq-title',
  },
  teacherPaySchedule: {
    dropdown: 'teacherPaySchedule',
    'dropdown-sect': 'displayInRow',
    title: 'pay-freq-title',
  },
  smallDropDown: {
    'dropdown-sect': 'selectType',
    select: 'small',
  },
  'we-bs-pay': {
    'dropdown-sect': 'inline',
    dropdown: 'inline-dropdown',
  },
  contributorType: {
    'dropdown-sect': '',
    dropdown: '',
    select: 'contrib',
    title: '',
  },
  spread: {
    dropdown: 'spread',
    'dropdown-sect': 'spreadEscrow',
    title: 'spreadTitle',
    select: 'medium',
  },
  'months-as': {
    'dropdown-sect': 'selectType-as',
    title: 'title-dropdown-as',
    select: 'large-as-disabled',
    enabledSelect: 'large-as-enabled',
    dropdown: 'dropdown-as',
  },
  'months-spr-as': {
    'dropdown-sect': 'selectType-spr-as',
    title: 'title-dropdown-as',
    select: 'large-as-disabled',
    enabledSelect: 'large-as-enabled',
    dropdown: 'dropdown-as',
  },
  default: {
    'dropdown-sect': 'displayInRow',
    title: 'title',
    select: 'select',
  },
};

const radioButtons = {
  'sicm-options': {
    paddingRight: '3rem',
  },
  accountServices: {
    div: 'radio-title-as',
    radioGroup: 'acc-radio-grp',
  },
  'verified-stated-radio': {
    div: 'vs-div',
    radioGroup: 'vs-rg',
  },
  default: {
    div: 'default-div',
    radioGroup: 'default-rg',
  },
};

const createSelect = {
  default: {
    button: 'cname-button',
    buttonIcon: 'cname-icon',
    createSelectWrapper: 'cname-create',
    createSelect: 'cname-button',
    dropDown: 'cname-dropdown',
  },
};

const direction = {
  top: { display: 'flex', flexDirection: 'column' },
  left: { display: 'flex', flexDirection: 'row' },
  right: { display: 'flex', flexDirection: 'row-reverse' },
  bottom: { display: 'flex', flexDirection: 'column-reverse' },
};

const tabStyle = {
  docBorrowerTab: {
    tabs: 'doc-tab-view',
  },
  docWidget: {
    tabs: 'doc-widget',
  },
  default: {
    tabs: 'tabview',
  },
};

const table = {
  'ana-brk-table': {
    table: 'fut-table',
    'row-highlight': 'odd',
  },
  default: {
    table: 'default-table',
    'row-highlight': 'none',
  },
};

const styleData = {
  table,
  datePicker,
  textFields,
  taskSection,
  dropDown,
  radioButtons,
  createSelect,
  tabStyle,
};

const getStyleObj = component => R.prop(null, component, styleData);

const getStyleName = (component, styleName, element) => R.pathOr(R.pathOr('', [component, 'default', element], styleData), [component, styleName, element], styleData);

module.exports = {
  getStyleName,
  getStyleObj,
  direction,
};
