
import React from 'react';
import { connect } from 'react-redux';
import { operations, selectors as tombstoneSelectors } from 'ducks/tombstone';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { operations as npvOperations } from 'ducks/netpresentvalue';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import './NPVResultViewIcon.css';
import {
  IconButton,
} from '@material-ui/core/index';
import { STANDARD_EVAL_SCREEN, CASE_DETAILS, REPAYMENT_PLAN_CASE } from 'constants/loanInfoComponents';
import DashboardModel from 'models/Dashboard';

class NPVResultViewIcon extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleCenterPaneView = this.handleCenterPaneView.bind(this);
  }

  handleCenterPaneView() {
    const {
      loanInfoComponent, setChecklistCenterPaneData, npvRequestId, setRequestIdData,
      group, setPrevchecklistCenterPane, centerPaneVal,
    } = this.props;
    if (group === DashboardModel.SEARCH_LOAN
      && [STANDARD_EVAL_SCREEN, CASE_DETAILS, REPAYMENT_PLAN_CASE].includes(centerPaneVal)) {
      setPrevchecklistCenterPane(centerPaneVal);
    }
    setChecklistCenterPaneData(loanInfoComponent);
    setRequestIdData(npvRequestId);
  }

  render() {
    const { npvStatus } = this.props;
    const styleName = (npvStatus === 'NA') ? 'icon-view-disabled' : 'icon-view';
    return (
      <IconButton onClick={this.handleCenterPaneView} size="small" styleName={`${styleName}`}>
        <VisibilityIcon />
      </IconButton>
    );
  }
}

NPVResultViewIcon.propTypes = {
  centerPaneVal: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  loanInfoComponent: PropTypes.string.isRequired,
  npvRequestId: PropTypes.string.isRequired,
  npvStatus: PropTypes.string.isRequired,
  setChecklistCenterPaneData: PropTypes.func.isRequired,
  setPrevchecklistCenterPane: PropTypes.func.isRequired,
  setRequestIdData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  centerPaneVal: tombstoneSelectors.getChecklistCenterPaneView(state),
  group: dashboardSelectors.groupName(state),
});

const mapDispatchToProps = dispatch => ({
  setChecklistCenterPaneData: operations.setChecklistCenterPaneDataOperation(dispatch),
  setRequestIdData: npvOperations.setRequestIdOperation(dispatch),
  setPrevchecklistCenterPane: operations.setPrevchecklistCenterPaneOpn(dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(NPVResultViewIcon);
