/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  Divider,
  TextField,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { operations as dashboardOperations } from 'ducks/dashboard';
import { operations as evalOperations } from 'ducks/eval';
import { selectors as evalSelectors } from 'ducks/eval';
import './RejectReasonPopup.css';
import * as R from 'ramda';
import { REJECT_REASON_CONFIRMATION_DIALOG_MSG, REASON_CODE_VALID_INPUT } from '../../../constants/eval';
import ConfirmationDialogBox from '../../Tasks/OptionalTask/ConfirmationDialogBox';

import Popup from '../../Popup';

const RejectReasonPopup = (props) => {
  const {
    fetchRejectReasons, evalRejectReasons, isOpen, setRejectreasonPopupStatus,
    saveRejectReason, checkIsLetterGenerated,
    fetchLetterGeneratedStatus,
  } = props;
  const [selectedOption, setSelectedOption] = useState('');
  const [reasonCodeInput, setReasonCodeInput] = useState('');
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [comment, setComment] = useState('');
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [openOverwritePopup, setOpenOverwritePopup] = useState(false);
  const [openFinalConfirmationPopup, setOpenFinalConfirmationPopup] = useState(false);

  useEffect(() => {
    fetchRejectReasons();
    checkIsLetterGenerated();
  }, []);

  const handleClose = () => {
    if (R.isEmpty(R.trim(selectedOption))) {
      setRejectreasonPopupStatus(false);
    } else {
      setOpenAlertBox(true);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSaveRejectreason = () => {
    setOpenFinalConfirmationPopup(true);
  };

  const handleFinalConfirmation = () => {
    setOpenFinalConfirmationPopup(false);

    if (fetchLetterGeneratedStatus !== undefined && fetchLetterGeneratedStatus
      && !openOverwritePopup) {
      setOpenOverwritePopup(true);
      return;
    }

    const selectedOptionArr = selectedOption.split('_');
    const [reasonCode, reason] = selectedOptionArr;
    let payload = {
      reason,
      reasonCode,
      comment,
      isOverrideLetter: 'NO',
    };

    if (fetchLetterGeneratedStatus !== undefined && fetchLetterGeneratedStatus
      && openOverwritePopup) {
      payload = { ...payload, isOverrideLetter: 'YES' };
    }

    saveRejectReason(payload);
    setSelectedOption('');
    setComment('');
    setRejectreasonPopupStatus(false);
    setIsButtonDisable(true);
  };

  const handleConfirmationDialogBox = (isConfirmed) => {
    setOpenAlertBox(false);
    if (isConfirmed) {
      setSelectedOption('');
      setComment('');
      setRejectreasonPopupStatus(false);
      setIsButtonDisable(true);
      setReasonCodeInput('');
    }
  };

  const handleComments = (event) => {
    const { value } = event.target;
    if (R.length(value) <= 1000) {
      setComment(value);
      setIsButtonDisable(R.isEmpty(R.trim(value)));
    }
  };

  const handleKeyDown = (event) => {
    const { key } = event;

    if (key === 'Enter') {
      setReasonCodeInput('');
    } else if (key === 'Backspace') {
      if (reasonCodeInput.length > 0) {
        setReasonCodeInput(reasonCodeInput.slice(0, -1));
      }
    } else if (key in REASON_CODE_VALID_INPUT) {
      setReasonCodeInput(reasonCodeInput + key);
    }
  };

  const filterOptionsByPrefix = () => {
    if (reasonCodeInput === '') {
      return evalRejectReasons;
    }

    return evalRejectReasons.filter(reason => R.pathOr('', ['reasonCode'], reason).toString().startsWith(reasonCodeInput));
  };

  const handleCancel = () => {
    setOpenOverwritePopup(false);
    // updateLetterGenerationStatus(false);
  };

  return (
    <Grid>
      <Dialog open={isOpen}>
        <DialogTitle>
          {' '}
          <Grid container item styleName="reject-reason-pop-grid-title">
            <Grid>
              <Typography styleName="reject-reason-pop-grid-title-name">
                Reject Reason
              </Typography>
            </Grid>
            <Grid>
              <IconButton onClick={handleClose} styleName="reject-reason-close-icon ">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ overflow: 'hidden' }}>
            <Grid item styleName="reason-label" xs={3}>
              <div>Reason</div>
            </Grid>
            <Grid item style={{ overflow: 'hidden' }} xs={9}>
              <div onKeyDown={handleKeyDown}>
                <FormControl fullWidth>
                  <InputLabel id="select-placeholder" shrink="true">Select</InputLabel>
                  <Select
                    displayEmpty
                    fullWidth
                    input={<OutlinedInput name="selectedRejectedReason" />}
                    labelId="select-placeholder"
                    onChange={handleOptionChange}
                    styleName="reject-reason-dropDown"
                    value={selectedOption}
                  >
                    <MenuItem disabled value=""><em>Select</em></MenuItem>
                    {evalRejectReasons && filterOptionsByPrefix().map(reason => (
                      <MenuItem key={reason.reasonCode} value={`${reason.reasonCode}_${reason.reason}`}>
                        {`${reason.reasonCode}_${reason.reason}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

            </Grid>
          </Grid>
          {selectedOption && (
          <>
            <Grid container spacing={2} style={{ overflow: 'hidden' }}>
              <Grid item styleName="reason-label" xs={12}>
                <div>Comment</div>
              </Grid>
              <Grid item style={{ overflow: 'hidden' }} xs={12}>
                <TextField
                  inputProps={{ maxLength: 1000 }}
                  multiline
                  name="comments"
                  onChange={handleComments}
                  rows={4}
                  size="small"
                  styleName="fullWidth"
                  value={comment}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Divider style={{ marginBottom: '8px', marginTop: '10px' }} />
            <div styleName="reject-reason-bottom">
              <Button
                color="primary"
                disabled={isButtonDisable}
                onClick={handleSaveRejectreason}
                styleName={isButtonDisable ? 'disable-reject-reason-button' : 'reject-reason-button'}
                variant="outlined"
              >
                  OK
              </Button>
            </div>
          </>
          )}
        </DialogContent>
      </Dialog>

      <ConfirmationDialogBox
        isOpen={openAlertBox}
        message=""
        onClose={isConfirmed => handleConfirmationDialogBox(isConfirmed)}
        title={REJECT_REASON_CONFIRMATION_DIALOG_MSG}
      />

      {openOverwritePopup && (
      <Popup
        cancelButtonText="NO"
        confirmButtonText="YES"
        level="Warning"
        message="Denial has already been generated for this Eval. Do you wish to override to generate a new Denial Letter?"
        onCancel={handleCancel}
        onConfirm={handleFinalConfirmation}
        show={openOverwritePopup}
        showCancelButton
        showConfirmButton
        title="Confirmation"
      />
      )}

      {openFinalConfirmationPopup && (
      <Popup
        cancelButtonText="Close"
        confirmButtonText="Yes"
        level="Warning"
        message="Rejection of Eval will result in closure the milestone task. Are you sure you want to proceed?"
        onCancel={() => setOpenFinalConfirmationPopup(false)}
        onConfirm={handleFinalConfirmation}
        show={openFinalConfirmationPopup}
        showCancelButton
        showConfirmButton
        title="Confirmation"
      />
      )}
    </Grid>
  );
};
RejectReasonPopup.defaultProps = {
};

RejectReasonPopup.propTypes = {
  checkIsLetterGenerated: PropTypes.func.isRequired,
  evalRejectReasons: PropTypes.shape().isRequired,
  fetchLetterGeneratedStatus: PropTypes.bool.isRequired,
  fetchRejectReasons: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  popupData: PropTypes.shape({
    cancelButtonText: PropTypes.string,
    clearData: PropTypes.string,
    confirmButtonText: PropTypes.string,
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    showCancelButton: PropTypes.bool,
    showConfirmButton: PropTypes.bool,
    title: PropTypes.string,

  }).isRequired,
  saveRejectReason: PropTypes.func.isRequired,
  setRejectreasonPopupStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  evalRejectReasons: evalSelectors.evalRejectReasons(state),
  isOpen: evalSelectors.popUpStatus(state),
  fetchLetterGeneratedStatus: evalSelectors.fetchLetterGeneratedStatus(state),
});

const mapDispatchToProps = dispatch => ({
  fetchRejectReasons: evalOperations.fetchRejectReasonsOperation(dispatch),
  setRejectreasonPopupStatus: evalOperations.setRejectreasonPopupStatusOperation(dispatch),
  saveRejectReason: evalOperations.saveRejectReasonOperation(dispatch),
  dispatchAction: dashboardOperations.dispatchAction(dispatch),
  checkIsLetterGenerated: evalOperations.checkIsLetterGeneratedOperation(dispatch),
  updateLetterGenerationStatus: evalOperations.updateLetterGenerationStatusOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RejectReasonPopup);
