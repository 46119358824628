import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { operations, selectors } from 'ducks/tombstone';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { FORMAT } from 'lib/Formatters';
import Loader from 'components/Loader';
import CloseIcon from '@material-ui/icons/Close';
import DashboardModel from 'models/Dashboard';
import './RepossessionTableContent.css';

const getDisplayValue = (value, type) => {
  if (!value) {
    // If value is falsy (empty, null, undefined, etc.), return the default value
    if (type === 'amount') return '0.00';
    if (type === 'number') return 0;
    return '--';
  }
  if (type === 'amount') {
    return FORMAT.repossession_currency(value.toString(), false);
  }

  if (type === 'date') {
    const dateObj = new Date(value);
    if (value === '0001-01-01' || value === '01/01/0001' || dateObj.getFullYear() === 1 || dateObj.getFullYear() === 1970) {
      return '--';
    }
  }

  return value;
};

const getAddress = (line1, line2) => {
  if (!line1 && !line2) {
    return '--';
  }
  if (line1 && line2) {
    return `${line1}, ${line2}`;
  }
  return line1 || line2;
};

const RepossessionTableContent = ({
  repossessionData = {},
  activeTile,
  setChecklistCenterPaneData,
  loading,
}) => {
  const [repossessionTableData, setRepossessionTableData] = useState({});
  useEffect(() => {
    setRepossessionTableData(repossessionData);
  }, [repossessionData]);

  const handleClose = () => {
    if (activeTile === DashboardModel.NPV) {
      setChecklistCenterPaneData('NPVResults');
    } else {
      setChecklistCenterPaneData('Checklist');
    }
  };

  const {
    generalLoanInformation = {},
    loanInformation = {},
    repossessionVendorInfo = {},
    collateralInformation = {},
    letterHistory = {},
    loanIndicator = {},
    repossessionActions = {},
  } = repossessionTableData || {};

  const {
    mailingAddress = {},
    borrowerName = '',
    cityStateZip = '',
    coBorrowerName = '',
    loanNumber = '',
  } = generalLoanInformation;

  const {
    repossessionVendor = '',
    repossessionVendorAddress = {},
    repossessionVendorPhoneNumber = '',
    repossessionVendorWebsite = '',
    vendorCityStateZip = '',
    vendorFileNumber = '',
  } = repossessionVendorInfo;

  const sections = [
    {
      title: 'General Loan Information',
      styleName: 'section-general-loan',
      columns: 'two-columns',
      fields: [
        { label: 'Loan Number', value: getDisplayValue(loanNumber, 'string') },
        { label: 'Mailing City, State Zip', value: getDisplayValue(cityStateZip, 'string') },
        { label: 'Borrower Name', value: getDisplayValue(borrowerName, 'string') },
        {
          label: 'Mailing Address',
          value: getAddress(mailingAddress.line1, mailingAddress.line2),
        },
        { label: 'Co-Borrower Name', value: getDisplayValue(coBorrowerName, 'string') },
      ],
    },
    {
      title: 'Loan Information',
      styleName: 'section-loan-info',
      columns: 'two-columns',
      fields: [
        { label: 'UPB Balance', value: `$${getDisplayValue(loanInformation.upbBalance, 'amount')}` },
        { label: 'Note Date', value: getDisplayValue(loanInformation.noteDate, 'date') },
        { label: 'Interest Rate', value: `${getDisplayValue(loanInformation.interestRate, 'number')}%` },
        { label: 'Escrow Balance', value: `$${getDisplayValue(loanInformation.escrowBalance, 'amount')}` },
        { label: 'Paid To Date', value: getDisplayValue(loanInformation.paidToDate, 'date') },
        { label: 'Suspense Balance', value: `$${getDisplayValue(loanInformation.suspenseBalance, 'amount')}` },
        { label: 'Day of Delinquency', value: getDisplayValue(loanInformation.dayOfDelinquency, 'number') },
        { label: 'Past Due Payments', value: getDisplayValue(loanInformation.pastDuePayments, 'number') },
        { label: 'Bankruptcy Status', value: getDisplayValue(loanInformation.bankruptcyStatus, 'string') },
        { label: 'Bankruptcy Chapter', value: getDisplayValue(loanInformation.bankruptcyChapter, 'string') },
        { label: 'Late Fees', value: `$${getDisplayValue(loanInformation.lateFees, 'amount')}` },
        { label: 'MISC Fees', value: `$${getDisplayValue(loanInformation.miscFees, 'amount')}` },
        { label: 'MFR Granted', value: getDisplayValue(loanInformation.mfrGranted, 'string') },
      ],
    },
    {
      title: 'Repossession Vendor',
      styleName: 'section-repossession-vendor',
      columns: 'three-columns',
      fields: [
        { label: 'Repossession Vendor', value: getDisplayValue(repossessionVendor, 'string') },
        { label: 'Repossession Vendor Phone Number', value: getDisplayValue(repossessionVendorPhoneNumber, 'string') },
        { label: 'Vendor City, State, Zip', value: getDisplayValue(vendorCityStateZip, 'string') },
        {
          label: 'Repossession Vendor Address',
          value: getAddress(repossessionVendorAddress.line1, repossessionVendorAddress.line2),
        },
        { label: 'Repossession Vendor Website', value: getDisplayValue(repossessionVendorWebsite, 'string') },
        { label: 'Vendor File Number', value: getDisplayValue(vendorFileNumber, 'string') },
      ],
    },
    {
      title: 'Collateral Information',
      styleName: 'section-collateral-info',
      columns: 'two-columns',
      fields: [],
    },
    {
      title: 'Letter History',
      styleName: 'section-letter-history',
      columns: 'three-columns',
      fields: [
        {
          label: 'Notice of Default Letter Sent',
          value: getDisplayValue(letterHistory.noticeOfDefaultLetterSent, 'date'),
        },
        {
          label: 'Amended Deficiency Letter Sent',
          value: getDisplayValue(letterHistory.amendedDeficiencyLetterSent, 'date'),
        },
        {
          label: 'Deficiency Letter Sent',
          value: getDisplayValue(letterHistory.deficiencyLetterSent, 'date'),
        },
        {
          label: 'Notice of Intent Letter Sent',
          value: getDisplayValue(letterHistory.noticeOfIntentLetterSent, 'date'),
        },
        {
          label: 'Surplus Letter Sent',
          value: getDisplayValue(letterHistory.surplusLetterSent, 'date'),
        },
        {
          label: 'Ancillary Letter Sent',
          value: getDisplayValue(letterHistory.ancillaryLetterSent, 'date'),
        },
      ],
    },
    {
      title: 'Loan Indicator',
      styleName: 'section-loan-indicator',
      columns: 'one-column',
      fields: [
        {
          label: 'CDLI (Indicator Position)',
          value: getDisplayValue(loanIndicator.indicatorPosition, 'string'),
        },
      ],
    },
    {
      title: 'Repossession Actions',
      styleName: 'section-repossession-actions',
      columns: 'four-columns',
      fields: [
        {
          label: 'Surrender Type (Involuntary or Voluntary)',
          value: getDisplayValue(repossessionActions.surrenderType, 'string'),
        },
        {
          label: 'Auction Date',
          value: getDisplayValue(repossessionActions.auctionDate, 'date'),
        },
        {
          label: 'Floor Price',
          value: `$${getDisplayValue(repossessionActions.floorPrice, 'amount')}`,
        },
        {
          label: 'Repossession Fees',
          value: `$${getDisplayValue(repossessionActions.repossessionFees, 'amount')}`,
        },
        {
          label: 'Date Referred to Vendor',
          value: getDisplayValue(repossessionActions.dateReferredToVendor, 'date'),
        },
        {
          label: 'Auction Sale Completed Date',
          value: getDisplayValue(repossessionActions.auctionSaleCompletedDate, 'date'),
        },
        {
          label: 'Secretion Claim Filed Date',
          value: getDisplayValue(repossessionActions.secretionClaimFiledDate, 'date'),
        },
        {
          label: 'Remarketing Fees',
          value: `$${getDisplayValue(repossessionActions.remarketingFees, 'amount')}`,
        },
        {
          label: 'Date Secured',
          value: getDisplayValue(repossessionActions.dateSecured, 'date'),
        },
        {
          label: 'Sales Price',
          value: `$${getDisplayValue(repossessionActions.salesPrice, 'amount')}`,
        },
        {
          label: 'Storage Fees',
          value: `$${getDisplayValue(repossessionActions.storageFees, 'amount')}`,
        },
      ],
    },
  ];

  const renderSection = (title, styleName, columns, fields) => {
    if (title === 'Collateral Information') {
      const vinNumbers = Object.values(collateralInformation).filter(vin => vin && vin.vinValue);

      return (
        <div key={title} styleName={styleName}>
          <h3>{title}</h3>
          {vinNumbers.length > 0 ? (
            <TableContainer component={Paper}>
              <Table styleName="TableContainer">
                <TableHead>
                  <TableRow>
                    <TableCell styleName="TableHeading">Vin Numbers</TableCell>
                    <TableCell styleName="TableHeading">Year</TableCell>
                    <TableCell styleName="TableHeading">Make</TableCell>
                    <TableCell styleName="TableHeading">Model</TableCell>
                    <TableCell styleName="TableHeading">Location</TableCell>
                    <TableCell styleName="TableHeading">License Plate Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vinNumbers.map(vin => (
                    <TableRow>
                      <TableCell styleName="TableValues">{getDisplayValue(vin.vinValue, 'string')}</TableCell>
                      <TableCell styleName="TableValues">{getDisplayValue(vin.collateralYear, 'string')}</TableCell>
                      <TableCell styleName="TableValues">{getDisplayValue(vin.collateralMake, 'string')}</TableCell>
                      <TableCell styleName="TableValues">{getDisplayValue(vin.collateralModel, 'string')}</TableCell>
                      <TableCell styleName="TableValues">{getDisplayValue(vin.collateralLocation, 'string')}</TableCell>
                      <TableCell styleName="TableValues">{getDisplayValue(vin.licensePlateNumber, 'string')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <p styleName="p">No Collateral Data Available</p>
          )}
        </div>
      );
    }

    return (
      <div key={title} styleName={styleName}>
        <h3>{title}</h3>
        <div styleName={`info-grid ${columns}`}>
          {fields.map(({ label, value }) => (
            <div>
              <span styleName="label">{label}</span>
              <br />
              <span styleName="value">{value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Grid container elevation={0}>
      <Grid item xs={11}>
        <Typography styleName="heading">
          Repossession
        </Typography>
      </Grid>
      <Grid item styleName="closeButton" xs={1}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      {loading ? <Loader message="Please Wait" />
        : (
          <Grid item styleName="popup-container" xs={12}>
            {sections.map(section => renderSection(
              section.title,
              section.styleName,
              section.columns,
              section.fields,
            ))}
          </Grid>
        )}
    </Grid>
  );
};

RepossessionTableContent.propTypes = {
  activeTile: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  repossessionData: PropTypes.shape(),
  setChecklistCenterPaneData: PropTypes.func.isRequired,
};

RepossessionTableContent.defaultProps = {
  repossessionData: {},
  loading: false,
};

const mapStateToProps = state => ({
  activeTile: dashboardSelectors.getStagerActiveTile(state),
  loading: selectors.getLoader(state),
  repossessionData: selectors.getRepossessionTableData(state),
});

const mapDispatchToProps = dispatch => ({
  setChecklistCenterPaneData: operations.setChecklistCenterPaneDataOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RepossessionTableContent);
