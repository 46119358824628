import React, { useEffect } from 'react';
import UniversalCookie from 'universal-cookie';

const cookies = new UniversalCookie(document.cookie);
const JWT_TOKEN_COOKIE_NAME = 'app-session-jwt-token';
const JWT_REFRESH_TOKEN_COOKIE_NAME = 'app-session-jwt-refresh-token';

function CookieManager() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (cookies.get(JWT_TOKEN_COOKIE_NAME)) {
        localStorage.setItem(JWT_TOKEN_COOKIE_NAME,
          cookies.get(JWT_TOKEN_COOKIE_NAME));
      }
      if (cookies.get(JWT_REFRESH_TOKEN_COOKIE_NAME)) {
        localStorage.setItem(JWT_REFRESH_TOKEN_COOKIE_NAME,
          cookies.get(JWT_REFRESH_TOKEN_COOKIE_NAME));
      }
    }
  }, [cookies]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (<></>);
}
CookieManager.defaultProps = {
};
export default CookieManager;
