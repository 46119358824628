import React, { useState } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import {
  Grid, Typography, TextField, Button, Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core/';
import * as moment from 'moment';
import { convertToUSCurrency } from 'lib/Formatters';
import BookingFieldsStyles from './BookingFieldsStyles';
import EvalDialogBox from '../EvalDialogBox/EvalDialogBox';

const BookingFields = ({
  id, label, value, details, highlightCells, highlightCellsWithBorder, type, options,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const classes = BookingFieldsStyles({ highlightCells, highlightCellsWithBorder });

  const formatValue = () => {
    switch (type) {
      case 'currency': {
        return `${convertToUSCurrency.format(Number(value).toFixed(2))}`;
      }
      case 'rate':
        return value && value !== '' ? `${(Number(value) * 100).toFixed(3)}%` : '-';
      case 'date': {
        return value && value !== '' && value !== '-' ? moment(value).format('MM/DD/YYYY') : '-';
      }
      default:
        return value;
    }
  };

  return (
    <Grid className={classes.singleContainer} container direction="row" spacing={2}>
      <Grid className={classes.grid1} item md={7} xs={7}>
        <Typography className={classes.labelName}>{label}</Typography>
        {!R.isEmpty(details) && (
        <>
          <Button className={classes.DetailsButton} disableElevation disableRipple onClick={handleClickOpen} variant="text">Details</Button>
          <EvalDialogBox
            content={details.data}
            isOpen={open}
            onClose={handleClose}
            showCloseIcon
            title={details.title}
          />
        </>
        )}
      </Grid>
      <Grid className={classes.grid2} item md={5} xs={5}>
        {type === 'radio' && !R.isEmpty(options)
          ? (
            <RadioGroup
              className={classes.RadioGroup}
              name="row-radio-buttons-group"
              row
              value={value}
            >
              {options && options.map(option => (
                <FormControlLabel
                  control={<Radio />}
                  disabled
                  label={option.displayName}
                  value={option.value}
                />
              ))
     }
            </RadioGroup>
          )

          : (
            <TextField
              className={classes.TextFieldDiv}
              disabled
              id={id}
              InputProps={{
                disableUnderline: true,
              }}
              value={formatValue()}
            />
          ) }
      </Grid>
    </Grid>
  );
};

BookingFields.propTypes = {
  details: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      code: PropTypes.string,
      description: PropTypes.string,
    })),
    title: PropTypes.string,
  }),
  highlightCells: PropTypes.bool,
  highlightCellsWithBorder: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    disabled: PropTypes.bool,
    displayName: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  type: PropTypes.string,
  value: PropTypes.string,
};

BookingFields.defaultProps = {
  highlightCells: false,
  highlightCellsWithBorder: false,
  type: '',
  label: '',
  value: '0',
  details: {},
  options: [],
};

export default BookingFields;
