import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import {
  Typography,
} from '@material-ui/core/index';
import './IndexingList.css';
import PropTypes from 'prop-types';
import { operations as documentChecklistOperations, selectors as documentChecklistSelectors } from 'ducks/document-checklist';
import { operations as indexerOperations } from 'ducks/indexer';
import LinkPopover from 'components/IncomeCalc/DocumentViewer/components/LinkPopover';
import Document from '../../IncomeCalc/DocumentList/Document';
import Loader from '../../Loader';

class IndexingList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documents: null,
      linkDocPopover: false,
      removalDocumentId: 0,
      removalDocumentName: '',
    };
    this.handleLinkPopover = this.handleLinkPopover.bind(this);
  }

  componentDidMount() {
    const {
      fetchDocTxnDocuments,
    } = this.props;
    fetchDocTxnDocuments();
  }

  componentDidUpdate(prevProps) {
    const { selectedBorrower, setRadioSelect } = this.props;

    if (prevProps.selectedBorrower !== selectedBorrower) {
      setRadioSelect('');
    }
  }

  componentWillUnmount() {
    const { setRadioSelect, setIndexingPageVisibility } = this.props;
    setRadioSelect('');
    setIndexingPageVisibility(false);
  }

  static getDerivedStateFromProps(props) {
    const {
      value, selectedBorrower,
    } = props;
    const data = R.propOr([], 'documents', R.find(R.propEq('borrowerName',
      selectedBorrower))(value));
    if (data) {
      return { documents: data };
    }
    return null;
  }

  handleLinkPopover = () => {
    this.setState({ linkDocPopover: false, removalDocumentId: 0, removalDocumentName: '' });
  }

  setLinkDocPopover = () => {
    this.setState({ linkDocPopover: true });
  }

  setRemovalDoc = (docId, docName) => {
    this.setState({ removalDocumentId: docId, removalDocumentName: docName });
  }

  render() {
    const {
      loader,
    } = this.props;
    const {
      documents, linkDocPopover, removalDocumentName,
      removalDocumentId,
    } = this.state;
    return (
      <>
        {
        loader ? <Loader />
          : (
            <div>
              {
                R.isEmpty(documents)
                  ? (
                    <div>
                      <Typography
                        styleName="no-data"
                        variant="h6"
                      >
                        {'No Documents Found'}
                      </Typography>
                    </div>
                  )
                  : (
                    <div>
                      {
                        documents.map(item => (
                          <Document
                            doc={item}
                            setLinkDocPopover={this.setLinkDocPopover}
                            setRemovalDoc={this.setRemovalDoc}
                            setUnlinkDocPopOver={this.handleLinkPopover}
                          />
                        ))
                      }
                      <LinkPopover
                        linkDocPopover={linkDocPopover}
                        removalDocumentId={removalDocumentId}
                        removalDocumentName={removalDocumentName}
                        setLinkDocPopover={this.handleLinkPopover}
                        source="indexer"
                        type="unlink"
                      />
                    </div>
                  )}
            </div>
          )}
      </>
    );
  }
}

IndexingList.propTypes = {
  fetchDocTxnDocuments: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired,
  selectedBorrower: PropTypes.string.isRequired,
  setIndexingPageVisibility: PropTypes.func.isRequired,
  setRadioSelect: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  errorFields: documentChecklistSelectors.getErrorFields(state),
  value: documentChecklistSelectors.getDocChecklistData(state),
  loader: documentChecklistSelectors.getLoader(state),
  selectedBorrower: documentChecklistSelectors.getSelectedBorrower(state),
  radioSelect: documentChecklistSelectors.getRadioSelect(state),
});

const mapDispatchToProps = dispatch => ({
  fetchDocTxnDocuments: documentChecklistOperations.setDocChecklistDataOperation(dispatch),
  setRadioSelect: documentChecklistOperations.radioSelectOperation(dispatch),
  unlinkDocumentToBorrowers: documentChecklistOperations.unlinkDocumentOperation(dispatch),
  setIndexingPageVisibility: indexerOperations.setIndexingPageVisibility(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexingList);
