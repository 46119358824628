/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Typography, Button, Grid, Tabs, Tab, Paper,
} from '@material-ui/core/index';
import PropTypes from 'prop-types';
import Tombstone from 'containers/Dashboard/Tombstone';
import AdditionalInfo from 'containers/AdditionalInfo';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { selectors as incomeCalcSelectors } from 'ducks/income-calculator';
import { selectors as dashboardSelectors, operations as dashboardOperations } from 'ducks/dashboard';
import { selectors as tombstoneSelectors } from 'ducks/tombstone';
import { operations as docChecklistOperations } from 'ducks/document-checklist';
import { operations as indexerOperations, selectors as indexerSelectors } from 'ducks/indexer';
import { selectors as widgetsSelectors, operations as widgetsOperations } from 'ducks/widgets';
import { selectors as loginSelectors } from 'ducks/login';
import {
  ADDITIONAL_INFO, LSAMS_NOTES,
} from 'constants/widgets';
import * as R from 'ramda';
import processBorrowerData from 'lib/CustomFunctions/BorrowerData/processBorrowerData';
import DocumentViewer from 'components/IncomeCalc/DocumentViewer/DocumentViewer';
import AddContributor from 'components/IncomeCalc/AddContributor/AddContirbutor';
import SweetAlertBox from 'components/SweetAlertBox';
import LSAMSNotesWidget from 'components/Widgets/LSAMSNotesWidget';
import './Indexing.css';
import { connect } from 'react-redux';
import Loader from 'components/Loader';
import getTombstonePopup from 'components/Tombstone/PopupSelect';
import { EDITABLE_FIELDS } from 'constants/loanInfoComponents';
import Popup from 'components/Popup';
import WidgetBuilder from '../../Widgets/WidgetBuilder';
import ConfirmationDialog from './ConfirmDialog';
import IndexingList from '../IndexingList';
import EditContributor from '../../IncomeCalc/EditContributor/EditContributor';

const Indexing = (props) => {
  const {
    setDocSelectedBorrorwer, getborrowerData, handleLink,
    inProgress, loanMAState, updateLSAMSDetails,
    loanNumber, evalId, brandName, resultOperation, resetResultOperationOperation,
    fetchIndexerGridData, indexerGridData, openWidgetList, groupName,
    checklistCenterPaneView, clearPopupData, popupData, dispatchAction, user: { userDetails },
    isSearchActive, fetchIndexerSearchGridData,
  } = props;

  const getEmail = R.propOr('', 'email');
  const email = getEmail(userDetails);

  const [tabIndex, setTabIndex] = useState(0);
  const [showAddContributorPopup, setShowAddContributorPopup] = useState(false);
  const [showEditContributorPopup, setShowEditContributorPopup] = useState(false);
  const [contributorData, setContributorData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const displayList = processBorrowerData(getborrowerData);

  const handleTabChange = (selectedIndex) => {
    setTabIndex(selectedIndex);
    const borrowerValue = R.propOr('', 'value', R.find(R.propEq('pstnNum',
      (selectedIndex + 1)))(displayList));
    setDocSelectedBorrorwer({ selectedBorrower: borrowerValue });
  };

  const handAddContributorClick = () => {
    setShowAddContributorPopup(true);
  };

  const handleCloseAddContributor = () => {
    setShowAddContributorPopup(false);
  };

  const EditContributorFn = (item) => {
    setContributorData(item);
    setShowEditContributorPopup(true);
  };
  const handleCloseEditContributor = () => {
    setShowEditContributorPopup(false);
  };

  const isEditable = item => (!!(item.description.toLowerCase().startsWith('assump')
  || item.description.toLowerCase().startsWith('contrib')));

  const displayBorrowerBanner = () => (
    <Paper elevation={0} square styleName="borrowerBanner">
      <Tabs
        inkBarStyle={{ background: '#596feb' }}
        onChange={(_, selectedIndex) => handleTabChange(selectedIndex)}
        scrollable="true"
        style={{ width: '90%' }}
        value={tabIndex}
        variant="scrollable"
      >
        { inProgress ? <Loader size={20} />
          : displayList && displayList.map(task => (
            <Tab
              key={R.propOr('', 'name', task)}
              label={(
                <div styleName="tabContainer">
                  <div styleName="taballign">
                    <div styleName="borrNameDiv">
                      <Typography styleName="borrName" variant="subtitle1">
                        {task && R.propOr('', 'name', task)}
                      </Typography>
                      <Typography styleName="borrDesc" variant="subtitle2">
                        {task && R.propOr('', 'description', task)}
                      </Typography>
                    </div>
                  </div>
                  {isEditable(task) && (
                    <div>
                      <IconButton
                        aria-label="Edit"
                        component="span"
                      >
                        <EditIcon onClick={() => EditContributorFn(task)} />
                      </IconButton>
                    </div>
                  ) }
                </div>
                        )}
              styleName="borrTab"
            />
          ))}
      </Tabs>
      {displayList.length > 0 && (
        <IconButton
          onClick={handAddContributorClick}
          styleName="add-contributor-button"
        >
          <img alt="add-contributor" src="/static/img/person_add.svg" />
        </IconButton>
      )}
    </Paper>
  );

  useEffect(() => {
    if (resultOperation && resultOperation.saga === 'lsamsUpdate') {
      setDialogOpen(false);
    }
  }, [resultOperation]);

  const shouldRenderWidgetView = () => R.any(widget => R.contains(
    widget, [ADDITIONAL_INFO, LSAMS_NOTES],
  ))(openWidgetList);

  const renderWidgetComponents = () => {
    const mainWidget = R.head(openWidgetList);
    let widgetToRender = null;
    switch (mainWidget) {
      case ADDITIONAL_INFO:
        widgetToRender = <AdditionalInfo groupName={groupName} />;
        break;
      case LSAMS_NOTES:
        widgetToRender = <LSAMSNotesWidget />;
        break;
      default:
        widgetToRender = null;
    }
    return widgetToRender;
  };

  const renderSweetAlert = () => {
    if (popupData) {
      const {
        isOpen, message, title, level, showCancelButton,
        cancelButtonText, confirmButtonText, onConfirm,
      } = popupData;
      const confirmAction = onConfirm ? dispatchAction : clearPopupData;
      return (
        <Popup
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          level={level}
          message={message}
          onCancel={clearPopupData}
          onConfirm={() => confirmAction(onConfirm)}
          show={isOpen}
          showCancelButton={showCancelButton}
          showConfirmButton
          title={title}
        />
      );
    }
    return null;
  };

  const renderLoanInfoComponents = () => (
    <section styleName="loanInfo">
      <Grid styleName="rfdData">
        {getTombstonePopup(checklistCenterPaneView)}
      </Grid>
      {renderSweetAlert()}
    </section>
  );

  const onConfirmAlert = () => {
    const { isActive } = isSearchActive;
    if (isActive) {
      const { searchType, searchValue } = isSearchActive;
      fetchIndexerSearchGridData({ searchType, searchValue });
    }
    if (indexerGridData) {
      fetchIndexerGridData(indexerGridData.pageNumber, indexerGridData.pageSize);
    } else {
      fetchIndexerGridData(0, 10);
    }
    handleLink(true);
  };

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <Typography styleName="main-headline" variant="h2">INDEXING</Typography>
        <div styleName="indexing-wrapper">
          <Tombstone />
          {!shouldRenderWidgetView() ? (
            <>
              {EDITABLE_FIELDS.includes(checklistCenterPaneView)
                ? (
                  <div styleName="scroll-wrapper">
                    {renderLoanInfoComponents()}
                  </div>
                )
                : (
                  <div styleName="indexing-container">
                    <div styleName="btn-container">
                      <Button
                        onClick={() => {
                          handleLink(true);
                          fetchIndexerGridData(
                            indexerGridData.pageNumber ? indexerGridData.pageNumber : 0,
                            indexerGridData.pageSize ? indexerGridData.pageSize : 10,
                          );
                        }

                      }
                        size="large"
                        startIcon={<ArrowBackIosIcon />}
                        styleName="icon-btn"
                        type="button"
                      >
                        Back
                      </Button>
                      <Button color="primary" onClick={() => setDialogOpen(true)} variant="contained">
                        Save
                      </Button>
                    </div>
                    <div styleName="center-container">
                      <Grid container direction="column">
                        <Grid item styleName="tabWidth" />
                        {displayBorrowerBanner()}
                        <Grid item>
                          <div styleName="docListContainer">
                            <IndexingList />
                          </div>
                        </Grid>
                      </Grid>
                      <div styleName="documents-container">
                        <DocumentViewer />
                      </div>
                      {showAddContributorPopup
                        && <AddContributor onClose={handleCloseAddContributor} />}
                      {showEditContributorPopup && (
                      <EditContributor
                        contributorData={contributorData}
                        onClose={handleCloseEditContributor}
                      />
                      )}
                    </div>
                  </div>
                )}
            </>
          ) : (
            <div styleName="widget-indexer">
              {renderWidgetComponents()}
            </div>
          ) }
          <WidgetBuilder page="INDEXER" styleName="widget" />
        </div>
        <ConfirmationDialog
          cancelFn={() => setDialogOpen(false)}
          handleClose={() => setDialogOpen(false)}
          isMaLoan={loanMAState}
          isOpen={dialogOpen}
          saveFn={(values) => {
            updateLSAMSDetails({
              ...values,
              evalId,
              loanNumber,
              brandName,
              email,
            });
          }}
        />
        {resultOperation && resultOperation.status && resultOperation.saga === 'lsamsUpdate' && (
          <SweetAlertBox
            message={resultOperation.status}
            onConfirm={() => {
              resetResultOperationOperation();
              if (resultOperation.level === 'Success') { onConfirmAlert(); }
            }}
            show={resultOperation.isOpen}
            type={resultOperation.level}
          />
        )}
      </DndProvider>
    </div>
  );
};

Indexing.defaultProps = {
  fetchIndexerGridData: () => {},
  fetchIndexerSearchGridData: () => { },
};

Indexing.propTypes = {
  brandName: PropTypes.string.isRequired,
  checklistCenterPaneView: PropTypes.string.isRequired,
  clearPopupData: PropTypes.func.isRequired,
  dispatchAction: PropTypes.func.isRequired,
  evalId: PropTypes.number.isRequired,
  fetchIndexerGridData: PropTypes.func,
  fetchIndexerSearchGridData: PropTypes.func,
  getborrowerData: PropTypes.shape().isRequired,
  groupName: PropTypes.string.isRequired,
  handleLink: PropTypes.func.isRequired,
  indexerGridData: PropTypes.shape().isRequired,
  inProgress: PropTypes.bool.isRequired,
  isSearchActive: PropTypes.shape({
    isActive: PropTypes.bool,
    searchType: PropTypes.string,
    searchValue: PropTypes.number,
  }).isRequired,
  loanMAState: PropTypes.bool.isRequired,
  loanNumber: PropTypes.number.isRequired,
  openWidgetList: PropTypes.arrayOf(PropTypes.string).isRequired,
  popupData: PropTypes.shape({
    cancelButtonText: PropTypes.string,
    clearData: PropTypes.string,
    confirmButtonText: PropTypes.string,
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    message: PropTypes.string,
    onConfirm: PropTypes.func,
    showCancelButton: PropTypes.bool,
    showConfirmButton: PropTypes.bool,
    title: PropTypes.string,
  }).isRequired,
  resetResultOperationOperation: PropTypes.func.isRequired,
  resultOperation: PropTypes.shape({
    isOpen: PropTypes.bool,
    level: PropTypes.string,
    saga: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  setDocSelectedBorrorwer: PropTypes.func.isRequired,
  updateLSAMSDetails: PropTypes.func.isRequired,
  user: PropTypes.shape({
    skills: PropTypes.objectOf(PropTypes.array),
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  getborrowerData: incomeCalcSelectors.getBorrowers(state),
  inProgress: dashboardSelectors.inProgress(state),
  loanMAState: tombstoneSelectors.getLoanMAState(state),
  brandName: dashboardSelectors.brand(state),
  loanNumber: dashboardSelectors.loanNumber(state),
  evalId: dashboardSelectors.evalId(state),
  resultOperation: dashboardSelectors.resultOperation(state),
  checklistCenterPaneView: tombstoneSelectors.getChecklistCenterPaneView(state),
  indexerGridData: indexerSelectors.getIndexerGridData(state),
  openWidgetList: widgetsSelectors.getOpenWidgetList(state),
  popupData: dashboardSelectors.getPopupData(state),
  groupName: dashboardSelectors.groupName(state),
  user: loginSelectors.getUser(state),
});


const mapDispatchToProps = dispatch => ({
  clearPopupData: dashboardOperations.clearPopupData(dispatch),
  dispatchAction: dashboardOperations.dispatchAction(dispatch),
  setDocSelectedBorrorwer: docChecklistOperations.setSelectedBorrowerOperation(dispatch),
  updateLSAMSDetails: indexerOperations.updateLSAMSDetails(dispatch),
  resetResultOperationOperation: dashboardOperations.resetResultOperationOperation(dispatch),
  fetchIndexerGridData: indexerOperations.fetchIndexerGridData(dispatch),
  fetchIndexerSearchGridData: indexerOperations.fetchIndexerSearchGridData(dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(Indexing);
