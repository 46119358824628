import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { selectors as loginSelectors } from 'ducks/login';
import { selectors } from 'ducks/dashboard';
import RouteAccess from 'lib/RouteAccess';
import './EvalTableCell.css';

class EvalTableCell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.route = this.route.bind(this);
  }

  handleLinkClick() {
    const { click } = this.props;
    click();
  }

  route() {
    const { user } = this.props;
    const groups = user && user.groupList;
    if (RouteAccess.hasLoanActivityAccess(groups)) {
      return '/loan-activity';
    }
    return '/';
  }

  isDisabled() {
    const { user } = this.props;
    const { groupList } = user;
    if (Array.isArray(groupList) && groupList.includes('readonly')) {
      return true;
    }
    return false;
  }

  render() {
    const { value, styleProps, disableSendToFEUW } = this.props;
    let renderCellValue = '';
    switch (value) {
      case 'Un-reject':
        renderCellValue = (
          <Tooltip
            styleName="tooltip"
            title="UnReject"
          >
            <IconButton disabled={this.isDisabled()} onClick={this.handleLinkClick} styleName="reject-icon">
              <img alt="UnReject" src="/static/img/Revoke.svg" />
            </IconButton>
          </Tooltip>
        );
        break;
      case 'Booking':
        renderCellValue = (
          <IconButton disabled={this.isDisabled()} onClick={this.handleLinkClick} styleName="reject-icon">
            <img alt="Booking" src="/static/img/booking.svg" />
          </IconButton>
        );
        break;
      case 'SendToFEUW':
        renderCellValue = (
          <IconButton disabled={disableSendToFEUW || this.isDisabled()} onClick={this.handleLinkClick} styleName="reject-icon">
            <img alt="Send To FEUW" src="/static/img/Revoke.svg" />
          </IconButton>
        );
        break;
      default:
        renderCellValue = (
          <span disabled={this.isDisabled()} styleName={styleProps}>
            {(value === 'CMOD' || value === 'REMEDY') ? <img alt="REMEDY" src={`/static/img/${value}.png`} /> : value}
          </span>
        );
    }
    return (
      <div>
        {renderCellValue}
      </div>
    );
  }
}

EvalTableCell.defaultProps = {
  disableSendToFEUW: false,
};

EvalTableCell.propTypes = {
  click: PropTypes.func.isRequired,
  disableSendToFEUW: PropTypes.bool,
  styleProps: PropTypes.string.isRequired,
  user: PropTypes.shape({
    groupList: PropTypes.array,
    userDetails: PropTypes.shape({
      email: PropTypes.string,
      jobTitle: PropTypes.string,
      name: PropTypes.string,
    }),
    userGroups: PropTypes.array,
  }).isRequired,
  value: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  user: loginSelectors.getUser(state),
  disableSendToFEUW: selectors.disableSendToFEUW(state),
});

const TestHooks = {
  EvalTableCell,
};

export default connect(mapStateToProps, null)(EvalTableCell);
export { TestHooks };
