/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import History from '@material-ui/icons/History';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import CommentOutlined from '@material-ui/icons/CommentOutlined';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { operations as documentChecklistOperations, selectors as documentChecklistSelectors } from 'ducks/document-checklist';
import { selectors as dashboardSelectors } from 'ducks/dashboard';
import { selectors as indexerSelectors } from 'ducks/indexer';
import CustomTooltip from './components/CustomTooltip';
import CustomTextField from './components/CustomTextfield';
import {
  DECEASED_BORROWER, NO_LINK, MAX_CHAR_COUNT,
  TO_ORDER_TAB,
} from '../../../constants/incomeCalc/DocumentList';
import './DocumentList.css';
import ExpTag from './ExpTag';


function Document(props) {
  const {
    selectedBorrower, doc, radioSelect, errorFields,
    isAssigned, isDocWidgetNotInProc, changeDocumentDetails,
    docReviewStatusOptions, defectReasonOptions, isVASP,
    unlinkDocumentToBorrowers, setDisableCompleteBtn,
    activeTab, setOpenHistoryModel, setLinkDocPopover, setRemovalDoc,
    setTag, setRemovalDocumentAnchor, setUnlinkDocPopOver, isIndexingPageVisible,
  } = props;


  const [anchorMoreOptions, setAnchorMoreOptions] = useState({});
  const [expanded, setExpanded] = useState({});
  const [anchorDocReview, setAnchorDocReview] = useState({});
  const [anchorDocReasons, setAnchorDocReasons] = useState({});
  const [editCheckBox, setEditCheckBox] = useState(null);
  const [editText, setEditText] = useState(null);
  const [editedComment, setEditedComment] = useState(null);
  const [activeDocumentName, setActiveDocumentName] = useState('');

  const {
    documentName, required, docTxnId,
    expirationDate, documentReviewStatus, toolTipText,
    linkedDocuments, docReasons, comments,
    disableOptionalOption,
  } = doc;

  const [, drop] = useDrop({
    accept: 'fileNetDoc',
    drop: () => ({
      name: documentName,
      selectedBorrower,
    }),
  });

  const handleRadioClick = () => {
    const { setRadioSelect } = props;
    setRadioSelect({ radioSelect: documentName });
    if (isIndexingPageVisible) {
      setActiveDocumentName('');
    }
  };

  const handleOpenHistoryModel = () => {
    const { fetchDocHistory } = props;
    fetchDocHistory({ docTxnId });
    setOpenHistoryModel(documentName);
  };

  const setTagPopoverfunc = (taggedDocName, req) => {
    setTag(true, taggedDocName, req);
  };

  const handleCloseMoreOptions = (req, source) => {
    const { setTagData } = props;
    if (!R.isNil(req)) {
      if (isVASP) {
        setTagData({ taggedDocumentName: documentName, required: !req });
      } else {
        setTag(true, documentName, req);
      }
      setTagPopoverfunc(documentName, req);
    }
    setAnchorMoreOptions({ ...anchorMoreOptions, [docTxnId]: null });
    if (source !== 'onClose' && activeTab === TO_ORDER_TAB) {
      setDisableCompleteBtn(false);
    }
  };

  const handleAccordianClick = () => {
    const { setRadioSelect } = props;
    const isOpen = R.propOr(false, documentName, expanded);
    setRadioSelect({ radioSelect: '' });
    setExpanded({ ...expanded, [documentName]: !isOpen });
    if (isIndexingPageVisible) {
      setActiveDocumentName(documentName);
    }
  };

  const handleClick = (event) => {
    setAnchorDocReview({ ...anchorDocReview, [docTxnId]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorDocReview({ ...anchorDocReview, [docTxnId]: null });
  };

  const handleMenuClick = (event) => {
    const { defectReasonDropdown } = props;
    setAnchorDocReview({ ...anchorDocReview, [docTxnId]: null });
    if (!defectReasonOptions[documentName]
        || defectReasonOptions[documentName].length === 0) {
      defectReasonDropdown(documentName);
    }
    changeDocumentDetails({ key: 'documentReviewStatus', value: event.currentTarget.innerText, docTxnId });
    if (activeTab === TO_ORDER_TAB) {
      setDisableCompleteBtn(false);
    }
  };

  const handleDocReasonClick = (event) => {
    setEditCheckBox(anchorDocReasons[docTxnId] ? null : docReasons);
    setAnchorDocReasons(
      { ...anchorDocReasons, [docTxnId]: anchorDocReasons[docTxnId] ? null : event.currentTarget },
    );
  };

  const handCheckboxClick = (checkBoxItem, event) => {
    setEditCheckBox(
      event.target.checked
        ? [...editCheckBox, checkBoxItem]
        : R.without([checkBoxItem], editCheckBox),
    );
  };

  const handleDoneClick = () => {
    setEditCheckBox(null);
    setAnchorDocReasons({ ...anchorDocReasons, [docTxnId]: null });
    changeDocumentDetails({ key: 'docReasons', value: editCheckBox, docTxnId });
  };

  const handleDateChange = (selectedDate) => {
    if (selectedDate && selectedDate.isValid()) {
      changeDocumentDetails({ key: 'expirationDate', value: selectedDate, docTxnId });
    }
  };

  const getDefectReasonText = () => {
    const { defectReasonDropdown } = props;
    if (docReasons.length > 0
      && (R.isEmpty(defectReasonOptions[documentName])
      || R.isNil(defectReasonOptions[documentName]))) {
      defectReasonDropdown(documentName);
    }
    const dftData = defectReasonOptions[documentName]
      ? defectReasonOptions[documentName].reduce((acc, curr) => {
        if (docReasons.includes(curr.docDefectId)) {
          return [...acc, curr.defectReason];
        }
        return acc;
      }, []) : [];
    return dftData;
  };

  const getDocReasonText = () => {
    const defectReasonText = getDefectReasonText();
    const formattedString = R.join(',', defectReasonText);
    const text = R.remove(12, formattedString.length, formattedString).join('');
    const additionalCound = R.length(docReasons) - R.length(R.match(/,/g, text)) - 1;
    return text ? `${text}...+${additionalCound}` : 'Select Doc Reason(s)';
  };

  const handleTextChange = (event) => {
    if (isVASP) {
      const { value } = event.target;
      if (value.length <= MAX_CHAR_COUNT) {
        setEditedComment(value);
      }
    } else {
      setEditedComment(event.target.value);
    }
  };

  const handleInputKeyDown = (event) => {
    event.preventDefault();
    if (event.keyCode === 8 || event.keyCode === 46) {
      changeDocumentDetails({ key: 'expirationDate', value: null, docTxnId });
    }
  };

  const handleCommentUpdate = () => {
    setEditedComment(null);
    setEditText(null);
    changeDocumentDetails({ key: 'comments', value: editedComment, docTxnId });
    if (activeTab === TO_ORDER_TAB) {
      setDisableCompleteBtn(false);
    }
  };

  const handleEditClick = (x) => {
    setEditText(x);
    setEditedComment(comments);
  };

  const handleRemoveLink = (event, linkedDoc, type) => {
    const removalDocId = R.propOr('', 'fileNetDocId', R.nth(linkedDoc, linkedDocuments));
    const removalDocAnchor = event.currentTarget;
    if (isVASP) {
      setUnlinkDocPopOver();
      setRemovalDoc(removalDocId, documentName);
      setRemovalDocumentAnchor(removalDocAnchor);
    } else {
      if (type === 'multi') {
        setLinkDocPopover(true);
        setRemovalDoc(removalDocId, documentName);
      } else if (type === 'single') {
        const payload = {
          removalDocumentId: removalDocId,
          removalDocumentName: documentName,
          checkedBorrowers: [selectedBorrower],
        };
        unlinkDocumentToBorrowers(payload);
      }
    }
    if (isIndexingPageVisible) {
      setActiveDocumentName('');
    }
  };

  const dateFormatter = (date) => {
    if (R.isNil(date)) { return ''; }
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    return (`${month}/${day}/${year}`);
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');
    event.preventDefault();

    const { value, selectionStart, selectionEnd } = event.target;
    const currentTextBeforePaste = value.slice(0, selectionStart);
    const currentTextAfterPaste = value.slice(selectionEnd);

    const maxCharCount = MAX_CHAR_COUNT
      - (currentTextBeforePaste.length + currentTextAfterPaste.length);
    const truncatedData = pastedData.slice(0, maxCharCount);

    const comment = currentTextBeforePaste + truncatedData + currentTextAfterPaste;
    setEditedComment(comment);
  };

  const errors = errorFields[docTxnId] || [];
  const selectedStyleName = ((isIndexingPageVisible && documentName === activeDocumentName) || documentName === radioSelect) ? 'selected' : '';
  const errorStyle = errors.length > 0 ? 'error' : '';
  const docReviewError = errors.includes('documentReviewStatus') ? 'docReviewError' : '';
  const expirationDateError = errors.includes('expirationDate') ? 'expirationDateError' : '';
  const isDeceased = selectedBorrower.includes(DECEASED_BORROWER);
  const disableStyle = (!isIndexingPageVisible && (!isAssigned || isDocWidgetNotInProc)) ? 'doc-disable' : '';
  const disableDocChecklist = isDeceased ? 'doc-disable' : '';
  const isLinkedDocPresent = linkedDocuments.length > 0;
  let text = '';
  if (documentReviewStatus === 'Defects') { text = getDocReasonText(); }

  return (
    <>
      <Paper key={documentName} ref={drop} styleName={`doc-container ${disableDocChecklist} ${selectedStyleName} ${errorStyle}`} variant="outlined">
        <div styleName="accordian-header">
          <div styleName="left-header">
            <Radio
              checked={documentName === radioSelect}
              checkedIcon={(
                <Button
                  disabled
                  styleName={`link-doc-button-disable ${disableStyle}`}
                  variant="contained"
                >
                    Link Doc
                </Button>
              )}
              icon={(
                <Button
                  styleName={`link-doc-button ${disableStyle}`}
                  variant="contained"
                >
                  Link Doc
                </Button>
              )}
              onChange={handleRadioClick}
              styleName={disableStyle}
              value={documentName}
            />
            {
                (
                  <span
                    style={{
                      backgroundColor:
                        linkedDocuments.length
                          > 0 ? '#00cc99' : '#EBEBE4',
                      padding: '2px 5px',
                      borderRadius: 3,
                      color: '#fff',
                      marginRight: 8,
                    }}
                  >
                    {linkedDocuments.length}
                  </span>
                )}
            {
                  isIndexingPageVisible && required ? (
                    <span
                      style={{
                        backgroundColor: '#F03858',
                        padding: '2px 5px',
                        borderRadius: 3,
                        color: '#fff',
                        marginRight: 8,
                      }}
                    >
                      REQ
                    </span>
                  ) : ''}
            {!isIndexingPageVisible && <ExpTag expDate={expirationDate} />}
            <p styleName="typography">{documentName}</p>
            {isVASP && (
              <CustomTooltip
                arrow
                placement="bottom-start"
                title={(
                  <div className="tooltiptext">
                    {toolTipText}
                  </div>
                  )}
              >
                <div>
                  <IconButton>
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </div>
              </CustomTooltip>
            )}
          </div>
          <div styleName="right-header">
            { !isIndexingPageVisible
              && (
                <FormControlLabel
                  control={<Switch checked={required} color="primary" onChange={() => handleCloseMoreOptions(required, 'onClick')} size="medium" />}
                  disabled={disableOptionalOption || !R.isEmpty(disableStyle)}
                  label="Req"
                  labelPlacement="start"
                />
              )
            }
            {!isIndexingPageVisible && !isVASP && <History onClick={handleOpenHistoryModel} styleName="cursor" />}
            {R.propOr(false, documentName, expanded)
              ? <ExpandLess onClick={handleAccordianClick} styleName="cursor" />
              : <ExpandMore onClick={handleAccordianClick} styleName="cursor" />}
          </div>
        </div>
        {!isIndexingPageVisible
          && (
          <div styleName="doc-components">
            <div styleName="doc-review">
              <Typography>Doc Review Status</Typography>
              <Button
                endIcon={<ArrowDropDownIcon />}
                onClick={handleClick}
                styleName={disableStyle}
              >
                {documentReviewStatus || 'Select'}
              </Button>
              <Menu
                anchorEl={R.propOr(null, docTxnId, anchorDocReview)}
                id={docTxnId}
                keepMounted
                onClose={handleClose}
                open={Boolean(R.propOr(null, docTxnId, anchorDocReview))}
              >
                {!isVASP && docReviewStatusOptions
                  && docReviewStatusOptions.map(({
                    requestType, displayText,
                  }) => (
                    <MenuItem
                      key={requestType}
                      disabled={
                        (R.equals(displayText, 'Defects')
                        && R.isEmpty(defectReasonOptions[documentName]))
                        || !isLinkedDocPresent
                      }
                      onClick={event => handleMenuClick(event)}
                      value={requestType}
                    >
                      {displayText}
                    </MenuItem>
                  ))
                  }
                {isVASP && !isLinkedDocPresent && docReviewStatusOptions
                  && docReviewStatusOptions
                    .filter(
                      ({ requestType }) => NO_LINK.includes(requestType),
                    )
                    .map(({ requestType, displayText }) => (
                      <MenuItem
                        key={requestType}
                        onClick={event => handleMenuClick(event)}
                        value={requestType}
                      >
                        {displayText}
                      </MenuItem>
                    ))
                }
                {isVASP && isLinkedDocPresent && docReviewStatusOptions
                  && docReviewStatusOptions.map(({
                    requestType,
                    displayText,
                  }) => (
                    <MenuItem
                      key={requestType}
                      onClick={event => handleMenuClick(event)}
                      value={requestType}
                    >
                      {displayText}
                    </MenuItem>
                  ))
                  }
              </Menu>
            </div>

              {defectReasonOptions[documentName] && defectReasonOptions[documentName].length > 0 && !isVASP && R.equals(documentReviewStatus, 'Defects') && (
              <div styleName={`doc-reason ${docReviewError}`}>
                <Typography>Doc Reason(s)</Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Typography
                    onClick={event => handleDocReasonClick(event)}
                    styleName="cursor"
                    tabindex="0"
                  >
                    {text}
                  </Typography>
                  <ArrowDropDownIcon
                    onClick={event => handleDocReasonClick(event)}
                    styleName="cursor"
                  />
                </div>
                <Popover
                  anchorEl={anchorDocReasons[docTxnId]}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  onClose={event => handleDocReasonClick(event)}
                  open={Boolean(anchorDocReasons[docTxnId])}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transition
                >
                  <Paper styleName="checkbox-list">
                    <div styleName="checkbox-component">
                      {defectReasonOptions[documentName]
                        && defectReasonOptions[documentName].map(
                          checkBoxItem => (
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={
                                  R.contains(
                                    checkBoxItem.docDefectId, editCheckBox
                                    || [],
                                  )
                                }
                                  color="primary"
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  onChange={event => handCheckboxClick(checkBoxItem.docDefectId, event)}
                                />
                            )
                          }
                              label={checkBoxItem.defectReason}
                              styleName={disableStyle}
                            />
                          ),
                        )}
                    </div>
                    <Divider />
                    <Button
                      color="primary"
                      onClick={handleDoneClick}
                      styleName={`done-button ${disableStyle}`}
                      variant="contained"
                    >
                                Done
                    </Button>
                  </Paper>
                </Popover>
              </div>
              )}
              {
                !isVASP
                && (
                <div styleName={`doc-expiration ${expirationDateError}`}>
                  <Typography>Expiration</Typography>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      emptyLabel="MM/DD/YYYY"
                      error={false}
                      format="MM/DD/YYYY"
                      helperText={null}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      KeyboardButtonProps={{
                        style: { marginLeft: '-6.5rem' },
                      }}
                      maxDate={moment().add(1, 'years').format('MM/DD/YYYY')}
                      onChange={handleDateChange}
                      PopoverProps={{
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                      }}
                      styleName={disableStyle}
                      TextFieldComponent={({ InputProps, ...textFieldprops }) => (
                        <TextField
                          {...textFieldprops}
                          InputProps={{
                            ...InputProps,
                            readOnly: true,
                          }}
                          onKeyDown={handleInputKeyDown}
                        />
                      )}
                      value={(R.isNil(expirationDate)
                        || R.isEmpty(expirationDate))
                        ? null : new Date(expirationDate).toISOString().split('T')[0]}
                      variant="inline"
                    />
                  </MuiPickersUtilsProvider>
                </div>
                )
            }

              {isVASP && <div styleName="separator" />}
            <div styleName={isVASP ? 'vasp-doc-comments' : 'doc-comments'}>
              <CommentOutlined styleName="commentIcon" />
              {R.equals(editText, docTxnId)
                ? (
                  <div styleName="editText">
                    {isVASP ? (
                      <CustomTextField
                        onChange={handleTextChange}
                        onPaste={handlePaste}
                        value={editedComment}
                      />
                    ) : (
                      <Box styleName="boxEditing">
                        <TextField
                          InputProps={{
                            disableUnderline: true,
                          }}
                          multiline
                          onChange={handleTextChange}
                          size="small"
                          styleName="textFieldEditing"
                          value={editedComment}
                          variant="standard"
                        />
                      </Box>
                    )}
                    <CheckIcon
                      onClick={handleCommentUpdate}
                      styleName="cursor"
                    />
                    <CloseIcon
                      onClick={() => handleEditClick(null)}
                      styleName="cursor"
                    />
                  </div>
                ) : (R.isNil(comments) || R.isEmpty(comments)) ? (
                  <Typography
                    onClick={() => handleEditClick(docTxnId)}
                    styleName={`comment cursor ${disableStyle}`}
                    tabindex="0"
                  >
                    Add comments
                  </Typography>
                ) : (!isVASP ? (
                  <Box styleName="boxView">
                    <TextField
                      disabled={!isAssigned || isDocWidgetNotInProc}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                        style: {
                          cursor: 'pointer',
                        },
                      }}
                      multiline
                      onClick={() => handleEditClick(docTxnId)}
                      size="small"
                      styleName="textField"
                      value={comments}
                      variant="standard"
                    />
                  </Box>
                ) : (
                  <Typography
                    onClick={() => handleEditClick(docTxnId)}
                    styleName={`comment cursor ${disableStyle}`}
                    tabindex="0"
                  >
                    {comments}
                  </Typography>
                )
                )}
            </div>

          </div>
          )
        }
        {R.propOr(false, documentName, expanded) && !isIndexingPageVisible && (
          <div styleName="linked-docs-contianer">
            {linkedDocuments.length > 0 && (<Typography styleName="linked-typography">Documents Linked</Typography>)}
            <div styleName="linked-docs">
              {linkedDocuments.map((document, docIndex) => (
                <div styleName="document-container">
                  <div styleName="linked-doc-header">
                    <Link color="inherit" href={document.docUrl} rel="noopener noreferrer" target="_blank">
                      <p styleName="linked-doc-header-title">{document.docTitle}</p>
                    </Link>
                    <CloseOutlinedIcon
                      onClick={event => handleRemoveLink(event, docIndex, 'multi')}
                      styleName={isVASP ? 'cursor' : `cursor ${disableStyle}`}
                    />
                  </div>
                  <div styleName="linked-doc-content">
                    <p styleName="linked-doc-title">Uploaded On</p>
                    <p styleName="linked-doc-value">{dateFormatter(document.docCreatedDate)}</p>
                  </div>
                  <div styleName="linked-doc-footer">
                    <div styleName="linked-doc-content">
                      <p styleName="linked-doc-title">By</p>
                      <p styleName="linked-doc-value">{document.docCreator}</p>
                    </div>
                    <LinkOffIcon onClick={event => handleRemoveLink(event, docIndex, 'single')} styleName={`cursor ${disableStyle}`} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {R.propOr(false, documentName, expanded) && isIndexingPageVisible && (
          linkedDocuments.map((indexerDoc, docIndex) => (
            <div styleName="linked-document-container">
              <div styleName="displayInRow">
                <Link color="inherit" href={indexerDoc.docUrl} rel="noopener noreferrer" styleName="docTitle" target="_blank">
                  <Typography variant="h2">{indexerDoc.docTitle}</Typography>
                </Link>
                <IconButton
                  aria-label="close"
                  onClick={event => handleRemoveLink(event, docIndex, 'multi')}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Typography variant="body2">
                Uploaded On
                <span>{dateFormatter(indexerDoc.docCreatedDate)}</span>
              </Typography>
              <div styleName="displayInRow">
                <Typography variant="body2">
                  By
                  <span>{indexerDoc.docCreator}</span>
                </Typography>
                <IconButton
                  aria-label="unlink"
                  onClick={event => handleRemoveLink(event, docIndex, 'single')}
                >
                  <LinkOffIcon />
                </IconButton>
              </div>
            </div>
          ))
        )}
      </Paper>
    </>
  );
}

Document.defaultProps = {
  isDocWidgetNotInProc: true,
  isVASP: false,
  setDisableCompleteBtn: () => { },
  setRemovalDocumentAnchor: () => { },
};

Document.propTypes = {
  activeTab: PropTypes.string.isRequired,
  changeDocumentDetails: PropTypes.func.isRequired,
  defectReasonDropdown: PropTypes.func.isRequired,
  defectReasonOptions: PropTypes.shape().isRequired,
  doc: PropTypes.shape().isRequired,
  docReviewStatusOptions: PropTypes.shape().isRequired,
  errorFields: PropTypes.shape().isRequired,
  fetchDocHistory: PropTypes.func.isRequired,
  isAssigned: PropTypes.bool.isRequired,
  isDocWidgetNotInProc: PropTypes.bool,
  isIndexingPageVisible: PropTypes.bool.isRequired,
  isVASP: PropTypes.bool,
  radioSelect: PropTypes.string.isRequired,
  selectedBorrower: PropTypes.string.isRequired,
  setDisableCompleteBtn: PropTypes.func,
  setLinkDocPopover: PropTypes.func.isRequired,
  setOpenHistoryModel: PropTypes.func.isRequired,
  setRadioSelect: PropTypes.func.isRequired,
  setRemovalDoc: PropTypes.func.isRequired,
  setRemovalDocumentAnchor: PropTypes.func,
  setTag: PropTypes.func.isRequired,
  setTagData: PropTypes.func.isRequired,
  setUnlinkDocPopOver: PropTypes.func.isRequired,
  unlinkDocumentToBorrowers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  defectReasonOptions: documentChecklistSelectors.getDefectReasonDropdown(state),
  docReviewStatusOptions: documentChecklistSelectors.getDocReviewStatusDropdown(state),
  errorFields: documentChecklistSelectors.getErrorFields(state),
  isAssigned: dashboardSelectors.isAssigned(state),
  isIndexingPageVisible: indexerSelectors.isIndexingPageVisible(state),
  selectedBorrower: documentChecklistSelectors.getSelectedBorrower(state),
  radioSelect: documentChecklistSelectors.getRadioSelect(state),
});

const mapDispatchToProps = dispatch => ({
  changeDocumentDetails: documentChecklistOperations.changeDocumentDetails(dispatch),
  defectReasonDropdown: documentChecklistOperations.defectReasonDropdownOperation(dispatch),
  fetchDocHistory: documentChecklistOperations.fetchDocHistoryOperation(dispatch),
  setRadioSelect: documentChecklistOperations.radioSelectOperation(dispatch),
  unlinkDocumentToBorrowers: documentChecklistOperations.unlinkDocumentOperation(dispatch),
  setTagData: documentChecklistOperations.setTagOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Document);
