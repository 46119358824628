import { Button } from '@material-ui/core/index';
import React from 'react';
import DownloadIcon from 'containers/Dashboard/BoardingTemplate/History/DownloadIcon';
import { convertToUSCurrency } from 'lib/Formatters';
import * as moment from 'moment';

export const DEFAULT_ROWS_PER_PAGE = 500;
export const HISTORY_TABLE_COLUMNS = [
  {
    name: 'id',
    label: 'ID',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'fileName',
    label: 'File Name',
    align: 'left',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'requestorId',
    label: 'User Name',
    align: 'left',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'createdAt',
    label: 'Date Uploaded',
    align: 'left',
    options: {
      filter: false,
      sort: true,
    },
    cellFormat: value => (value ? moment(value).format('MM/DD/YYYY hh:mm A') : '-'),
  },
  {
    name: 'importStatus',
    label: 'Status',
    align: 'left',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'totalRecords',
    label: 'Total Records',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'successRecords',
    label: 'Successful Records',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'failedRecords',
    label: 'Unsuccessful Records',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'download',
    label: 'Download',
    align: 'left',
    options: {
      filter: false,
      sort: false,
    },
    cellFormat: (callable) => {
      const { fn, id, importStatus } = callable;
      // eslint-disable-next-line react/jsx-filename-extension
      return (<Button disabled={importStatus === 'In Progress'} onClick={() => { fn(id); }} style={{ padding: '0px' }}><DownloadIcon color={importStatus === 'In Progress' ? '#acacad' : '#6D7483'} /></Button>);
    },
  },
];

const LOAN_ID = 'Loan ID';
const CASE_TYPE = 'Case Type';
const PHASE = 'Phase';
const PRE_APPROVED = 'Pre Approved';
const NEXT_PAYMENT_DUE = 'Next Payment Due';
const PRE_MOD_GROSS_UPB = 'Pre-Mod Gross UPB';
const PRE_MOD_NEXT_DUE_DATE = 'Pre-Mod Next Due Date';
const INFLIGHT = 'InFlight';
const CURRENT_UPB = 'Current UPB';
const CRITICAL_SEVERITY_EXCEPTION = 'Critical Severity Exceptions';
const MEDIUM_SEVERITY_EXCEPTION = 'Medium Severity Exceptions';
const LOW_SEVERITY_EXCEPTION = 'Low Severity Exceptions';

export const FORMATTABLE_ONBOARD_TABLE_COLUMNS = {
  'Pre Approved': {
    cellFormat: value => (value && value.toString() === '1' ? 'Yes' : 'No'),
  },
  'Next Payment Due': {
    cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
  },
  'Pre-Mod Gross UPB': {
    cellFormat: value => (convertToUSCurrency.format(value)),
  },
  'Pre-Mod Next Due Date': {
    cellFormat: value => (value && value ? moment(value).format('MM/DD/YYYY') : '-'),
  },
  InFlight: {
    cellFormat: value => (value && value.toString() === '1' ? 'Yes' : 'No'),
  },
  'Current UPB': {
    cellFormat: value => (convertToUSCurrency.format(value)),
  },
};

export const ONBOARD_TABLE_COLUMNS = [LOAN_ID, CASE_TYPE, PHASE, PRE_APPROVED,
  INFLIGHT, PRE_MOD_GROSS_UPB, PRE_MOD_NEXT_DUE_DATE];

export const EXCEPTION_TABLE_COLUMNS = [LOAN_ID, CASE_TYPE, PHASE, PRE_APPROVED,
  INFLIGHT, CURRENT_UPB, CRITICAL_SEVERITY_EXCEPTION,
  MEDIUM_SEVERITY_EXCEPTION, LOW_SEVERITY_EXCEPTION];

export const ONBOARD_TABLE_COLUMNS_MAPPING = {
  [LOAN_ID]: LOAN_ID,
  [CASE_TYPE]: CASE_TYPE,
  [PHASE]: PHASE,
  [INFLIGHT]: INFLIGHT,
  [PRE_APPROVED]: PRE_APPROVED,
  [PRE_MOD_GROSS_UPB]: CURRENT_UPB,
  [PRE_MOD_NEXT_DUE_DATE]: NEXT_PAYMENT_DUE,
};

export const EXCEPTION_TABLE_COLUMNS_MAPPING = {
  [LOAN_ID]: LOAN_ID,
  [CASE_TYPE]: CASE_TYPE,
  [PHASE]: PHASE,
  [INFLIGHT]: INFLIGHT,
  [PRE_APPROVED]: PRE_APPROVED,
  [CURRENT_UPB]: CURRENT_UPB,
  [CRITICAL_SEVERITY_EXCEPTION]: CRITICAL_SEVERITY_EXCEPTION,
  [MEDIUM_SEVERITY_EXCEPTION]: MEDIUM_SEVERITY_EXCEPTION,
  [LOW_SEVERITY_EXCEPTION]: LOW_SEVERITY_EXCEPTION,
};

export const templateDataFileName = 'BoardingTemplate.xlsx';
export const exceptionDataFileName = 'BoardingTemplateException.xlsx';
export const template = 'Template';
export const uploadedTemplate = 'Uploaded Template';
export const exceptionBtn = 'Exceptions';
export const loanNoLimitExceededExp = 'There is a 500-loan limit. Please correct and try again.';
export const DATE_FIELDS = ['Post-Mod Maturity Date', 'Post-Mod Down Payment Date', 'Post-Mod Start Date', 'Eval Status Date', 'Case Status Date', 'Post-Mod Forgiveness Date', 'Pre-Mod Current LPI', 'Pre-Mod Next Due Date', 'Borrower Doc Execution Date', 'Pre-Approved Expiration Date', 'Due Date At Time of Disaster Declared', 'Docs Out Date', 'Docs Received Date', 'Forecasted LPI Date', 'Case Expiration Date', 'Max Maturity Date', 'Trial Plan Date 1*', 'Trial Plan Date 2*', 'Trial Plan Date 3*', 'Trial Plan Date 4*', 'Trial Plan Date 5*', 'Trial Plan Date 6*', 'Trial Plan Date 7*', 'Trial Plan Date 8*', 'Trial Plan Date 9*', 'Trial Plan Date 10*', 'Trial Plan Date 11*', 'Trial Plan Date 12*', 'Trial Plan Date 13*', 'Trial Plan Date 14*', 'Trial Plan Date 15*', 'Trial Plan Date 16*', 'Trial Plan Date 17*', 'Trial Plan Date 18*', 'Trial Plan Date 19*', 'Trial Plan Date 20*', 'Trial Plan Date 21*', 'Trial Plan Date 22*', 'Trial Plan Date 23*', 'Trial Plan Date 24*', '1- Date Payment Received*', '2- Date Payment Received*', '3- Date Payment Received*', '4- Date Payment Received*', '5- Date Payment Received*', '6- Date Payment Received*', '7- Date Payment Received*', '8- Date Payment Received*', '9- Date Payment Received*', '10- Date Payment Received*', '11- Date Payment Received*', '12- Date Payment Received*', '13- Date Payment Received*', '14- Date Payment Received*', '15- Date Payment Received*', '16- Date Payment Received*', '17- Date Payment Received*', '18- Date Payment Received*', '19- Date Payment Received*', '20- Date Payment Received*', '21- Date Payment Received*', '22- Date Payment Received*', '23- Date Payment Received*', '24- Date Payment Received*'];

export const NUMBER_FIELDS = ['Loan ID', 'SMDU Transaction ID', 'Existing Eval ID', 'Pre Approved', 'Streamline', 'InFlight', 'Forbearance Flag', 'Disaster ID', 'Post-Mod Amortization Duration',
  'Frequency', 'Cap Mod Request ID', 'FHATypeIndicator', 'More Than 90 Days Delinquent', 'PVMID', 'Escrow Spread Months', 'Total Months Deferred', 'Trial Exempt Flag',
  'Forecasted Remaining Term'];

export const AMOUNT_FIELDS = ['New Partial Claim Amount', 'Borrower Gross Income', 'Borrower Expenses', 'Post-Mod TI Amount/Taxes', 'Trial Payment',
  'Capitalized Escrow Advances', 'UPB At Default', 'Post-Mod Interest Bearing UPB', 'Pre-Mod Forbearance or Existing Deferred Amount', 'Post-Mod Forbearance Amount and New Deferred Amount', 'Pre-Mod PI Amount', 'Pre-Mod TI Amount', 'Post-Mod PI Amount',
  'Pre-Mod Gross UPB', 'Post-Mod Down Payment Amount', 'Post-Mod Forgiveness Amount', 'Capitalized Amount', 'Capitalized Corp Adv', 'Total Delinquent Interest Due',
  'Suspense Funds (at lock)', 'Delinquent Principal', 'Post-Mod PITI Amount', 'targetPI', 'targetPITI', 'Borrower Disposable Income', 'Borrower Net Income', 'Net Disbursements', 'Escrow Total Shortage',
  'Pre-Mod Interest Bearing UPB (at lock)', 'Pre-Mod Forecasted UPB', 'Prior Partial Claim Amount', 'Pre-Mod PITI Amount', 'Case Payment 25 Percent', 'Deferred Principal',
  'Deferred Interest', 'Total Forecasted Delinquent Interest Due', 'Unpaid Legal Fee', 'Remaining Partial Claim Amount', 'Max Partial Claim Amount', 'Post-Mod Gross UPB', 'Proposed Monthly PI Amount',
  'Delinquent PI', 'Delinquent TI', 'Monthly Payment Reduction', 'Total Payment Reduction', 'Borrower PI', 'Case Principal', 'Monthly Max Principal Reduction', 'Total Max Monthly Principal Reduction',
  'Monthly Remaining Supplemental Amount', '1 - Capitalized Corporate Advance Amount', '2 - Capitalized Corporate Advance Amount', '3 - Capitalized Corporate Advance Amount', '4 - Capitalized Corporate Advance Amount', '5 - Capitalized Corporate Advance Amount', '6 - Capitalized Corporate Advance Amount', '7 - Capitalized Corporate Advance Amount', '8 - Capitalized Corporate Advance Amount', '9 - Capitalized Corporate Advance Amount', '10 - Capitalized Corporate Advance Amount', '11 - Capitalized Corporate Advance Amount', '12 - Capitalized Corporate Advance Amount', '13 - Capitalized Corporate Advance Amount', '14 - Capitalized Corporate Advance Amount', '15 - Capitalized Corporate Advance Amount', '1 - Uncapitalized Corporate Advance Amount', '2 - Uncapitalized Corporate Advance Amount', '3 - Uncapitalized Corporate Advance Amount'];

export const RATE_FIELDS = ['Post-Mod Interest Rate', 'Effective Survey Rate', 'Pre-Mod Interest Rate'];

export const ONBOARD_REQ_FIELDS = ['Pre-Mod Gross UPB', 'Pre-Mod Next Due Date'];

export const NA_CASE_TYPES = ['Repayment Plan', 'USDA Special Forbearance', 'USDA Special Unemployment Forbearance'];
