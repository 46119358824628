/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import './ExtensionGranted.css';
import { selectors as taskChecklistSelectors, operations as taskChecklistOperations } from '../../state/ducks/tasks-and-checklist';

const ExtensionGranted = ({
  secLookExtensionReason, onClose, getSecLookDaysExtended, getSecLookUploadedFile,
  saveSecLookExtensionGranted,
}) => {
  const [selectedFile, setSelectedFile] = useState(getSecLookUploadedFile);
  const [daysExtended, setDaysExtended] = useState(getSecLookDaysExtended);
  const [errorMessage, setErrorMessage] = useState('');

  const grantedDate = new Date().toLocaleDateString();
  const expiryDate = new Date(new Date().setDate(new Date().getDate() + daysExtended))
    .toLocaleDateString();

  const allowedTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        setErrorMessage('');
      } else {
        setErrorMessage('Invalid file format. Please upload a .XLSX, .PDF, .JPEG, .DOCX, .PNG, .GIF file.');
      }
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      if (allowedTypes.includes(file.type)) {
        setSelectedFile(file);
        setErrorMessage('');
      } else {
        setErrorMessage('Invalid file format. Please upload a .XLSX, .PDF, .JPEG, .DOCX, .PNG, .GIF file.');
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const saveExtensionDetails = () => {
    saveSecLookExtensionGranted(grantedDate, daysExtended, selectedFile, expiryDate);
    onClose();
  };

  return (
    <>
      <div styleName="modal-container">
        <div styleName="card-container">
          <div styleName="content">
            <CloseIcon onClick={onClose} styleName="close-icon" />
            <p styleName="modal-title">
              {secLookExtensionReason}
:
            </p>
            <p styleName="upload-title">Upload Document</p>
            <div styleName="upload-container">
              {!selectedFile ? (
                <div>
                  <div onDragOver={handleDragOver} onDrop={handleDrop} styleName="upload-section">
                    <img alt="fileupload" src="/static/img/fileuploadsmall.svg" />
                    <p>
Drag and drop
                      <b> .XLSX, IMAGE, PDF, DOCX</b>
                      {' '}
to upload
                    </p>
                  </div>
                  <p>Or</p>
                  <input accept=".xlsx, .pdf, .jpg, .jpeg, .png, .gif, .docx" id="fileInput" onChange={handleFileChange} styleName="hidden-input" type="file" />
                </div>
              ) : (
                <div styleName="file-info-container">
                  <a download={selectedFile.name} href={URL.createObjectURL(selectedFile)}>
                    <p styleName="file-name">{selectedFile.name}</p>
                  </a>
                  <CloseIcon onClick={handleRemoveFile} styleName="remove-icon" />
                </div>
              )}
              <Button disabled={selectedFile} onClick={() => document.getElementById('fileInput').click()} styleName="browse-btn">Browse</Button>
            </div>
            {errorMessage && <p styleName="errorMsg">{errorMessage}</p>}
            <div styleName="date-section">
              <span styleName="input-label">Date Extension Granted</span>
              <input readOnly styleName="readonly-input" type="text" value={grantedDate} />
            </div>
            <div styleName="date-section">
              <span styleName="input-label">
                Days Extended
                <span styleName="required"> *</span>
              </span>
              <input onChange={e => setDaysExtended(Number(e.target.value))} styleName="input-field" type="number" value={daysExtended} />
              <div styleName="errorMsg">
                { (daysExtended > 60) && 'Days cannot exceed 60 days'}
              </div>
            </div>
            <div styleName="date-section">
              <span styleName="input-label">Date Extension Expires</span>
              <input readOnly styleName="readonly-input" type="text" value={expiryDate} />
            </div>
            <Button
              disabled={daysExtended > 60}
              onClick={saveExtensionDetails}
              styleName="save-btn"
            >
SAVE
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

ExtensionGranted.propTypes = {
  getSecLookDaysExtended: PropTypes.number.isRequired,
  getSecLookUploadedFile:
   PropTypes.oneOfType([PropTypes.instanceOf(window.File), PropTypes.oneOf([null])]).isRequired,
  onClose: PropTypes.func.isRequired,
  saveSecLookExtensionGranted: PropTypes.func.isRequired,
  secLookExtensionReason: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  getSecLookDaysExtended: taskChecklistSelectors.getSecLookDaysExtended(state),
  getSecLookUploadedFile: taskChecklistSelectors.getSecLookUploadedFile(state),
  secLookExtensionReason: taskChecklistSelectors.getSecLookExtensionReason(state),

});

const mapDispatchToProps = dispatch => ({
  saveSecLookExtensionGranted:
  taskChecklistOperations.saveSecLookExtensionGrantedOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionGranted);
