import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import * as moment from 'moment';
import {
  DEFAULT_ROWS_PER_PAGE,
  TEMPLATE_FILE_INFO,
} from 'constants/busniessAdmin';
import BusinessAdminTable from 'components/ProductBusiness/BusinessAdminTable/BusinessAdminTable';
import Loader from 'components/Loader/Loader';
import * as R from 'ramda';
import { operations as businessAdminOperations, selectors as businessAdminSelectors } from 'ducks/admin-tools';
import { operations as dashboardOperations } from 'ducks/dashboard';

const UploadDataHistory = (props) => {
  const {
    fetchHistoryData, businessAdminData, loading, activeTab, isFilterEnabled, applyBAFilter,
    filterPayload, downloadFile,
  } = props;


  const jsonToExcel = (data, template) => {
    const tab = R.equals('All', activeTab) ? template : activeTab;
    const { fileName } = TEMPLATE_FILE_INFO[tab];
    const inputFileRecords = data.map(obj => Object.keys(obj).reduce((acc, key) => {
      acc[key.substring(0, 1).toUpperCase() + key.substring(1)] = obj[key];
      return acc;
    }, {}));
    const payload = {
      fileName,
      data: inputFileRecords,
    };
    downloadFile(payload);
  };


  const UPLOAD_DATA_HISTORY_COLUMNS = [
    {
      name: 'id',
      label: 'ID',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'ID',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'createdBy',
      label: 'Created By',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Created By',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Date/Time Uploaded',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Date/Time Uploaded',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: value => (value ? moment(value).format('MM/DD/YYYY') : '-'),
    },
    {
      name: 'templateType',
      label: 'Template Type',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Template Type',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'importStatus',
      label: 'Import Status',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Import Status',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'totalRecords',
      label: 'Total Records',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Total Records',
          size: 'small',
          color: 'action',
        },
      },
    },
    {
      name: 'successRecords',
      label: 'Successful Records',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Successful Records',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: item => (
        <span style={{ color: '#596FEB' }}>{item}</span>
      ),
    },
    {
      name: 'failedRecords',
      label: 'Unsuccessful Records',
      align: 'left',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Unsuccessful Records',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: item => (
        <span style={{ color: '#596FEB' }}>{item}</span>
      ),
    },
    {
      name: 'outputFileJson',
      label: 'File Action',
      align: 'center',
      options: {
        filter: false,
        sort: false,
        toolTip: {
          title: 'Download',
          size: 'small',
          color: 'action',
        },
      },
      cellFormat: (value, tab) => (
        <div>
          <IconButton aria-label="download" disabled={!value} onClick={() => jsonToExcel(JSON.parse(value), tab)}>
            <GetAppIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchHistoryData(0, DEFAULT_ROWS_PER_PAGE);
  }, [activeTab]);

  const fetchGridData = (page, pageSize) => {
    if (isFilterEnabled) {
      applyBAFilter(filterPayload, page, pageSize);
    } else {
      fetchHistoryData(page, pageSize);
    }
  };

  return (
    <>
      {loading ? <Loader message="Please Wait..." />
        : (
          <BusinessAdminTable
            businessAdminData={businessAdminData}
            columns={UPLOAD_DATA_HISTORY_COLUMNS}
            handleChange={fetchGridData}
            size="small"
          />
        )}
    </>
  );
};

UploadDataHistory.defaultProps = {
  activeTab: '',
  loading: false,
  fetchHistoryData: () => {},
  isFilterEnabled: false,
  applyBAFilter: () => {},
};

UploadDataHistory.propTypes = {
  activeTab: PropTypes.string,
  applyBAFilter: PropTypes.func,
  businessAdminData: PropTypes.shape().isRequired,
  downloadFile: PropTypes.func.isRequired,
  fetchHistoryData: PropTypes.func,
  filterPayload: PropTypes.shape().isRequired,
  isFilterEnabled: PropTypes.bool,
  loading: PropTypes.bool,
};

const mapStateToProps = state => ({
  businessAdminData: businessAdminSelectors.getBusinessAdminGridData(state),
  loading: businessAdminSelectors.getLoader(state),
  isFilterEnabled: businessAdminSelectors.isFilterEnabled(state),
  filterPayload: businessAdminSelectors.filterPayload(state),
});

const mapDispatchToProps = dispatch => ({
  fetchHistoryData: businessAdminOperations.fetchBusinessAdminUploadDataHistory(dispatch),
  applyBAFilter: businessAdminOperations.applyBAFilter(dispatch),
  downloadFile: dashboardOperations.downloadFile(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadDataHistory);
