import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import './FinancialSection.css';
import Loader from 'components/Loader';
import InputField from 'components/InputField';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  IconButton,
  Grid,
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
}
  from '@material-ui/core';
import {
  selectors as loginSelectors,
} from 'ducks/login';
import { operations, selectors as evalSelectors } from 'ducks/eval';
import { FINANCIALS_EDIT_USER_GROUPS, REJECTED, COMPLETED } from '../../../constants/eval';


const FinancialSection = (props) => {
  const {
    isEvalLandingPage, isEditable, userGroupList, fetchFinancialDropdowns,
    evalCase, dropdowns, saveFinancialData, financialDetailLoader,
    caseHeaderInfo, isFinancialDropdownLoading,
  } = props;

  const initialState = {
    incomeId: '',
    expenseId: '',
    capModId: '',
    pvmId: '',
  };

  const [evalFormData, setEvalFormData] = useState(initialState);
  const [visible, setVisible] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isFinancialsEditIconDisabled, setFinancialsEditIconDisabled] = useState(true);

  useEffect(() => {
    if (FINANCIALS_EDIT_USER_GROUPS.some(group => userGroupList.includes(group))
       && evalCase && ![REJECTED, COMPLETED].includes(evalCase.status)) {
      setFinancialsEditIconDisabled(false);
    } else {
      setFinancialsEditIconDisabled(true);
    }
  }, [userGroupList, evalCase]);

  const setValueFromState = () => {
    if (isEvalLandingPage && evalCase) {
      setEvalFormData(prev => ({
        ...prev,
        incomeId: evalCase.lockedIncomeId,
        expenseId: evalCase.lockedExpenseId,
        capModId: evalCase.lockedCapModRequestId,
        pvmId: evalCase.lockedPVMId,
      }));
    } else if (caseHeaderInfo) {
      setEvalFormData(prev => ({
        ...prev,
        incomeId: caseHeaderInfo.incomeId,
        expenseId: caseHeaderInfo.expenseId,
        capModId: caseHeaderInfo.capModId,
      }));
    }
  };

  useEffect(() => {
    setValueFromState();
  }, [evalCase, caseHeaderInfo]);


  const changeHandler = (e, key) => {
    const { value } = e.target;
    if (isEvalLandingPage) {
      setEvalFormData(prev => ({ ...prev, [key]: value }));
    }
  };

  const saveData = () => {
    setAlert(false);
    saveFinancialData(evalFormData);
    setVisible(false);
  };

  const handlePopup = () => {
    const currentData = {
      incomeId: evalCase.lockedIncomeId,
      expenseId: evalCase.lockedExpenseId,
      capModId: evalCase.lockedCapModRequestId,
      pvmId: evalCase.lockedPVMId,
    };
    if (currentData.incomeId === evalFormData.incomeId
      && currentData.expenseId === evalFormData.expenseId
      && currentData.capModId === evalFormData.capModId) {
      setAlert(false);
      setVisible(false);
    } else if (alert) {
      setValueFromState();
      setEvalFormData(currentData);
      setAlert(false);
      setVisible(false);
    } else {
      setAlert(true);
    }
  };

  const closeConfirmation = () => {
    setAlert(false);
  };

  const isDisabled = () => {
    if (userGroupList.includes('readonly')) {
      return true;
    }
    return false;
  };

  const editClickHandler = () => {
    if (isEditable) {
      setVisible(!visible);
      fetchFinancialDropdowns();
    }
  };
  return (
    <Grid container styleName="container">
      <Grid styleName={isEvalLandingPage ? 'grey-bg' : 'caseDetails-bg grey-bg'}>
        <div styleName="table-header">
          <h2>
Financials
            {isEvalLandingPage && (
            <IconButton aria-label="Edit" component="span" disabled={isFinancialsEditIconDisabled || isDisabled()} styleName="icon-btn">
              <EditIcon
                disabled={isDisabled()}
                fontSize="small"
                onClick={editClickHandler}
                style={{ cursor: isEditable && !isFinancialsEditIconDisabled && !isDisabled() ? 'pointer' : '' }}
                styleName={isDisabled() ? '' : 'edit-icon'}
              />
            </IconButton>
            )}
          </h2>
        </div>
        { financialDetailLoader ? <Loader message="Please wait while updating financial details..." size={60} /> : (
          <Grid>
            <InputField isDisabled label="Income ID" value={isEvalLandingPage ? evalCase.lockedIncomeId : caseHeaderInfo.incomeId} />
            <InputField isDisabled label="CapMod ID" value={isEvalLandingPage ? evalCase.lockedCapModRequestId : caseHeaderInfo.capModId} />
            <InputField isDisabled label="Expense ID" value={isEvalLandingPage ? evalCase.lockedExpenseId : caseHeaderInfo.expenseId} />
            {isEvalLandingPage && <InputField isDisabled label="PVM ID" value={evalCase.lockedPVMId} />}

          </Grid>
        )}
        {visible && (
        <div styleName="backdrop">
          <div styleName={`modal ${alert ? 'centerItem' : ''}`} testId="modal">
            {!alert
              && (
              <>
                <div styleName="header">
                  <h2>Edit Case Details</h2>
                  <IconButton aria-label="close" onClick={() => handlePopup()} testId="closeButton">
                    <CloseIcon />
                  </IconButton>
                </div>
                { isFinancialDropdownLoading ? <Loader message="Please wait while fetching financial dropdown details..." size={40} /> : (
                  <div styleName="selection">
                    <div styleName="item">
                      <h4>Income Financials ID</h4>
                      <FormControl variant="outlined">
                        <Select
                          id="incomeId"
                          input={<OutlinedInput name="incomeId" />}
                          onChange={e => changeHandler(e, 'incomeId')}
                          styleName="dropDown-select"
                          value={evalFormData.incomeId}
                        >
                          {dropdowns.financialId && dropdowns.financialId.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div styleName="item">

                      <h4>Expense Financials ID</h4>
                      <FormControl variant="outlined">
                        <Select
                          id="expenseId"
                          input={<OutlinedInput name="expenseId" />}
                          onChange={e => changeHandler(e, 'expenseId')}
                          styleName="dropDown-select"
                          value={evalFormData.expenseId}
                        >
                          {dropdowns.borrExpenseId && dropdowns.borrExpenseId.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div styleName="item">
                      <h4>Cap Mod Financials ID</h4>
                      <FormControl variant="outlined">
                        <Select
                          id="capModId"
                          input={<OutlinedInput name="capModId" />}
                          onChange={e => changeHandler(e, 'capModId')}
                          styleName="dropDown-select"
                          value={evalFormData.capModId}
                        >
                          {dropdowns.capModId && dropdowns.capModId.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}
                {' '}

              </>
              ) }
            {alert && <h2 testId="alert"> Are you sure you want to clear the unsaved changes ? </h2>}
            { !isFinancialDropdownLoading
              && (
              <div styleName="buttons">
                <Button
                  color="primary"
                  onClick={() => (!alert ? saveData() : handlePopup())}
                  styleName="saveBtn btn"
                  testId="btn1"
                  variant="contained"
                >
                  { !alert ? 'Save' : 'Yes' }
                </Button>
                {alert && (
                <Button onClick={() => closeConfirmation()} styleName="btn cancelBtn" testId="btn2">No</Button>
                )}

              </div>
              )}
          </div>
        </div>
        ) }

      </Grid>
    </Grid>

  );
};
FinancialSection.defaultProps = {
  caseHeaderInfo: {
    incomeId: null,
    expenseId: null,
    capModId: null,
  },
  evalCase: {
    lockedIncomeId: null,
    lockedCapModRequestId: null,
    lockedExpenseId: null,
    lockedPVMId: null,
    status: null,
  },
  isEditable: true,
  isEvalLandingPage: false,
  userGroupList: [],
  fetchFinancialDropdowns: () => {},
  dropdowns: {
    financialId: [],
    borrExpenseId: [],
    capModId: [],
  },
  saveFinancialData: () => {},
  financialDetailLoader: false,
  isFinancialDropdownLoading: false,
};

FinancialSection.propTypes = {
  caseHeaderInfo: PropTypes.shape(
    {
      capModId: PropTypes.number,
      expenseId: PropTypes.number,
      incomeId: PropTypes.number,
    },
  ),
  dropdowns: PropTypes.shape({
    borrExpenseId: PropTypes.arrayOf(PropTypes.number),
    capModId: PropTypes.arrayOf(PropTypes.number),
    financialId: PropTypes.arrayOf(PropTypes.number),
  }),
  evalCase: PropTypes.shape({
    lockedCapModRequestId: PropTypes.number,
    lockedExpenseId: PropTypes.number,
    lockedIncomeId: PropTypes.number,
    lockedPVMId: PropTypes.number,
    status: PropTypes.string,
  }),
  fetchFinancialDropdowns: PropTypes.func,
  financialDetailLoader: PropTypes.bool,
  isEditable: PropTypes.bool,
  isEvalLandingPage: PropTypes.bool,
  isFinancialDropdownLoading: PropTypes.bool,
  saveFinancialData: PropTypes.func,
  userGroupList: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  userGroupList: loginSelectors.getGroupList(state),
  dropdowns: evalSelectors.financialDropdowns(state),
  caseHeaderInfo: evalSelectors.caseHeaderInfoData(state),
  financialDetailLoader: evalSelectors.financialDetailLoader(state),
  isFinancialDropdownLoading: evalSelectors.isFinancialDropdownLoading(state),
});

const mapDispatchToProps = dispatch => ({
  fetchFinancialDropdowns: operations.fetchFinancialDropdownOperation(dispatch),
  saveFinancialData: operations.saveFinancialDataOperation(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialSection);

const TestHooks = {
  FinancialSection,
};


export { TestHooks };
